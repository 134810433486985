import { lazy, Suspense, useEffect, useState } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { arbitrum, bsc, polygon, mainnet } from "viem/chains";
import { useHistory } from "react-router-dom"; // import at the top of your file

import LogRocket from "logrocket";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "e46fc7216dea58c2bda7129cef1f97aa";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc, polygon];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCimY0qx6PihZCiPFNrKq73mWWyEYihJTo",
  authDomain: "wespendit.firebaseapp.com",
  projectId: "wespendit",
  storageBucket: "wespendit.appspot.com",
  messagingSenderId: "776332784932",
  appId: "1:776332784932:web:8a172cdb75a4b084516fb1",
  measurementId: "G-8NR8C71YFH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const VerificationResultPage = lazy(() =>
  import("./jsx/pages/VerificationResultPage")
);
const PasswordResetPage = lazy(() => import("./jsx/pages/PasswordResetPage"));
const Checkout = lazy(() => import("./jsx/pages/Checkout"));

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  LogRocket.init("bridgebit/bridgebit");

  useEffect(() => {
    if (localUser) {
      console.log("LogRocket.identify");

      LogRocket.identify(localUser.id, {
        name: localUser.username,
        email: localUser.email,
      });
      fetchUserData(localUser.firebaseUid);
    }
  }, [localUser]);

  const fetchUserData = (firebaseUid) => {
    fetch(`https://api.bridge-bit.com/api/user/${firebaseUid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("User not found.");
        }
        return response.json();
      })
      .then((userData) => {
        dispatch({ type: "SET_USER", payload: userData });
        localStorage.setItem("user", JSON.stringify(userData));
        console.log("User data updated:", userData);
      });
  };

  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname !== "/page-register" &&
      location.pathname !== "/verification-result" &&
      location.pathname !== "/password-reset"
    ) {
      checkAutoLogin(dispatch, navigate);
    }
  }, []);

  useEffect(() => {
    console.log("location.pathname");
    if (props.isAuthenticated === true && location.pathname === "/login") {
      console.log("redirect to dashboard");
      navigate("/dashboard");
    }
  }, [props.isAuthenticated, location.pathname]);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
      <Route path="/verification-result" element={<VerificationResultPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/checkout/:transactionId" element={<Checkout />} />
    </Routes>
  );
  console.log("props.isAuthenticated", props.isAuthenticated);
  if (props.isAuthenticated) {
    return (
      <>
        <WagmiConfig config={wagmiConfig}>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
          </Suspense>
        </WagmiConfig>
      </>
    );
  } else {
    return (
      <WagmiConfig config={wagmiConfig}>
        <div className="vh-100">
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            {routeblog}
          </Suspense>
        </div>
      </WagmiConfig>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
