import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Button,
  Form,
  Alert,
  Col,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import axios from "axios";

const CardTransactions = ({ card }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [activeTab, setActiveTab] = useState("transactions");

  // Add pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDeposit, setCurrentPageDeposit] = useState(1);
  const [itemsPerPage] = useState(10);

  // Calculate indices
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItemDeposit = currentPageDeposit * itemsPerPage;
  const indexOfFirstItemDeposit = indexOfLastItemDeposit - itemsPerPage;

  // Slice the transactions array
  const currentItems = localUser?.transactions_inbanx
    .filter(
      (transaction) =>
        transaction !== null && transaction.description !== "Card Top Up"
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const currentItemsDeposits = localUser?.transactionstopup
    .filter((transaction) => transaction !== null)
    .slice(indexOfFirstItemDeposit, indexOfLastItemDeposit);
  const handleTabClick = (tab) => {
    // console.log(tab);
    setActiveTab(tab);
  };

  const nextPageDeposit = () => {
    setCurrentPageDeposit((prev) => prev + 1);
  };

  const prevPageDeposit = () => {
    if (currentPageDeposit > 1) {
      setCurrentPageDeposit((prev) => prev - 1);
    }
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Fragment>
      <div>
        <Col>
          <Card>
            <div className="card-header border-0 pb-0">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "transactions" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("transactions")}
                  >
                    Spendings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "deposits" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("deposits")}
                  >
                    Deposits
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <Card.Body>
                {activeTab === "transactions" && (
                  <>
                    <div className="tab-pane fade show active">
                      <Table responsive>
                        {/* <thead>
                                    <tr className="text-black text-weight-600">
                                      <th>Description</th>
                                      <th>Amount</th>
                                      <th>Date</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead> */}
                        <tbody>
                          {currentItems.length > 0 ? (
                            currentItems.map((transaction) => (
                              <tr className="text-black" key={transaction.id}>
                                <td className="text-black">
                                  {transaction.description}
                                </td>
                                <td
                                  className="text-black"
                                  style={{ width: "110px" }}
                                >
                                  -{Number(transaction.amount / 100).toFixed(2)}{" "}
                                  USD
                                </td>
                                <td className="text-black">
                                  {transaction.date}
                                </td>
                                <td className="text-success">Completed</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                className="text-black text-center"
                                colSpan="4"
                              >
                                No Transactions available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>{" "}
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary m-2"
                          onClick={prevPage}
                          disabled={currentPage === 1} // disable button when on first page
                        >
                          <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <button
                          className="btn btn-primary m-2"
                          onClick={nextPage}
                          disabled={
                            currentPage ===
                            Math.ceil(
                              localUser?.transactions.length / itemsPerPage
                            )
                          }
                        >
                          <i className="fa-solid fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {activeTab === "deposits" && (
                  <>
                    <Table responsive>
                      {/* <thead>
                                  <tr className="text-black text-weight-600">
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                  </tr>
                                </thead> */}
                      <tbody>
                        {currentItemsDeposits.map((transaction) => (
                          <tr className="text-black" key={transaction.id}>
                            <td className="text-black">
                              {transaction.description}
                            </td>
                            <td
                              className="text-black"
                              style={{ width: "130px" }}
                            >
                              +{Number(transaction.amount).toFixed(2)} USDT
                            </td>
                            <td className="text-black">{transaction.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>{" "}
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn btn-primary m-2"
                        onClick={prevPageDeposit}
                        disabled={currentPageDeposit === 1}
                      >
                        <i className="fa-solid fa-chevron-left"></i>
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        onClick={nextPageDeposit}
                        disabled={
                          currentPageDeposit ===
                          Math.ceil(
                            localUser?.transactionstopup.length / itemsPerPage
                          )
                        }
                      >
                        <i className="fa-solid fa-chevron-right"></i>
                      </button>
                    </div>
                  </>
                )}
              </Card.Body>
            </div>
          </Card>
        </Col>
      </div>
    </Fragment>
  );
};

export default CardTransactions;
