import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  useAccount,
  useNetwork,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LockIcon from "@mui/icons-material/Lock";
import Web3 from "web3";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  width: "100%",
  maxWidth: 400,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const Checkout = () => {
  const { transactionId } = useParams();
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [manualPayment, setManualPayment] = useState(false);
  const [network, setNetwork] = useState("BSC (BEP20)");
  const [activeStep, setActiveStep] = useState(0);
  const [timer, setTimer] = useState(900);
  const steps = [
    "Receiving Payment",
    "Forwarding Payment",
    "Waiting for Card Funding",
  ];
  const walletAddress = "0x7562d4d41a2b67e42141932287db358007fcc076"; // Change as necessary
  const coin = "USDT";

  const { write: contractWrite, isLoading: isWriting } = useContractWrite({
    // Add your contract address and ABI here
    addressOrName: "your_contract_address",
    contractInterface: "your_contract_ABI",
    functionName: "your_function_name",
    args: [
      /* arguments for the function */
    ],
  });

  const { isLoading: isWaiting } = useWaitForTransaction({
    onSuccess: (data) => {
      console.log("Transaction success:", data);
      setActiveStep(steps.length);
    },
    onError: (error) => {
      console.error("Transaction error:", error);
    },
  });

  useEffect(() => {
    let interval;
    if (activeStep === 2) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [activeStep]);

  useEffect(() => {
    if (timer === 0) {
      // Handle timer expiration
    }
  }, [timer]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        alert("Wallet address copied to clipboard");
      })
      .catch((err) => {
        alert("Failed to copy wallet address");
      });
  };

  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  };

  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    return (
      <div>
        {completed ? (
          <CheckCircleIcon color="primary" />
        ) : active ? (
          <HourglassEmptyIcon color="action" />
        ) : (
          <LockIcon color="disabled" />
        )}
      </div>
    );
  };

  const handlePay = async () => {
    try {
      // Replace with the actual call to your contract's function
      await contractWrite();
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        Transaction Type
      </Typography>
      {!isConnecting && !isDisconnected && address ? (
        <StyledPaper elevation={3}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 2 && (
            <Typography variant="h6" mt={2}>
              Time Remaining: {Math.floor(timer / 60)}:
              {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={handlePay}
            disabled={isWriting || isWaiting}
            mt={2}
          >
            Pay
          </Button>
        </StyledPaper>
      ) : (
        <StyledPaper elevation={3}>
          <Typography variant="h6">Choose Payment Method</Typography>
          <Button
            variant="contained"
            onClick={() =>
              window.ethereum.request({ method: "eth_requestAccounts" })
            }
            mt={2}
          >
            Connect Wallet
          </Button>
          <Button
            variant="contained"
            onClick={() => setManualPayment(true)}
            mt={2}
            ml={2}
          >
            Manual Payment
          </Button>
          {manualPayment && (
            <Box mt={2}>
              <Typography variant="h6">Choose Network</Typography>
              <select value={network} onChange={handleNetworkChange}>
                <option value="BSC (BEP20)">BSC (BEP20)</option>
                <option value="Polygon (MATIC)">Polygon (MATIC)</option>
              </select>
              <Box mt={2}>
                <QRCode value={walletAddress} size={256} />
              </Box>
              <Typography variant="h6" mt={2}>
                Wallet Address
              </Typography>
              <Typography variant="body1" style={{ wordBreak: "break-word" }}>
                {walletAddress}
              </Typography>
              <Button variant="contained" onClick={handleCopy} mt={2}>
                Copy Wallet Address
              </Button>
              <Typography variant="h6" mt={2}>
                Transaction ID
              </Typography>
              <Typography variant="body1">{transactionId}</Typography>
            </Box>
          )}
        </StyledPaper>
      )}
    </StyledContainer>
  );
};

export default Checkout;
