export const virtual_countries = [
  {
    id: 18630,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "DZ",
    alpha3: "DZA",
    name: "Algeria",
  },
  {
    id: 18631,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "AU",
    alpha3: "AUS",
    name: "Australia",
  },
  {
    id: 18632,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "AT",
    alpha3: "AUT",
    name: "Austria",
  },
  {
    id: 18633,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "BD",
    alpha3: "BGD",
    name: "Bangladesh",
  },
  {
    id: 18634,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "BE",
    alpha3: "BEL",
    name: "Belgium",
  },
  {
    id: 18635,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "BR",
    alpha3: "BRA",
    name: "Brazil",
  },
  {
    id: 18636,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "CA",
    alpha3: "CAN",
    name: "Canada",
  },
  {
    id: 18637,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "CO",
    alpha3: "COL",
    name: "Colombia",
  },
  {
    id: 18638,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "DM",
    alpha3: "DMA",
    name: "Dominica",
  },
  {
    id: 18639,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "EC",
    alpha3: "ECU",
    name: "Ecuador",
  },
  {
    id: 18640,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "EG",
    alpha3: "EGY",
    name: "Egypt",
  },
  {
    id: 18641,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "FI",
    alpha3: "FIN",
    name: "Finland",
  },
  {
    id: 18642,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "FR",
    alpha3: "FRA",
    name: "France",
  },
  {
    id: 18643,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "DE",
    alpha3: "DEU",
    name: "Germany",
  },
  {
    id: 18644,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "GR",
    alpha3: "GRC",
    name: "Greece",
  },
  {
    id: 18645,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "IN",
    alpha3: "IND",
    name: "India",
  },
  {
    id: 18646,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "ID",
    alpha3: "IDN",
    name: "Indonesia",
  },
  {
    id: 18647,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "IE",
    alpha3: "IRL",
    name: "Ireland",
  },
  {
    id: 18648,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "IT",
    alpha3: "ITA",
    name: "Italy",
  },
  {
    id: 18649,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "JM",
    alpha3: "JAM",
    name: "Jamaica",
  },
  {
    id: 18650,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "JO",
    alpha3: "JOR",
    name: "Jordan",
  },
  {
    id: 18651,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "MY",
    alpha3: "MYS",
    name: "Malaysia",
  },
  {
    id: 18652,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "MX",
    alpha3: "MEX",
    name: "Mexico",
  },
  {
    id: 18653,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "NL",
    alpha3: "NLD",
    name: "Netherlands",
  },
  {
    id: 18654,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "PH",
    alpha3: "PHL",
    name: "Philippines",
  },
  {
    id: 18655,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "PL",
    alpha3: "POL",
    name: "Poland",
  },
  {
    id: 18656,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "PT",
    alpha3: "PRT",
    name: "Portugal",
  },
  {
    id: 18657,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "QA",
    alpha3: "QAT",
    name: "Qatar",
  },
  {
    id: 18658,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "SA",
    alpha3: "SAU",
    name: "Saudi Arabia",
  },
  {
    id: 18659,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "SG",
    alpha3: "SGP",
    name: "Singapore",
  },
  {
    id: 18660,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "ES",
    alpha3: "ESP",
    name: "Spain",
  },
  {
    id: 18661,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "TH",
    alpha3: "THA",
    name: "Thailand",
  },
  {
    id: 18662,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "AE",
    alpha3: "ARE",
    name: "United Arab Emirates",
  },
  {
    id: 18663,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "GB",
    alpha3: "GBR",
    name: "United Kingdom",
  },
  {
    id: 11111,
    description: "Mastercard Prepaid USD Debit (Virtual only)",
    country: "USA",
    alpha3: "USA",
    name: "Rest of World",
  },
];
