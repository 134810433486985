import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import TransferOwl from "./../Mophy/Dashboard/TransferOwl";
import TransferBarGraph from "./../Mophy/Dashboard/TransferBarGraph";
import HomeTab from "./../Mophy/Dashboard/HomeTab";
import Donut from "./../Mophy/Dashboard/Donut";
import Wizard from "../Forms/Wizard/Wizard";
import Spinner from "react-bootstrap/Spinner";

//Images
import card1 from "./../../../images/card/card.png";
import dual from "./../../../images/dual-dot.png";
import profile from "./../../../images/user_logo.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const LimitChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/LimitChart"), 1000)
);
const IncomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/IncomeChart"), 1000)
);
const OutomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/OutomeChart"), 1000)
);
const WeeklyChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/WeeklyChart"), 1000)
);

const Home = () => {
  const dispatch = useDispatch();

  const [sendMessage, setSendMessage] = useState(false);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const user = useSelector((state) => state.user.user);
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);

  const [apiFee, setApiFee] = useState(0);
  const [qrFee, setqrFee] = useState(0);
  const [blockchainFee, setblockchainFee] = useState(0);
  const [camFee, setCamFee] = useState(0);
  const [loadOnCard, setLoadOnCard] = useState(0);
  const [fcfFee, setFcfFee] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [toFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);

  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cardBalance, setcardBalance] = useState(0);
  const [issuer_Last4, setissuer_Last4] = useState("0000");

  useEffect(() => {
    if (user) {
      //   console.log("set user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      setLocalUser(user);
      setissuer_Last4(user?.cards?.issuer_Last4);
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      //   console.log("trying fetch userdata", JSON.stringify(user));
      try {
        const response = await fetch(
          `https://api.bridge-bit.com/api/user/${localUser.firebaseUid}`
        );
        if (!response.ok) {
          throw new Error("User not found.");
        }
        const userData = await response.json();
        // console.log("userData", userData);
        dispatch({ type: "SET_USER", payload: userData });
        setLocalUser(userData);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchBalance = async () => {
      if (localUser?.cards?.cardKitNumber !== 12345) {
        const balance = await fetchcardbalance();
        setcardBalance(balance);
      }
    };

    fetchUserData();
    fetchBalance();
  }, []);

  const handecalc = async () => {
    console.log("LoadAmount", LoadAmount);

    const fee = await fetchblockhainfee();
    if (isNaN(fee)) {
      console.log("Invalid fee value: ", fee);
    } else {
      console.log("fee", fee);
      setblockchainFee(Number(fee));
    }

    var api_fee = LoadAmount * 0.01;
    var qr_fee = LoadAmount * 0.01;
    setqrFee(qr_fee);
    setApiFee(api_fee);
    var cam_fee = LoadAmount * 0.05 + 5;
    setCamFee(cam_fee);
    var load_on_card = LoadAmount - cam_fee;
    setLoadOnCard(load_on_card);
    var fcf_fee = load_on_card * 0.0275 + 3 - Number(fee);
    setFcfFee(fcf_fee);
    var to_fcf = (load_on_card + 3) / 0.9725;
    console.log("to_fcf", to_fcf);
    console.log("to_fcf", to_fcf.toFixed(2));
    // setToFcf(to_fcf.toFixed(2));
    // var to_fcf_percentage = (to_fcf / (LoadAmount * 0.99)) * 100;
    // setToFcfPercentage(to_fcf_percentage);
    // var to_cam_percentage =
    //   ((LoadAmount * 0.99 - to_fcf) / (LoadAmount * 0.99)) * 100;
    // setToCamPercentage(to_cam_percentage);
  };

  const handleLoadClick = async () => {
    setIsLoading(true);
    try {
      let amount = loadOnCard.toString();
      console.log("amount", amount);
      const apiResponse = await fetchDepositData(
        "2000003255932559",
        amount,
        "BSC.USDT"
      );
      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);
      let fcf_amount = apiResponse.data.crypto_currency_amount;
      setToFcf(fcf_amount);
      var to_fcf_percentage = fcf_amount / (LoadAmount * 0.99);
      setToFcfPercentage(to_fcf_percentage);
      var to_cam_percentage =
        (LoadAmount * 0.99 - fcf_amount) / (LoadAmount * 0.99);
      setToCamPercentage(to_cam_percentage);

      var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(2);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(2);
      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: "https://api.bridge-bit.com/cryptapi/callss", // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@0x38861AF3f588c36560931d50aa45b37c7aD8D8C4|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const ticker = "bep20/usdt"; // Replace with your ticker (e.g., 'btc', 'eth', etc.)
      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setCreatedAddress(data.address_in); // Update the state with the new address
        fetchQrCode(data.address_in); // Fetch the QR code for this address
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    console.log("cardNumber", cardNumber);
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/load-deposit",
      {
        card_number: cardNumber,
        amount: amount.toString(),
        token_symbol: tokenSymbol,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  const fetchcardbalance = async () => {
    if (
      localUser.cards.cardKitNumber != null &&
      localUser.cards.cardKitNumber !== 12345
    ) {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/physical-cards/get-balance",
        {
          card_number: localUser.cards.cardKitNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      if (!response.status === 200) {
        throw new Error("Network response was not ok");
      }
      console.log("balance update", response.data.message);
      return response.data.message;
    }
  };

  const fetchblockhainfee = async (address) => {
    try {
      const response = await fetch(
        `https://api.cryptapi.io/bep20/usdt/estimate/?addresses=2&priority=default`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("fetchblockhainfee:", data.estimated_cost_currency.USD);
      return data.estimated_cost_currency.USD;
    } catch (error) {
      console.error("Error fetching fetchblockhainfee:", error);
    }
  };

  const fetchQrCode = async (address) => {
    try {
      var value = LoadAmount + qrFee;
      const query = new URLSearchParams({
        address: address,
        value: value,
        size: "512",
      }).toString();

      const ticker = "bep20/usdt"; // Replace with your actual ticker
      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      // Assuming the QR code data contains a base64 encoded image
      if (data.qr_code) {
        setQrCode(data.qr_code); // Update the state with the QR code data
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">KYC</h2>
      </div>

      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="card overflow-hidden">
                <div
                  className="text-center p-3  "
                  style={{ background: `black` }}
                >
                  <div className="profile-photo">
                    <img
                      src={profile}
                      width="100"
                      className="m-auto img-fluid rounded-circle d-block"
                      alt=""
                    />
                  </div>
                  <h3 className="mt-3 mb-1 text-white">{localUser.username}</h3>
                  <p className="text-white mb-0">{localUser.email}</p>
                </div>
                <ul className="list-group list-group-flush">
                  {/* <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">KYC Status</span>{" "}
                    <strong
                      className={
                        localUser.isKycAccepted === "approved"
                          ? "text-success text-uppercase"
                          : localUser.isKycAccepted === "pending"
                          ? "text-warning text-uppercase"
                          : "text-danger text-uppercase"
                      }
                    >
                      {localUser.isKycAccepted}
                    </strong>
                  </li> */}
                  {localUser.isKycAccepted !== "approved" ? <Wizard /> : null}

                  {/* <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Years Old</span>{" "}
                    <strong className="text-muted">Age: 24 </strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Patient Weight</span>{" "}
                    <strong className="text-muted">65 Kg </strong>
                  </li> */}
                </ul>
                {/* <div className="card-footer border-0 mt-0">
                  <button
                    className="btn btn-primary btn-lg d-block w-100"
                    disabled={
                      localUser.isKycAccepted === "approved" ? true : false
                    }
                  >
                    <i className="fa fa-bell-o"></i> Submit KYC
                  </button>
                </div> */}
              </div>
            </div>

            {/* <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
              <div className="card-bx stacked">
                <img src={card1} alt="" className="mw-100" />
                <div className="card-info text-white">
                  <p className="mb-1">Card Balance</p>
                  <h2 className="fs-36 text-white mb-sm-4 mb-3">
                    ${cardBalance}
                  </h2>
                  <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-3">
                    <img src={dual} alt="" className="dot-img" />
                    <h4 className="fs-20 text-white mb-0">
                      **** **** **** {issuer_Last4}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-3">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        onChange={(e) => setLoadAmount(e.target.value)}
                      />
                      <button
                        onClick={handecalc}
                        className="btn btn-secondary"
                        type="button"
                      >
                        Calc
                      </button>{" "}
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <button
                          onClick={handleLoadClick}
                          className="btn btn-secondary"
                          type="button"
                          disabled={LoadAmount === 0 ? true : false}
                        >
                          Load
                        </button>
                      )}
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>+1% Fee:</p>
                    <p>{qrFee.toFixed(2)}</p>
                  </div>
                  <div>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Blockchain Fee:</p>
                      <p>{blockchainFee}</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>CRYPT API Fee:</p>
                      <p>{apiFee.toFixed(2)}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>CAM Fee:</p>
                      <p>{camFee.toFixed(2)}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Load On Card:</p>
                      <p>{loadOnCard.toFixed(2)}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>FCF Fee:</p>
                      <p>{fcfFee.toFixed(2)}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>To FCF:</p>
                      <p>{toFcf}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>To FCF Percentage:</p>
                      <p>{toFcfPercentage.toFixed(4)}%</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>To CAM Percentage:</p>
                      <p>{toCamPercentage.toFixed(4)}%</p>
                    </div>

                    {createdAddress && (
                      <div
                        className="d-flex align-items-center flex-direction-column"
                        style={{ flexDirection: "column" }}
                      >
                        <p>Deposit Address:</p>
                        <p>{createdAddress}</p>
                      </div>
                    )}
                    {qrCode && (
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          src={`data:image/png;base64,${qrCode}`}
                          alt="QR Code"
                          style={{ maxWidth: "220px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="me-5">
                      <p className="fs-14 mb-1 op6">VALID THRU</p>
                      <span>08/21</span>
                    </div>
                    <div>
                      <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                      <span>Franklin Jr.</span>
                    </div>
                  </div>
                </div>
                <Link to={"/cards-center"}>
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-5 col-sm-4">
              <div
                className="card bgl-primary card-body overflow-hidden p-0 d-flex rounded"
                style={{ maxHeight: "280px" }}
              >
                <div className="p-0 text-center mt-3">
                  <span className="text-black">Daily Limit</span>
                  <h3 className="text-black fs-20 mb-0 font-w600">$4,000</h3>
                  <small>/$10,000</small>
                </div>
                <div className="mt-auto line-chart-demo">
                  <LimitChart />
                </div>
              </div>
            </div> */}

            {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div className="pr-3 mb-sm-0 mb-3 me-auto">
                    <h4 className="fs-20 text-black mb-1">Quick Transfer</h4>
                    <span className="fs-12">
                      Lorem ipsum dolor sit amet, consectetur
                    </span>
                  </div>
                  <span className="fs-24 text-black font-w600">$56,772.38</span>
                </div>
                <div className="card-body">
                  <TransferOwl />
                  <form>
                    <div className="form-group row style-1 align-items-center">
                      <label className="fs-18 col-sm-3 text-black font-w500">
                        Amount
                      </label>
                      <div className="input-group col-sm-9">
                        <input type="number" className="form-control" />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary rounded"
                            type="button"
                          >
                            TRANSFER NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            {/* <div className="col-xl-6 col-sm-6">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0">
                  <div className="me-3 mb-2">
                    <p className="fs-14 mb-1">Income</p>
                    <span className="fs-24 text-black font-w600">$65,123</span>
                  </div>
                  <span className="fs-12 mb-2">
                    <svg
                      className=""
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
                        stroke="#2BC155"
                        strokeWidth="2"
                      />
                      <path
                        d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
                        fill="url(#paint0_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="10.2499"
                          y1="3"
                          x2="10.9999"
                          y2="13.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0"
                            stopColor="#2BC155"
                            stopOpacity="0.73"
                          />
                          <stop
                            offset="1"
                            stopColor="#2BC155"
                            stopOpacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <IncomeChart />
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-6 col-sm-6">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0">
                  <div className="me-3 mb-2">
                    <p className="fs-14 mb-1">Outome</p>
                    <span className="fs-24 text-black font-w600">$24,551</span>
                  </div>
                  <span className="fs-12 mb-2">
                    <svg
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z"
                        fill="url(#paint0_linear1)"
                      />
                      <path
                        d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5"
                        stroke="#FF2E2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear1"
                          x1="10.5"
                          y1="2.625"
                          x2="9.64345"
                          y2="13.9935"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0" stopColor="#FF2E2E" />
                          <stop
                            offset="1"
                            stopColor="#FF2E2E"
                            stopOpacity="0.03"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <OutomeChart />
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-12">
              <div className="card overflow-hidden">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div className="mb-sm-0 mb-2">
                    <p className="fs-14 mb-1">Weekly Wallet Usage</p>
                    <span className="mb-0">
                      <svg
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 6L5.99994 -2.62268e-07L-6.10352e-05 6"
                          fill="#2BC155"
                        />
                      </svg>
                      <strong className="fs-24 text-black ms-2 me-3">
                        43%
                      </strong>
                      Than last week
                    </span>
                  </div>
                  <span className="fs-12">
                    <svg
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
                        stroke="#2BC155"
                        strokeWidth="2"
                      />
                      <path
                        d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
                        fill="url(#paint0_linear2)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear2"
                          x1="10.2499"
                          y1="3"
                          x2="10.9999"
                          y2="13.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0"
                            stopColor="#2BC155"
                            stopOpacity="0.73"
                          />
                          <stop
                            offset="1"
                            stopColor="#2BC155"
                            stopOpacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <WeeklyChart />
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-5 col-xxl-12 col-md-5">
                      <h4 className="fs-20 text-black mb-4">Spendings</h4>
                      <div className="row">
                        <div className="d-flex col-xl-12 col-xxl-6  col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#AC39D4"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Investment</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$1,415</span>
                              /$2,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#40D4A8"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Installment</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$1,567</span>
                              /$5,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#1EB6E7"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Restaurant</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$487</span>
                              /$10,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#461EE7"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Property</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$3,890</span>
                              /$4,000
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7  col-xxl-12 col-md-7">
                      <div className="row">
                        <div className="col-sm-6 mb-4">
                          <div className="bg-secondary rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={71}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">71%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Investment
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <div className="bg-success rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={30}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">30%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Installment
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-sm-0 mb-4">
                          <div className="border border-2 border-primary rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={5}
                                backgroundColor="rgb(30, 170, 231)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-black">5%</small>
                            </div>
                            <span className="fs-14 text-black d-block">
                              Restaurant
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-sm-0 mb-4">
                          <div className="bg-info rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={96}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">96%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Property
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-xl-12">
              <div className="card">{/* <HomeTab /> */}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Home;
