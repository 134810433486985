import React, { useState } from "react";
import axios from "axios";
import { countries } from "../../../../jsx/countries.js";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const StepOne = ({ onSubmitSuccess, onCardHolderIdReceived }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("AT");
  const [selectedState, setSelectedState] = useState("");
  const [apiError, setApiError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address_line1: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    birth_date: "",
    genderId: 0,
  });

  const nameRegex = /^[a-zA-Z\s-]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^[0-9]{9,15}$/;
  const callingCodeRegex = /^[0-9]{3}$/;

  const onlynumbers = /^[0-9]{3,15}$/;
  const stateRegex = /^[a-zA-Z\s-]+$/;

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
      case "country":
        return nameRegex.test(value) && value.length <= 22;

      case "email":
        return emailRegex.test(value) && value.length >= 3;
      case "phone":
      case "cellPhoneNumber":
        return phoneRegex.test(value);
      case "callingCode":
        return callingCodeRegex.test(value) && value.length === 3;

      case "city":
        return nameRegex.test(value) && value.length >= 3;

      case "zip":
        return value.length >= 3;

      case "state":
        return value.length >= 2 && value.length <= 25;
      // Add other field validations here...
      default:
        return true;
    }
  };

  const germanStates = [
    { name: "Choose State", shortCode: "CHOOSE" },
    { name: "Baden-Württemberg", shortCode: "BW" },
    { name: "Bayern", shortCode: "BY" },
    { name: "Berlin", shortCode: "BE" },
    { name: "Brandenburg", shortCode: "BB" },
    { name: "Bremen", shortCode: "HB" },
    { name: "Hamburg", shortCode: "HH" },
    { name: "Hessen", shortCode: "HE" },
    { name: "Niedersachsen", shortCode: "NI" },
    { name: "Mecklenburg-Vorpommern", shortCode: "MV" },
    { name: "Nordrhein-Westfalen", shortCode: "NW" },
    { name: "Rheinland-Pfalz", shortCode: "RP" },
    { name: "Saarland", shortCode: "SL" },
    { name: "Sachsen", shortCode: "SN" },
    { name: "Sachsen-Anhalt", shortCode: "ST" },
    { name: "Schleswig-Holstein", shortCode: "SH" },
    { name: "Thüringen", shortCode: "TH" },
  ];

  const handleCountryChange = (e) => {
    console.log("handleCountryChange", e.target.value);
    const newCountry = e.target.value;
    setSelectedCountry(newCountry);
    setFormData({ ...formData, country: newCountry, state: "" });

    if (newCountry !== "DE") {
      setSelectedState("");
    }
  };

  const handleStateChange = (e) => {
    console.log("selectedCountry", selectedCountry);
    const newState = e.target.value;
    console.log("newState", newState);
    if (selectedCountry !== "DE") {
      setSelectedState(newState);
      setFormData({ ...formData, state: newState });
    } else {
      const stateShortCode = germanStates.find(
        (state) => state.name === newState
      )?.shortCode;
      setSelectedState(newState);
      setFormData({ ...formData, state: stateShortCode });
    }
  };

  const handleApiErrors = (apiErrors) => {
    if (apiErrors === "Country is not allowed") {
      const newErrors = {};
      newErrors["country"] = "Country not allowed";
      setErrors(newErrors);
    } else {
      const newErrors = {};
      Object.keys(apiErrors).forEach((key) => {
        // Assuming you want to display only the first error message for each field
        newErrors[key] = apiErrors[key][0];
      });
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    const mapObject = {
      ä: "ae",
      ö: "oe",
      ü: "ue",
      ß: "ss",
    };

    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "state" ||
      name === "address_line1" ||
      name === "city"
    ) {
      // Apply transformation
      value = value.replace(/ä|ö|ü|ß/gi, (matched) => mapObject[matched]);
    }

    const mapPhone = {
      "+": "00",
    };
    if (name === "phone") {
      value = value.replace(/\+/gi, (matched) => mapPhone[matched]);
    }

    const isValid = validateField(name, value);
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: isValid ? "" : "Invalid input" });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();

    setIsLoading(true);

    const apiEndpoint =
      // "https://sandbox.fcfpay.com/api/physical-cards/create-card-holder";
      "https://dashboard.croesus-asset.management/api/physical-cards/create-card-holder";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        //   "Bearer CMZDAudUyvVZ8KIc58FymvqwBBxJM1ZgqC6ZHeqIBRbvzh8rTDvMB00DvwVI",
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      const fullName = `${formData.firstName} ${formData.lastName}`;
      const trimmedFullName = fullName.substring(0, 22);

      const dataToSend = {
        name: trimmedFullName,
        cellPhoneNumber: (formData.phone + 1).substring(0, 15),
        callingCode: "123",
        country: selectedCountry,
        countryCallingCode: selectedCountry,
        ...formData,
      };
      console.log("formdata", dataToSend);

      let formIsValid = true;
      for (const [key, value] of Object.entries(formData)) {
        if (!validateField(key, value)) {
          console.log("error");
          formIsValid = false;
          setErrors((prev) => {
            const newErrors = { ...prev, [key]: "Invalid input" };
            console.log(newErrors); // Log the new errors
            return newErrors;
          });
          setIsLoading(false);
        }
      }
      if (formIsValid) {
        const response = await axios.post(apiEndpoint, dataToSend, { headers });
        console.log(response.data); // Handle the response as needed
        if (response.data.success === true) {
          onSubmitSuccess(true); // Call this when submission is successful
          const cardHolderId = response.data.message; // Assuming this is how the ID is received

          const data = {
            userId: localUser.id,
            card_holder_id: cardHolderId,
            ...dataToSend,
          };
          console.log("data", data);

          fetch("https://api.bridge-bit.com/api/update_user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }).then((response) => response.json());

          window.scrollTo(0, 0);
          onCardHolderIdReceived(cardHolderId);
          setIsLoading(false);
        } else {
          setApiError(response.data.message || "An error occurred");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("There was an error!", error);
      console.log("error.response.data.message", error.response.data.message);

      if (
        error.response.data.message === "Card holder with this email exist" ||
        error.response.data.message === "Card holder with this phone exist"
      ) {
        const fullName = `${formData.firstName} ${formData.lastName}`;
        const dataToSend = {
          name: fullName,
          cellPhoneNumber: (formData.phone + 1).substring(0, 15),
          callingCode: "123",
          country: selectedCountry,
          countryCallingCode: selectedCountry,
          ...formData,
          email: "1" + formData.email,
          phone: (formData.phone + 1).substring(0, 15),
          cellPhoneNumber: (formData.phone + 1).substring(0, 15),
        };
        console.log("dataToSend", dataToSend);
        const response = await axios.post(apiEndpoint, dataToSend, { headers });
        console.log(response.data); // Handle the response as needed
        if (response.data.success === true) {
          onSubmitSuccess(true); // Call this when submission is successful
          const cardHolderId = response.data.message; // Assuming this is how the ID is received

          const data = {
            userId: localUser.id,
            card_holder_id: cardHolderId,
            name: fullName,
            cellPhoneNumber: (formData.phone + 1).substring(0, 15),
            phone: (formData.phone + 1).substring(0, 15),
            callingCode: "123",
            country: selectedCountry,
            countryCallingCode: selectedCountry,
            ...formData,
            email: formData.email,
            cellPhoneNumber: (formData.phone + 1).substring(0, 15),
            phone: (formData.phone + 1).substring(0, 15),
          };
          console.log("data", data);

          fetch("https://api.bridge-bit.com/api/update_user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }).then((response) => response.json());

          window.scrollTo(0, 0);
          onCardHolderIdReceived(cardHolderId);
          setIsLoading(false);
        } else {
          setApiError(response.data.message || "An error occurred");
          setIsLoading(false);
        }
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        handleApiErrors(error.response.data.message);
        setIsLoading(false);
      } else {
        // Handle other types of errors (like network errors)
        setApiError(error.response?.data?.message || "An error occurred");
        setIsLoading(false);
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">First Name*</label>
              <input
                type="text"
                name="firstName"
                className="form-control text-black"
                onChange={handleChange}
                //  value={
                //    localUser.firstName ? localUser.firstName : formData.firstName
                //  }
                value={formData.firstName}
                //  disabled={!!localUser.firstName}
                required
              />
              {errors.firstName && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.firstName}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Last Name*</label>
              <input
                type="text"
                name="lastName"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.lastName}
                required
              />
              {errors.lastName && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.lastName}
                </div>
              )}
            </div>
          </div>
          {/* Additional fields as per API requirements */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Address Line 1*</label>
              <input
                type="text"
                name="address_line1"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.address_line1}
                required
              />
              {errors.address_line1 && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.address_line1}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">City*</label>
              <input
                type="text"
                name="city"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.city}
                required
              />
              {errors.city && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.city}
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Country*</label>
              <select
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                className="form-control text-black"
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.country}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">State*</label>
              {selectedCountry === "DE" ? (
                <select
                  value={selectedState}
                  onChange={handleStateChange}
                  className="form-control text-black"
                >
                  {germanStates.map((state) => (
                    <option key={state.shortCode} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  name="state"
                  className="form-control text-black"
                  type="text"
                  value={selectedState}
                  onChange={(e) => handleStateChange(e)}
                  required
                />
              )}
              {errors.state && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.state}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Postal Code*</label>
              <input
                type="text"
                name="zip"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.zip}
                required
              />
              {errors.zip && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.zip} (only numbers allowed)
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Email*</label>
              <input
                type="email"
                name="email"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.email}
                required
              />
              {errors.email && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.email}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Phone Number*</label>
              <input
                type="text"
                name="phone"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.phone}
                required
              />
              {errors.phone && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.phone} (only numbers allowed)
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Cell Phone Number*</label>
              <input
                type="text"
                name="cellPhoneNumber"
                className="form-control"
                onChange={handleChange}
                value={formData.cellPhoneNumber}
                required
              />
            </div>
          </div> */}
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Calling Code*</label>
              <input
                type="text"
                name="callingCode"
                className="form-control"
                onChange={handleChange}
                value={formData.callingCode}
                required
              />
            </div>
          </div> */}
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Country Calling Code*</label>
              <input
                type="text"
                name="countryCallingCode"
                className="form-control"
                onChange={handleChange}
                value={formData.countryCallingCode}
                required
              />
            </div>
          </div> */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black  text-black">
                Birth Date*
              </label>
              <input
                type="date"
                name="birth_date"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.birth_date}
                required
              />
              {errors.birth_date && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.birth_date}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Gender*</label>
              <select
                name="genderId"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.genderId}
              >
                <option value="0">Male</option>
                <option value="1">Female</option>
              </select>
              {errors.genderId && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.genderId}
                </div>
              )}
            </div>
          </div>
          {apiError && <div className="alert alert-danger">{apiError}</div>}

          <div
            className="col-lg-12 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="submit"
              className="btn btn-primary "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100px",
              }}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepOne;
