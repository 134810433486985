import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const StepThree = ({ cardHolderId, onSubmitSuccess }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [errors, setErrors] = useState({});

  const cardHolderID = cardHolderId || localUser.cardHolderId;
  console.log("cardHolderID", cardHolderID);

  const [formData, setFormData] = useState({
    cardHolderID: cardHolderID,
    address_TypeId: 0,
    address_IssuedBy: "",
    address_IssuedDate: "",
    address_LangId: 0,
    address_FrontSide: "",
  });

  const validateField = (name, value) => {
    const nameRegex = /^[a-zA-Z\s-]+$/;

    switch (name) {
      case "address_IssuedBy":
        return nameRegex.test(value) && value.length <= 22;

      default:
        return true;
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    const isValid = validateField(name, value);

    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [name]: isValid ? "" : "Invalid input" });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      alert("Only PDF format is supported");
      e.target.value = null;
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "pdf") {
      alert("Selected file does not have a .pdf extension");
      e.target.value = null;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setFormData({
        ...formData,
        address_FrontSide: reader.result,
        address_BackSide: reader.result,
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var formDataToSend = new FormData();
    formDataToSend.append("address_FrontSide", formData.address_FrontSide);
    formDataToSend.append("user", localUser.id);
    console.log("localUser", localUser);
    console.log("formDataToSend", formDataToSend);
    console.log("formData", formData);

    const response = await fetch("https://api.bridge-bit.com/api/upload", {
      method: "POST",
      body: formDataToSend,
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      console.log(jsonResponse);
      let frontside_file = jsonResponse.address_frontside_filename;

      let dataToSend = {
        address_FrontSide_path: formData.address_FrontSide,
        frontside_file: frontside_file,
        user: localUser.id,
        cardholder_id: localUser.cardHolderId,
        address_TypeId: formData.address_TypeId,
        address_LangId: formData.address_LangId,
        address_IssuedBy: formData.address_IssuedBy,
        address_IssuedDate: formData.address_IssuedDate,
      };
      console.log("dataToSend", dataToSend);
      const response_info = await fetch(
        "https://api.bridge-bit.com/api/upload_address_info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      console.log("response_info", response_info);
      if (response_info.status === 200) {
        console.log("Data was successfully saved.");

        const data = {
          userId: localUser.id,
          kyc_step: 4,
        };
        console.log("data", data);
        const send_kyc = {
          card_holder_id: cardHolderID,
        };
        console.log("send_kyc", send_kyc);
        fetch("https://api.bridge-bit.com/api/update_kyc_step", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((response) => response.json());
        onSubmitSuccess(true);
        setIsLoading(false);
        // Handle success (e.g., show a success message or redirect the user)
      } else {
        // Handle the error from the second request
        console.error("Error saving the data:", response_info.error);
        // Show an error message to the user
      }
    }

    const apiEndpoint =
      // "https://sandbox.fcfpay.com/api/physical-cards/holder-address";
      "https://dashboard.croesus-asset.management/api/physical-cards/holder-address";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        // "Bearer CMZDAudUyvVZ8KIc58FymvqwBBxJM1ZgqC6ZHeqIBRbvzh8rTDvMB00DvwVI",
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      // console.log("formdata", formData);
      // const response = await axios.post(apiEndpoint, formData, { headers });
      // console.log(response.data);
      // if (
      //   response.data.success === true ||
      //   response.data.message === "Something went wrong"
      // ) {
      //   const data = {
      //     userId: localUser.id,
      //     kyc_step: 4,
      //   };
      //   console.log("data", data);
      //   const send_kyc = {
      //     card_holder_id: cardHolderID,
      //   };
      //   console.log("send_kyc", send_kyc);
      //   fetch(
      //     "https://dashboard.croesus-asset.management/api/physical-cards/send-kyc",
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Accept: "application/json",
      //         Authorization:
      //           "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
      //       },
      //       body: JSON.stringify(send_kyc),
      //     }
      //   ).then((response) => response.json());
      //   console.log("response", response);
      //   fetch("https://api.bridge-bit.com/api/update_kyc_step", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(data),
      //   }).then((response) => response.json());
      //   console.log("response", response);
      //   onSubmitSuccess(true);
      //   setIsLoading(false);
      // } else {
      //   setApiError(response.data.message || "An error occurred");
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.error("There was an error!", error);
      setApiError(error.response?.data?.message || "An error occurred");
      setIsLoading(false);
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* Proof of Address Fields */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">Select Document*</label>
              <select
                name="address_TypeId"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.address_TypeId}
              >
                <option value="0">Credit Card Statement</option>
                <option value="1">Utility Bill</option>
                <option value="2">Bank Statement</option>
                <option value="3">Bank Letter</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">
                From Whom Did You Receive This Document?*
              </label>
              <input
                type="text"
                name="address_IssuedBy"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.address_IssuedBy}
                required
              />
              {errors.address_IssuedBy && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errors.address_IssuedBy} (No Numbers allowed)
                </div>
              )}
              Write the name of the institution or company that issued the proof
              of address document.
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Date of Issue*</label>
              <input
                type="date"
                name="address_IssuedDate"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.address_IssuedDate}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">
                Document Language*
              </label>
              <select
                name="address_LangId"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.address_LangId}
              >
                <option value="0">English</option>
                <option value="1">Spanish</option>
                <option value="2">French</option>
                <option value="3">Arabic</option>
                <option value="4">Chinese</option>
                <option value="5">Japanese</option>
                <option value="6">Russian</option>
                <option value="7">Portuguese</option>
                <option value="8">German</option>
                <option value="9">Italian</option>
                <option value="10">Hindi</option>
                <option value="11">Other</option>
              </select>
            </div>
          </div>
          <div className="col-12 mb-2">
            <Accordion className="accordion accordion-danger-solid text-black">
              <Accordion.Item eventKey={110}>
                <Accordion.Header className="accordion-header">
                  Important Information
                </Accordion.Header>
                <Accordion.Collapse className="accordion__body">
                  <div className="accordion-body">
                    - Do not blur out any details <br></br>- Address document
                    cannot be older than 3 months <br></br>
                    {/* <strong>
                      Your Proof Of Address document MUST be in English or
                      Spanish.{" "}
                      <a
                        href="https://translate.google.com/?sl=auto&tl=en&op=images"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Please use this translation tool
                      </a>
                    </strong> 
                    <br></br>*/}
                  </div>
                </Accordion.Collapse>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Document*</label>
              <div className="form-file">
                <input
                  type="file"
                  name="address_FrontSide"
                  className="form-control text-black form-file-input"
                  onChange={handleFileChange}
                  accept=".pdf"
                  required
                />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label  text-black">Document Back*</label>
              <input
                type="file"
                name="address_BackSide"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>{" "}
          {apiError && <div className="alert alert-danger">{apiError}</div>} */}
          <div
            className="col-lg-12 text-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="submit"
              className="btn btn-primary "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100px",
              }}
              disabled={errors.address_IssuedBy}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepThree;
