import React, { Fragment, useState, useContext, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import visa from "../../images/visa_black.PNG";
import gpay from "../../images/GPay_Acceptance_Mark_800.png";
import applepay from "../../images/Apple_Pay_Mark_RGB.svg";
import { Row, Card, Col, Button, Nav, Alert, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { virtual_countries } from "../components/virtual_countries";

import Web3 from "web3";
import axios from "axios";

import { usdt_abi } from "../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_SPLIT_TOPUP_ABI } from "../../../src/contracts/BSC_SPLIT_TOPUP_ABI.js";
//WEB3
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
  useBalance,
} from "wagmi";

const bnblogo =
  "https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png";
const maticlogo = "https://cryptologos.cc/logos/polygon-matic-logo.png";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";

const POLYGON_TOPUP_CONTRACT = "0xE1D32047823a16c0482c526e19741b49381a4d83";
const POLYGON_USDT_CONTRACT = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";

const ETH_TOPUP_CONTRACT = "0x19f3FEbd63C61D7f9073Da6Cd58C75cd31aAe8B3";
const ETH_USDT_CONTRACT = "0xdac17f958d2ee523a2206206994597c13d831ec7";

const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";
const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

const VirtualCards = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [tofcfamount, settofcfamount] = useState(0);

  const [transactionsconfirmed, settransactionsconfirmed] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [loadingAmount, setLoadingAmount] = useState(0);
  const [AmountToPay, setAmountToPay] = useState(0);
  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [ToFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [network, setNetwork] = useState("");
  const [apialert, setalert] = useState("");
  const [address_in, setaddressin] = useState("");
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [issuecardenabled, setissuecardenabled] = useState(false);
  const [txn_hash, settxn_hash] = useState("");
  const [confirmations, setconfirmations] = useState(0);
  const [buyError, setbuyError] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [isLoadingFCF, setisLoadingFCF] = useState(false);
  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);
  const [topupsended, settopupsended] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [virtualCards, setvirtualCards] = useState([]);
  const [net_topup, setnet_topup] = useState(0);
  const [bscBalance, setbscBalance] = useState(0);
  const [bscBalanceWei, setbscBalanceWei] = useState(0);
  const [bscAllowance, setbscAllowance] = useState(0);
  const [isapproved, setisapproved] = useState(false);
  const [card_uuid, setcard_uuid] = useState("");
  const [virtualCardData, setVirtualCardData] = useState(null);
  const [web3statusmessage, setweb3statusmessage] = useState("");
  const [approveamount, setapproveamount] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceModeWeb3, setMaintenanceModeWeb3] = useState(false);

  const dispatch = useDispatch();

  //WEB3 VARS
  let chainid;
  let currency;
  let usdt_contract;
  let split_contract;
  let approve_amount;
  let buy_amount;
  let cur_fcf;
  if (address) {
    chainid = chain.id;

    if (chainid === 137) {
      usdt_contract = POLYGON_USDT_CONTRACT;
      split_contract = POLYGON_TOPUP_CONTRACT;

      approve_amount = Web3.utils.toWei(loadingAmount, "mwei");
      console.log("approve_amount wei", approve_amount);
      buy_amount = approve_amount;
      currency = "matic_usdt";
      cur_fcf = "MATIC.USDT";

      console.log("buy_amount", buy_amount);
      console.log("approve_amount", approve_amount);
    } else if (chainid === 56) {
      usdt_contract = BSC_USDT_CONTRACT;
      split_contract = BSC_TOPUP_CONTRACT;

      approve_amount = Web3.utils.toWei(loadingAmount, "ether");
      console.log("approve_amount wei", approve_amount);

      buy_amount = Web3.utils.toWei(loadingAmount, "ether");
      currency = "bsc_usdt";
      cur_fcf = "BSC.USDT";
      console.log("approve_amount", approve_amount);
    }
  }

  useEffect(() => {
    if (localUser?.settings?.virtual_web3 === 1) {
      console.log("setMaintenanceMode true");
      setMaintenanceModeWeb3(true);
      setMaintenanceMode(true);
    } else {
      console.log("setMaintenanceMode false");
      setMaintenanceModeWeb3(false);
      setMaintenanceMode(false);
    }

    if (chainid === 137) {
      const approve_amount = Web3.utils.toWei(loadingAmount, "mwei");
      setapproveamount(approve_amount);
      console.log("approve_amount wei", approve_amount);
      if (readData) {
        let tokenBalance = Number(readData) / Math.pow(10, 6);
        console.log("bscBalanceETH", tokenBalance);
        setbscBalance(tokenBalance.toFixed(2));
        setbscBalanceWei(readData);
      }
    } else if (chainid === 56) {
      const approve_amount = Web3.utils.toWei(loadingAmount, "ether");
      setapproveamount(approve_amount);
      console.log("approve_amount wei", approve_amount);
      if (readData) {
        let bscBalanceETH = Web3.utils.fromWei(String(readData), "ether");
        console.log("bscBalanceETH", bscBalanceETH);
        setbscBalance(Number(bscBalanceETH).toFixed(2));
      }
    }
  }, [chainid, loadingAmount]);

  const loadingfees = {
    50: [0.055, 0.0525, 0.05, 0.0475],
    100: [0.055, 0.0525, 0.05, 0.0475],
    250: [0.05, 0.0475, 0.045, 0.0425],
    500: [0.045, 0.0425, 0.04, 0.0375],
    1000: [0.0425, 0.04, 0.0375, 0.035],
    1500: [0.04, 0.0375, 0.035, 0.0325],
  };

  const handlegetstatus = async (uuid) => {
    setRefreshLoading((prevState) => ({ ...prevState, [uuid]: true }));
    try {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/cards/check-status",
        {
          uuid: uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      if (response.data.success === true) {
        // Success handling
        console.log("Update Virtual Card:", response.data);
        console.log("Received redeem link:", response.data.message.redeem_link);
        console.log("Received redeem code:", response.data.message.redeem_code);

        axios
          .post("https://api.bridge-bit.com/api/update_virtualcard", {
            // redeem_link: response.data.message.redeem_link,
            // redeem_code: response.data.message.redeem_code,
            redeem_link: response.data.message.redeem_link,
            redeem_code: response.data.message.redeem_code,
            pin: response.data.message.pin,
            expirationDate: response.data.message.expiration_date,
            uuid: uuid,
          })
          .then(async (response) => {
            console.log("Response received:", response);
            if (response.data.success === true) {
              console.log("Successfull:", response.data.message);
              const updatedCard = response.data.data;
              setvirtualCards((prevCards) => {
                // Find index of the updated card in the existing array
                const index = prevCards.findIndex(
                  (card) => card.uuid === updatedCard.uuid
                );

                if (index !== -1) {
                  // Replace the old card data with the updated one
                  const updatedCards = [...prevCards];
                  updatedCards[index] = updatedCard;
                  return updatedCards;
                } else {
                  // If for some reason the card wasn't found, just append the updated card to the list
                  return [...prevCards, updatedCard];
                }
              });
              setweb3statusmessage("Card successfull created...");
            } else {
              console.error(
                "Request was not successful. Please handle it accordingly."
              );
            }
          })
          .catch((error) => {
            console.error("Error encountered while sending topup data:", error);
          });
        setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      } else {
        // Error handling
        console.log("Error message from server:", response.data.message);
        setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      }
    } catch (error) {
      setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("No response was received", error.request);
      } else {
        console.error("Error setting up the request", error.message);
      }
    }
  };

  const handleLoadClick = async () => {
    setisLoading(true);
    try {
    } catch (error) {
      console.error("Error creating new address:", error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    console.log("localUser", localUser);
    if (
      localUser?.settings?.virtual_maintenance === 1 &&
      localUser?.settings?.api_maintenance === 1
    ) {
      setMaintenanceMode(true);
    } else {
      setMaintenanceMode(false);
    }
    if (localUser?.settings?.virtual_web3 === 1) {
      setMaintenanceModeWeb3(true);
    } else {
      setMaintenanceModeWeb3(false);
    }
    if (address) {
      if (localUser?.settings?.virtual_web3 === 1) {
        console.log("setMaintenanceMode true");
        setMaintenanceModeWeb3(true);
        setMaintenanceMode(true);
      } else {
        console.log("setMaintenanceMode false");
        setMaintenanceModeWeb3(false);
        setMaintenanceMode(false);
      }
    }
    if (localUser?.virtualcards) {
      setvirtualCards(localUser?.virtualcards);
    }
  }, [localUser]);

  useEffect(() => {
    if (address_in !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        fetchUserTransactions();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [address_in]);

  const fetchUserData = (firebaseUid) => {
    fetch(`https://api.bridge-bit.com/api/user/${firebaseUid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("User not found.");
        }
        return response.json();
      })
      .then((userData) => {
        dispatch({ type: "SET_USER", payload: userData });
        localStorage.setItem("user", JSON.stringify(userData));
        setLocalUser(userData);
        console.log("User data updated:", userData);
      });
  };

  const fetchUserTransactions = async () => {
    try {
      console.log("address in", address_in);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${address_in}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      settxn_hash(data.txid_in);
      setconfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTransactions(response.data);
      if (response.data) {
        let forwarded_coin = response.data.value_forwarded_coin;
        console.log("Forwardediting coin", forwarded_coin);
        let bb_amount = forwarded_coin * toCamPercentage;
        console.log("bb_amount", bb_amount);
        let bb_split_amount = bb_amount * 0.8;
        console.log("bb_split_amount", bb_split_amount);
        setbb_marketingAmount(bb_split_amount);
      }

      if (data.status == "confirmed") {
        setisLoadingTransaction(false);
        sendTopUpData(data.txid_in);
        handlecheckcardstatus();
        sendCreateVirtualCard();
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  const handlesetNetwork = async (target) => {
    console.log("Network:", target);
    console.log("loadingAmount:", loadingAmount);
    setNetwork(target);
    setisLoadingTransaction(true);
    if (loadingAmount !== 0) {
      //   if (localUser?.cards?.cardKitNumber) {
      console.log("card:", localUser?.cards?.cardKitNumber);
      setisLoadingFCF(true);
      let fcf_network;
      if (target === "bep20") {
        fcf_network = "BSC.USDT";
        //   setisLoadingTransactionBSC(true);
      } else if (target === "matic") {
        fcf_network = "MATIC.USDT";
        //   setisLoadingTransactionMATIC(true);
      }

      const apiResponse = await fetchDepositData(
        loadingAmount,
        fcf_network
      ).then((apiResponse) => {
        console.log("apiResponse", apiResponse);
        let modifiedData = { ...apiResponse.data.data };
        modifiedData.user_id = localUser.id;
        modifiedData.address = apiResponse.data.address;
        modifiedData.crypto_currency_amount =
          apiResponse.data.data.order_amount;
        modifiedData.crypto_currency_amount =
          apiResponse.data.crypto_currency_amount;
        modifiedData.uuid = apiResponse.data.uuid;
        modifiedData.order_id = apiResponse.data.order_id;
        modifiedData.total_fiat_amount = Number(loadingAmount).toFixed(2);
        let today = new Date();
        let date_today = today.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        modifiedData.created_at = date_today;
        setVirtualCardData(modifiedData);
        setfcftopayaddress(apiResponse.data.address);
        setcard_uuid(apiResponse.data.uuid);
        settofcfamount(apiResponse.data.data.order_amount);

        let fcf_amount = Number(apiResponse.data.data.order_amount);
        console.log("fcf_amount cryptapi", fcf_amount);
        setToFcf(fcf_amount);
        let tofcf_test = fcf_amount;

        let card_fee = loadingfees[loadingAmount][localUser.cards.card_type];
        console.log("card_fee", card_fee);
        let fcfbb =
          ((((fcf_amount - 2) * 100) / (100 + 2.5)) * (100 + card_fee * 100)) /
          100;
        console.log("+ Calculation", fcfbb);

        let cryptapifee = fcfbb * 0.01 + 0.5;
        console.log("cryptapifee", cryptapifee);

        let brut_amount = fcfbb + cryptapifee;
        console.log("brut_amount", brut_amount);
        brut_amount = (Math.ceil(brut_amount * 100) / 100).toFixed(2);

        console.log("brut_amount", brut_amount);
        setAmountToPay(brut_amount);
        approve_amount = Web3.utils.toWei(brut_amount, "ether");

        //TOPUP SPLITTING
        let bb_amount = brut_amount - fcf_amount;
        console.log("loadingAmount", apiResponse.data.data.order_amount);
        console.log("loadingAmount", Number(loadingAmount));
        console.log("bb_amount", bb_amount);
        let bb_marketing = (bb_amount * 0.8).toFixed(10);
        setbb_marketingAmount(bb_marketing);
        console.log("bb_marketing", bb_marketing);
        let bb_cr = (bb_amount * 0.1).toFixed(10);
        setbb_crAmount(bb_cr);
        console.log("bb_cr", bb_cr);
        let bb_team = (bb_amount * 0.1).toFixed(10);
        setbb_teamAmount(bb_team);
        console.log("bb_team", bb_team);

        var to_fcf_percentage = tofcf_test / (brut_amount * 0.99 - 0.5);
        console.log("to_fcf_percentage", to_fcf_percentage);

        let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
        let perc_to_fcf = temp.toFixed(4);
        console.log("test", perc_to_fcf);
        var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
        console.log("perc_to_cam", perc_to_cam);
        setToFcfPercentage(perc_to_fcf);
        setToCamPercentage(perc_to_cam);

        setissuecardenabled(true);
        setisLoadingFCF(false);
      });
      //   }
    }
    setisLoadingTransaction(false);
  };

  const handlecreateaddress = () => {
    console.log("handlecreateaddress network", network);
    setisLoadingTransaction(true);
    if (address) {
      if (approveamount > bscAllowance) {
        console.log("Need to approve usdt");
        setweb3statusmessage("Approve USDT...");

        async function approveUsdt() {
          try {
            await writeValue();
          } catch (err) {
            console.error(`Error while writing value: ${err}`);
          }
        }

        approveUsdt();
      } else {
        const args = [
          [fcftopayaddress, BB_80_WALLET, CR_10_WALLET, BB_10_WALLET],
          [
            getWei(tofcfamount, chainid),
            getWei(bb_marketingAmount, chainid),
            getWei(bb_crAmount, chainid),
            getWei(bb_teamAmount, chainid),
          ],
        ];
        console.log("TopUp Args", args);
        console.log("chainid", chainid);
        topupwrite?.();
      }
    } else {
      if (network !== "") {
        createapiaddress(network);
      }
    }
  };

  useEffect(() => {
    console.log("useEffect isapproved", isapproved);
    handlecreateaddress();
  }, [isapproved]);

  const handleshoeiframe = () => {
    setShowIframe(!showIframe);
  };

  const handlecurrentlocation = async (country) => {
    console.log("country", country);
  };

  const handleLoadingAmount = async (loadingAmount) => {
    if (loadingAmount != 0) {
      setisLoadingFCF(true);
      console.log("loadingAmount useEffect", loadingAmount);
      console.log("network", network);
      console.log("address", address);
      if (AmountToPay != 0 && !address) {
        handlesetNetwork(network);
        return;
      }
      setLoadingAmount(loadingAmount);
      if (network !== "" && !address) {
        setisLoadingFCF(true);
        if (localUser?.cards?.cardKitNumber) {
          let fcf_network;
          if (network === "bep20") {
            fcf_network = "BSC.USDT";
            //   setisLoadingTransactionBSC(true);
          } else if (network === "matic") {
            fcf_network = "MATIC.USDT";
            //   setisLoadingTransactionMATIC(true);
          }

          const apiResponse = await fetchDepositData(
            loadingAmount,
            fcf_network
          ).then((apiResponse) => {
            console.log("apiResponse", apiResponse);
            let modifiedData = { ...apiResponse.data.data };
            modifiedData.user_id = localUser.id;
            modifiedData.address = apiResponse.data.address;
            modifiedData.crypto_currency_amount =
              apiResponse.data.data.order_amount;
            modifiedData.crypto_currency_amount =
              apiResponse.data.crypto_currency_amount;
            modifiedData.uuid = apiResponse.data.uuid;
            modifiedData.order_id = apiResponse.data.order_id;
            modifiedData.total_fiat_amount = Number(loadingAmount).toFixed(2);
            let today = new Date();
            let date_today = today.toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            modifiedData.created_at = date_today;
            setVirtualCardData(modifiedData);
            setfcftopayaddress(apiResponse.data.address);
            setcard_uuid(apiResponse.data.uuid);
            settofcfamount(apiResponse.data.data.order_amount);

            let fcf_amount = Number(apiResponse.data.data.order_amount);
            console.log("fcf_amount", fcf_amount);
            setToFcf(fcf_amount);
            // let tofcf_test = 279.676585;
            let tofcf_test = fcf_amount;

            let card_fee =
              loadingfees[loadingAmount][localUser.cards.card_type];
            let z =
              ((((fcf_amount - 2) * 100) / (100 + 2.5)) *
                (100 + card_fee * 100)) /
              100;
            console.log("z", z);

            //TOPUP SPLITTING
            let bb_amount = brut_amount - fcf_amount;
            console.log("loadingAmount", apiResponse.data.data.order_amount);
            console.log("loadingAmount", Number(loadingAmount));
            console.log("bb_amount", bb_amount);
            let bb_marketing = (bb_amount * 0.8).toFixed(10);
            setbb_marketingAmount(bb_marketing);
            console.log("bb_marketing", bb_marketing);
            let bb_cr = (bb_amount * 0.1).toFixed(10);
            setbb_crAmount(bb_cr);
            console.log("bb_cr", bb_cr);
            let bb_team = (bb_amount * 0.1).toFixed(10);
            setbb_teamAmount(bb_team);
            console.log("bb_team", bb_team);

            let cryptapifee = z * 0.01 + 0.5;
            console.log("cryptapifee", cryptapifee);

            let brut_amount = tofcf_test + cryptapifee;
            console.log("brut_amount", brut_amount);
            brut_amount = (Math.ceil(brut_amount * 100) / 100).toFixed(2);

            console.log("brut_amount", brut_amount);
            setAmountToPay(brut_amount);
            approve_amount = Web3.utils.toWei(brut_amount, "ether");

            var to_fcf_percentage = tofcf_test / brut_amount;
            console.log("to_fcf_percentage", to_fcf_percentage);
            setToFcfPercentage(to_fcf_percentage);

            let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
            let perc_to_fcf = temp.toFixed(4);
            console.log("test", perc_to_fcf);
            var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
            console.log("perc_to_cam", perc_to_cam);
            setToCamPercentage(perc_to_cam);

            setissuecardenabled(true);
            setisLoadingFCF(false);
          });
        }
      } else {
        if (address) {
          console.log("address", address);
          console.log("chainid", chainid);
          if (localUser?.cards) {
            let fcf_network;
            if (chainid === 56) {
              fcf_network = "BSC.USDT";
            } else if (chainid === 137) {
              fcf_network = "MATIC.USDT";
            }

            console.log("loadingAmount", loadingAmount);
            console.log("allowance", bscAllowance);
            // if (Number(loadingAmount) > bscAllowance) {
            //   console.log("Need to approve usdt");
            //   setweb3statusmessage("Approve USDT...");

            //   try {
            //     await writeValue();
            //   } catch (err) {
            //     console.error(`Error while writing value: ${err}`);
            //   }
            // } else {
            const apiResponse = await fetchDepositData(
              loadingAmount,
              fcf_network
            ).then((apiResponse) => {
              console.log("apiResponse", apiResponse);
              let modifiedData = { ...apiResponse.data.data };
              modifiedData.user_id = localUser.id;
              modifiedData.address = apiResponse.data.address;
              modifiedData.crypto_currency_amount =
                apiResponse.data.data.order_amount;
              modifiedData.crypto_currency_amount =
                apiResponse.data.crypto_currency_amount;
              modifiedData.uuid = apiResponse.data.uuid;
              modifiedData.order_id = apiResponse.data.order_id;
              modifiedData.total_fiat_amount = Number(loadingAmount).toFixed(2);
              let today = new Date();
              let date_today = today.toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              modifiedData.created_at = date_today;
              setVirtualCardData(modifiedData);
              setfcftopayaddress(apiResponse.data.address);
              setcard_uuid(apiResponse.data.uuid);
              settofcfamount(apiResponse.data.data.order_amount);

              let fcf_amount = Number(apiResponse.data.data.order_amount);
              console.log("fcf_amount", fcf_amount);
              setToFcf(fcf_amount);
              let tofcf_test = fcf_amount;

              let card_fee =
                loadingfees[loadingAmount][localUser.cards.card_type];
              let z =
                ((((fcf_amount - 2) * 100) / (100 + 2.5)) *
                  (100 + card_fee * 100)) /
                100;
              console.log("z", z);

              let brut_amount = z;
              console.log("brut_amount", brut_amount);
              brut_amount = (Math.ceil(brut_amount * 100) / 100).toFixed(2);

              console.log("brut_amount", brut_amount);
              setAmountToPay(brut_amount);
              if (chainid === 137) {
                approve_amount = Web3.utils.toWei(brut_amount, "mwei");
                console.log("approve_amount wei", approve_amount);
              } else if (chainid === 56) {
                approve_amount = Web3.utils.toWei(brut_amount, "ether");
                console.log("approve_amount wei", approve_amount);
              }
              setapproveamount(approve_amount);

              //TOPUP SPLITTING
              let bb_amount = brut_amount - fcf_amount;
              console.log("loadingAmount", apiResponse.data.data.order_amount);
              console.log("loadingAmount", Number(loadingAmount));
              console.log("bb_amount", bb_amount);
              let bb_marketing = (bb_amount * 0.8).toFixed(10);
              setbb_marketingAmount(bb_marketing);
              console.log("bb_marketing", bb_marketing);
              let bb_cr = (bb_amount * 0.1).toFixed(10);
              setbb_crAmount(bb_cr);
              console.log("bb_cr", bb_cr);
              let bb_team = (bb_amount * 0.1).toFixed(10);
              setbb_teamAmount(bb_team);
              console.log("bb_team", bb_team);

              var to_fcf_percentage = tofcf_test / brut_amount;
              console.log("to_fcf_percentage", to_fcf_percentage);
              setToFcfPercentage(to_fcf_percentage);

              let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
              let perc_to_fcf = temp.toFixed(4);
              console.log("test", perc_to_fcf);
              var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
              console.log("perc_to_cam", perc_to_cam);
              setToCamPercentage(perc_to_cam);

              setissuecardenabled(true);
              setisLoadingFCF(false);
            });
            // }
          }
        }
      }
    }
  };

  const handleCardOrderSuccess = async () => {
    console.log("handleCardOrderSuccess");
    const interval = setInterval(() => {
      handlecheckcardstatus();
    }, 15000);

    return () => clearInterval(interval);
  };

  async function handlecheckcardstatus() {
    console.log("handlecheckcardstatus", card_uuid);
    try {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/cards/check-status",
        {
          uuid: card_uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      if (response.data.success === true) {
        // Success handling
        console.log("Update Virtual Card:", response.data);
        console.log("Received redeem link:", response.data.message.redeem_link);
        console.log("Received redeem code:", response.data.message.redeem_code);

        axios
          .post("https://api.bridge-bit.com/api/update_virtualcard", {
            // redeem_link: response.data.message.redeem_link,
            // redeem_code: response.data.message.redeem_code,
            redeem_link: response.data.message.redeem_link,
            redeem_code: response.data.message.redeem_code,
            pin: response.data.message.pin,
            expirationDate: response.data.message.expiration_date,
            uuid: card_uuid,
          })
          .then(async (response) => {
            console.log("Response received:", response);
            if (response.data.success === true) {
              console.log("Successfull:", response.data.message);
              const updatedCard = response.data.data;

              setvirtualCards((prevCards) => {
                // Find index of the updated card in the existing array
                const index = prevCards.findIndex(
                  (card) => card.uuid === updatedCard.uuid
                );

                if (index !== -1) {
                  // Replace the old card data with the updated one
                  const updatedCards = [...prevCards];
                  updatedCards[index] = updatedCard;
                  return updatedCards;
                } else {
                  // If for some reason the card wasn't found, just append the updated card to the list
                  return [...prevCards, updatedCard];
                }
              });

              isLoadingTransaction(false);

              setweb3statusmessage("Card successfull created...");
            } else {
              console.error(
                "Request was not successful. Please handle it accordingly."
              );
            }
          })
          .catch((error) => {
            console.error("Error encountered while sending topup data:", error);
          });
      } else {
        // Error handling
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("No response was received", error.request);
      } else {
        console.error("Error setting up the request", error.message);
      }
    }
  }

  async function fetchDepositData(amount, tokenSymbol) {
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://api.bridge-bit.com/api/create-card-order-depositUSD",
      {
        amount: amount.toString(),
        user_id: localUser?.id,
        currency: "EUR",
        product_id: 18597,
        token_symbol: tokenSymbol,
        email: "info@bridge-bit.com",
        liquidity: 0,
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  //   async function fetchDepositData(amount, tokenSymbol) {
  //     console.log("amount", amount.toString());
  //     console.log("tokenSymbol", tokenSymbol);
  //     const response = await axios.post(
  //       "https://dashboard.croesus-asset.management/api/cards/create-card-order-deposit",
  //       {
  //         iframe_id: "5933",
  //         amount: amount.toString(),
  //         currency: "EUR",
  //         product_id: 18587,
  //         token_symbol: tokenSymbol,
  //         email: "info@bridge-bit.com",
  // liquidity: 0,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization:
  //             "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
  //         },
  //       }
  //     );

  //     if (!response.status === 200) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.data;
  //   }

  //HANDLE API CRYPAPI
  const createapiaddress = async (network) => {
    var blockchain = network;
    setIsLoadingAddress(true);
    try {
      var ticker;
      let address;

      if (blockchain === "matic") {
        ticker = "polygon/usdt";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
      } else if (blockchain === "btc") {
        ticker = "btc";
      }
      console.log("Address BB_CRYPTAPI_80:", BB_CRYPTAPI_80);
      console.log("% BB_CRYPTAPI_80:", toCamPercentage);
      console.log("Address FCF:", fcftopayaddress);
      console.log("% FCF:", ToFcfPercentage);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1&virtual=1&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          toCamPercentage +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          ToFcfPercentage +
          "@" +
          fcftopayaddress,
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      console.log("Status:", data.status);

      if (data.status === "error") {
        setalert(data.error);
      } else if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(blockchain, data.address_in);
        setisLoadingTransaction(true);
      }

      setIsLoadingAddress(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoadingAddress(false);
    }
  };

  const fetchQrCode = async (blockchain, address) => {
    try {
      const query = new URLSearchParams({
        address: address,
        size: "512",
      }).toString();

      var ticker;
      if (blockchain === "matic") {
        ticker = "polygon/usdt";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
      } else if (blockchain === "btc") {
        ticker = "btc";
      }

      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      if (data.status === "error") {
        alert(data.error);
      } else if (data.qr_code) {
        setQrCode(data.qr_code);
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  async function sendCreateVirtualCard() {
    console.log("sendCreateVirtualCard");
    console.log("virtualCardData:", virtualCardData);
    axios
      .post(
        "https://api.bridge-bit.com/api/create_virtualcard",
        virtualCardData
      )
      .then(async (response) => {
        console.log("Response received:", response);
        if (response.data.success === true) {
          const updatedCard = virtualCardData;

          setvirtualCards((prevCards) => {
            // Find index of the updated card in the existing array
            const index = prevCards.findIndex(
              (card) => card.uuid === updatedCard.uuid
            );

            if (index !== -1) {
              // Replace the old card data with the updated one
              const updatedCards = [...prevCards];
              updatedCards[index] = updatedCard;
              return updatedCards;
            } else {
              // If for some reason the card wasn't found, just append the updated card to the list
              return [...prevCards, updatedCard];
            }
          });

          handleCardOrderSuccess();
          //   sendCreateVirtualCard();
          isLoading(false);
          fetchUserData(localUser?.firebaseUid);
        } else {
          console.error(
            "Request was not successful. Please handle it accordingly."
          );
        }
      })
      .catch((error) => {
        console.error("Error encountered while sending topup data:", error);
      });
  }

  async function sendTopUpData(txid_in) {
    console.log("sendTopUpData");
    if (!topupsended) {
      let currency = network !== "" ? network : chain.id;

      const payload = {
        user: localUser.id,
        currency: currency,
        amount: bb_marketingAmount,
        brut_amount: loadingAmount,
        net_amount: net_topup,
        fcf_amount: toFcf,
        bb_amount: bb_marketingAmount,
        marketing_amount: bb_marketingAmount,
        txn_hash: txid_in,
      };
      console.log("Payload:", payload);

      axios
        .post("https://api.bridge-bit.com/api/split_virtual_card", payload)
        .then(async (response) => {
          console.log("Response received:", response);
          settopupsended(true);
        })
        .catch((error) => {
          console.error("Error encountered while sending topup data:", error);
        });
    }
  }

  //WEB3 USEEFFECTS

  // READ ALLOWANCE
  const { data: readDataAllowance, isLoading: readLoadingAllowance } =
    useContractRead({
      address: usdt_contract,
      abi: usdt_abi,
      functionName: "allowance",
      args: [address, split_contract],
    });
  console.log("allowance", readDataAllowance);

  useEffect(() => {
    if (readDataAllowance) {
      let bscAllowanceETH = Web3.utils.fromWei(
        String(readDataAllowance),
        "ether"
      );
      console.log("bscAllowanceETH", bscAllowanceETH);
      setbscAllowance(readDataAllowance);
    }
  }, [readDataAllowance]);

  //   useEffect(() => {
  //     let amount = AmountToPay;
  //     console.log("useEffect cardLoadAmount", amount);

  //     if (amount != 0) {
  //       if (approveamount > bscAllowance) {
  //         console.log("Need to approve usdt");
  //         setweb3statusmessage("Approve USDT...");

  //         async function approveUsdt() {
  //           try {
  //             await writeValue();
  //           } catch (err) {
  //             console.error(`Error while writing value: ${err}`);
  //           }
  //         }

  //         approveUsdt();
  //       } else {
  //         console.log("No need to approve usdt");
  //         // topupwrite?.();
  //       }
  //     }
  //   }, [AmountToPay]);

  const { data: readData, isLoading: readLoading } = useContractRead({
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "balanceOf",
    args: [address],
  });
  //   console.log("balanceOf", readData);

  const {
    data: txhashapprove,
    write: writeValue,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "approve",
    args: [split_contract, approveamount],
    onSuccess(data) {
      console.log("Success approve");
      setweb3statusmessage("USDT approved...");
      // window.scrollTo(0, document.body.scrollHeight);
      // setbscAllowance(approve_amount);
      // handleSetValue();
    },
    onError(error) {
      console.log("Error", error);
      setisLoadingTransaction(false);
      setisLoading(false);
    },
  });

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: txhashapprove?.hash,
    onSuccess(data) {
      console.log("onSuccess approve", data);
      setweb3statusmessage("Creating Transaction...");

      setbscAllowance(approve_amount);
      setisapproved(true);
      topupwrite?.();
    },
    onerror(error) {
      console.log("onError", error);
      setisLoadingTransaction(false);
      isLoading(false);
    },
  });

  const getWei = (amount, chainid) => {
    // console.log("getWei", amount, chainid);
    switch (chainid) {
      case 56:
        return Web3.utils.toWei(amount, "ether");
      case 137:
        console.log("tofixed", Number(amount).toFixed(6));
        return Web3.utils.toWei(Number(amount).toFixed(6), "mwei");
      // return convertAmount(amount);
      default:
        // console.log("Unsupported chain", chain);
        return null;
    }
  };

  //SEND TO CONTRACT
  const {
    data: topupdata,
    write: topupwrite,
    isLoading: topupisPending,
  } = useContractWrite({
    address: split_contract,
    abi: BSC_SPLIT_TOPUP_ABI,
    functionName: "topUp",
    args: [
      [fcftopayaddress, BB_80_WALLET, CR_10_WALLET, BB_10_WALLET],
      [
        getWei(tofcfamount, chainid),
        getWei(bb_marketingAmount, chainid),
        getWei(bb_crAmount, chainid),
        getWei(bb_teamAmount, chainid),
      ],
    ],
    onError(error) {
      console.log("Error", error);
      setisLoadingTransaction(false);
      setisLoading(false);
    },
  });
  const {
    data: topupdataceipt,
    isLoading: topupdataPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: topupdata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      setweb3statusmessage("Transaction confirmed...");
      sendTopUpData(topupdata?.hash);
      handlecheckcardstatus();
      sendCreateVirtualCard();
    },
    onerror(error) {
      console.log("onError", error);
      setisLoadingTransaction(false);
      isLoading(false);
    },
  });

  useEffect(() => {
    // setweb3statusmessage("Waiting for transaction confirmation...");
  }, [topupdataPending]);

  const BNBBalances = () => {
    const { data, isError, isLoading, refetch } = useBalance({
      address,
      watch: true,
    });

    // console.log("data", data);

    if (isLoading) return <div>Fetching balance...</div>;
    if (isError) return <div>Error fetching balance</div>;

    return (
      <div>
        {data?.formatted}{" "}
        <span className="text-large " style={{ fontSize: "1rem" }}>
          {" "}
          {data?.symbol}
        </span>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Virtual Card Management</h2>
      </div>
      {address && (
        <div className="row">
          <div className="col-xl-8 col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                <div
                  className="card card-bx card-body overflow-hidden p-0 mb-2 d-flex rounded"
                  style={{
                    height: "100px",
                    background: "black",
                    marginLeft: "5px",
                    marginRight: "5px",
                    width: "auto",
                  }}
                >
                  <div
                    className="card-info d-flex"
                    style={{
                      height: "100%",
                      alignItems: "start",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundImage:
                        chainid == 56 ? `url(${bnblogo})` : `url(${maticlogo})`,
                      backgroundSize: chainid == 56 ? "90px" : "100px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "calc(100% - 10px) center", // Adjust the pixel value to move the image away from the right edge
                      boxSizing: "border-box",
                      padding: "10px",
                    }}
                  >
                    <h4 className="text-white">Balances</h4>
                    <h4 className="text-white">{bscBalance} USDT</h4>
                    <h5 className="text-white">
                      <BNBBalances></BNBBalances>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {localUser?.settings?.virtual_maintenance === 1 && (
        <div className="row">
          <div className="col-xl-8 col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                <>
                  {localUser?.settings?.virtual_web3 === 1 && address && (
                    <>
                      <Alert variant="danger" className="text-center">
                        WEB3 Virtual Card currently unavailable due to bank
                        maintenance. Will resume shortly. Please disconnect
                        wallet and generate address manually.
                      </Alert>
                    </>
                  )}

                  {localUser?.settings?.api_maintenance === 1 && (
                    <>
                      {localUser?.settings?.api_maintenance === 1 && (
                        <Alert variant="danger" className="text-center">
                          Virtual Card currently unavailable due to bank
                          maintenance. Will resume shortly.
                        </Alert>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentLocation === "Rest of World" && (
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-7 col-sm-8">
            <Alert variant="danger" className="text-center">
              Virtual Card currently not available in your location.
            </Alert>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
              <div className="card-bx " style={{ minWidth: "270px" }}>
                <img
                  // src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/a9354c5a-e6d4-47e1-ea4b-dc3505089800/public"
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/709d28fc-e6bb-4f14-7eee-1a940175d900/public"
                  alt=""
                  className="mw-100 "
                  style={{
                    height: "auto",
                    minHeight: "200px",
                  }}
                />
                <div
                  className="card-info text-white d-flex flex-direction-column"
                  style={{
                    minHeight: "230px",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                  }}
                >
                  <div
                    className="d-flex align-items-center mb-sm-1 mb-1"
                    // style={{ justifyContent: "space-between" }}
                  >
                    {/* <p className="mb-1">Card Balance</p> */}
                  </div>
                  {/* <div
                    className="d-flex align-items-center mb-sm-1 mb-1"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h2 className="fs-36 text-white mb-sm-2 mb-3"></h2>
                  </div> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="input-group mb-1">
                      <select
                        className="form-control h-auto text-black"
                        value={currentLocation}
                        onChange={(e) => setCurrentLocation(e.target.value)}
                        disabled={maintenanceMode}
                        // disabled={
                        //   localUser?.settings?.virtual_maintenance === 1
                        // }
                      >
                        {" "}
                        <option value="">Select your current location</option>
                        {virtual_countries.map((country, index) => (
                          <option key={index} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <p style={{ marginBottom: "0px", color: "black" }}>USD</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="input-group mb-1">
                      <select
                        className="form-control h-auto text-black"
                        value={loadingAmount}
                        onChange={(e) => handleLoadingAmount(e.target.value)}
                        // disabled={
                        //   localUser?.settings?.virtual_maintenance === 1
                        // }
                        disabled={
                          currentLocation === null ||
                          currentLocation === "Rest of World" ||
                          maintenanceMode
                        }
                      >
                        {" "}
                        <option value="">Select Amount</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        {/* <option value="1500">1500</option> */}
                      </select>
                    </div>
                  </div>{" "}
                  <div className="img_container"></div>
                  <>
                    {/* {topuperror !== "" && (
                      <h4 className="fs-14 text-danger mb-0">
                        You don´t have enough USDT on your wallet.
                      </h4>
                    )} */}
                    {/* {maintenance !== "" && (
                      <>
                        {localUser?.settings?.BSC === 0 &&
                        localUser?.settings?.MATIC === 0 ? (
                          <>
                            <h4 className="fs-14 text-danger mb-0">
                              {maintenance}
                            </h4>
                          </>
                        ) : (
                          <>
                            {localUser?.settings?.BSC === 0 && (
                              <>
                                <h4 className="fs-14 text-danger mb-0">
                                  USDT BEP20 Card loads currently unavailable
                                  due to bank maintenance. Will resume shortly.
                                </h4>
                              </>
                            )}
                            {localUser?.settings?.MATIC === 0 && (
                              <>
                                <h4 className="fs-14 text-danger mb-0">
                                  USDT POLYGON Card loads currently unavailable
                                  due to bank maintenance. Will resume shortly.
                                </h4>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )} */}
                  </>
                </div>
              </div>
            </div>
          </div>

          {loadingAmount !== 0 && (
            <div className="row">
              <Col xl="8" sm="8" lg="6">
                <Card>
                  <Card.Body
                    className="d-flex text-center"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {AmountToPay !== 0 && (
                        <>
                          <p className="fs-18 mb-0 text-black">
                            Amount to pay :
                          </p>
                          <p className="fs-18 mb-2 text-black">
                            {AmountToPay} USDT
                          </p>
                        </>
                      )}

                      {isLoadingFCF && network !== "" && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}

                      {!address && (
                        <>
                          <div className="row justify-content-md-center p-3">
                            <div
                              className="col-lg-6 d-flex"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {AmountToPay === 0 && (
                                <>
                                  <label className="text-black font-w600 ">
                                    Select Blockchain
                                  </label>
                                </>
                              )}

                              <div
                                className="row"
                                style={{
                                  display: "flex",
                                  flexDirection: "row", // Change from 'column' to 'row'
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  value="bep20"
                                  onClick={(e) => handlesetNetwork("bep20")}
                                  className="btn btn-primary mt-3 m-1"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  disabled={network && network === "bep20"}
                                >
                                  <img
                                    src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                                    alt="network"
                                    style={{
                                      marginRight: "10px",
                                      width: "25px",
                                    }}
                                  />
                                  USDT BEP20
                                </button>

                                <button
                                  value="matic"
                                  onClick={(e) => handlesetNetwork("matic")}
                                  className="btn btn-primary mt-3 m-1"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  disabled={network && network === "matic"}
                                >
                                  <img
                                    src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                                    alt="network"
                                    style={{
                                      marginRight: "10px",
                                      width: "25px",
                                    }}
                                  />
                                  USDT POLYGON
                                </button>

                                <button
                                  className="btn btn-primary mt-3 m-1"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={handleshoeiframe}
                                >
                                  <i className="fa-solid fa-rotate m-1"></i>
                                  {showIframe ? "Close" : "Bridge"}
                                </button>
                              </div>
                            </div>{" "}
                          </div>
                          <div className="col-12 text-center">
                            {showIframe ? (
                              <iframe
                                className="mt-3"
                                id="iframe-widget"
                                src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=0.1&amountFiat=1500&backgroundColor=2B2B35&darkMode=true&from=btc&fromFiat=eur&horizontal=false&isFiat&lang=en-US&link_id=26d728182950fd&locales=true&logo=false&primaryColor=EC6631&to=usdtbsc&toFiat=usdtbsc&toTheMoon=true"
                                style={{
                                  height: "356px",
                                  width: "-webkit-fill-available",
                                  border: "none",
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}

                      {createdAddress && (
                        <div
                          className="row d-flex mt-3"
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="col-lg-12 d-flex"
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <label className="text-black font-w600 ">
                              Address
                            </label>
                            <div className="input-group mb-3 mt-3">
                              <input
                                type="text"
                                className="form-control text-black"
                                placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                                value={createdAddress}
                                readOnly
                              />

                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(createdAddress)
                                    .then(() =>
                                      alert("Address copied to clipboard!")
                                    )
                                    .catch((error) =>
                                      console.error(
                                        "Could not copy text: ",
                                        error
                                      )
                                    );
                                }}
                              >
                                COPY
                              </button>
                            </div>
                            <label className="text-black font-w600 ">
                              Amount
                            </label>
                            <div
                              className="input-group mb-3 mt-3"
                              style={{ maxWidth: "200px" }}
                            >
                              <input
                                type="number"
                                className="form-control text-black"
                                placeholder="0"
                                value={AmountToPay}
                                readOnly
                              />

                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(AmountToPay)
                                    .then(() =>
                                      alert("Amount copied to clipboard!")
                                    )
                                    .catch((error) =>
                                      console.error(
                                        "Could not copy text: ",
                                        error
                                      )
                                    );
                                }}
                              >
                                COPY
                              </button>
                            </div>
                            {qrCode && (
                              <div
                                className="d-flex mb-3"
                                style={{ justifyContent: "center" }}
                              >
                                <img
                                  src={`data:image/png;base64,${qrCode}`}
                                  alt="QR Code"
                                  style={{ maxWidth: "220px" }}
                                />
                              </div>
                            )}

                            {isLoadingTransaction ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                />
                                {txn_hash ? (
                                  <>
                                    <label className="text-black font-w600 mt-3">
                                      Transaction Hash
                                    </label>
                                    <label className="text-black font-w600 mt-3">
                                      {txn_hash.substring(0, 15)}...
                                    </label>
                                    <label className="text-black font-w600 mt-3">
                                      Confirmations : {confirmations}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <label className="text-black font-w600 mt-3">
                                      Waiting for Payment
                                    </label>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <label className="text-black font-w600 mt-3">
                                  Transaction Hash
                                </label>
                                <label className="text-black font-w600">
                                  {txn_hash.substring(0, 25)}...
                                </label>
                                <label className="text-black font-w600 mt-3">
                                  Confirmations : {confirmations}
                                </label>
                                {transactionsconfirmed && (
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    Transaction Confirmed. You card details ..
                                  </div>
                                )}
                                {errormessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {errormessage}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {web3statusmessage !== "" && (
                        <label className="text-black font-w600 ">
                          {web3statusmessage}
                        </label>
                      )}

                      {isLoadingFCF && address && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}

                      {!qrCode && (
                        <button
                          type="submit"
                          className="btn btn-primary mt-3"
                          //   disabled={!issuecardenabled || isLoadingTransaction}
                          disabled={isLoadingTransaction}
                          onClick={handlecreateaddress}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "100px",
                            margin: "0px auto",
                          }}
                        >
                          {/* {isLoadingTransaction ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                            </>
                          ) : (
                            <>Issue Card</>
                          )} */}
                          Issue Card
                        </button>
                      )}

                      {/* <button
                        className="btn btn-primary mt-3"
                        onClick={sendCreateVirtualCard}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "100px",
                          margin: "0px auto",
                        }}
                      >
                        Create Card

                      </button> */}
                    </>
                  </Card.Body>
                </Card>
              </Col>
            </div>
          )}

          <div className="row">
            <Col xl="8" sm="8" lg="6">
              <Card>
                <Card.Body>
                  <>
                    <h4>Your Cards</h4>
                    <h6 className="text-warning">
                      The issueing process can take up to 5 minutes.{" "}
                    </h6>
                    <Table className="m-0" responsive>
                      <tbody>
                        {virtualCards
                          .slice()
                          .reverse()
                          .map((card) => (
                            <tr className="text-black" key={card.id}>
                              {card.redeem_link ? (
                                <td
                                  className="text-black"
                                  style={{ width: "130px" }}
                                >
                                  {card.total_fiat_amount}
                                  {card.redeem_code ? " USD" : " EUR"}
                                </td>
                              ) : (
                                <td
                                  className="text-black"
                                  style={{ width: "130px" }}
                                >
                                  {card.total_fiat_amount}
                                </td>
                              )}

                              <td className="text-black">
                                {(() => {
                                  let date = new Date(card.created_at);
                                  return `${("0" + date.getDate()).slice(
                                    -2
                                  )}/${("0" + (date.getMonth() + 1)).slice(
                                    -2
                                  )}/${date.getFullYear()}`;
                                })()}
                              </td>
                              {card.redeem_link ? (
                                <>
                                  <td className="text-black">
                                    <a
                                      className="text-blue"
                                      href={card.redeem_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Activate
                                    </a>
                                  </td>
                                  {card.redeem_code && (
                                    <td>Security Code : {card.redeem_code}</td>
                                  )}
                                </>
                              ) : (
                                <>
                                  <td
                                    className="text-black"
                                    // style={{ width: "130px" }}
                                  >
                                    Creating
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-light ml-2 p-0"
                                      onClick={() => handlegetstatus(card.uuid)}
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                    >
                                      <i
                                        className={`fas fa-sync-alt ${
                                          refreshLoading[card.uuid]
                                            ? "fa-spin"
                                            : ""
                                        }`}
                                        style={{ fontSize: "22px" }}
                                      ></i>
                                    </button>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </Table>{" "}
                    {/* <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn btn-primary m-2"
                        onClick={prevPageDeposit}
                        disabled={currentPageDeposit === 1}
                      >
                        <i className="fa-solid fa-chevron-left"></i>
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        onClick={nextPageDeposit}
                        disabled={
                          currentPageDeposit ===
                          Math.ceil(
                            localUser?.transactionstopup.length / itemsPerPage
                          )
                        }
                      >
                        <i className="fa-solid fa-chevron-right"></i>
                      </button>
                    </div> */}
                  </>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default VirtualCards;
