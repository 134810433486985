import { Fragment, useState, useContext, useEffect } from "react";
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
  useConnect,
} from "wagmi";
import { usdt_abi } from "../../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_SPLIT_TOPUP_ABI } from "../../../../src/contracts/BSC_SPLIT_TOPUP_ABI.js";
import Web3 from "web3";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";
// const BSC_TOPUP_CONTRACT = "0x64b663AA3Edf764eE68f0ECF4cCe994527c2814e";

const POLYGON_TOPUP_CONTRACT = "0xE1D32047823a16c0482c526e19741b49381a4d83";
const POLYGON_USDT_CONTRACT = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";

const ETH_TOPUP_CONTRACT = "0x19f3FEbd63C61D7f9073Da6Cd58C75cd31aAe8B3";
const ETH_USDT_CONTRACT = "0xdac17f958d2ee523a2206206994597c13d831ec7";

const TEAM_DIRECT = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";

const Topupbutton = ({
  LoadAmount,
  LoadAmountNet,
  LoadAmountFCF,
  onTopUpSuccess,
  onStatusChange,
  settopuperror,
}) => {
  // console.log("LoadAmount Brut", LoadAmount);
  // console.log("LoadAmount Net", LoadAmountNet);
  // console.log("LoadAmountFCF ", LoadAmountFCF);
  const [fcftopayaddress, setfcftopayaddress] = useState("");
  const [tofcfamount, settofcfamount] = useState(0);
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    connect,
    connectors,
    error,
    pendingConnector,
    isLoading: connectLoading,
  } = useConnect();
  const { chain } = useNetwork();
  const [bscBalance, setbscBalance] = useState(0);
  const [bscBalanceWei, setbscBalanceWei] = useState(0);
  const [bscAllowance, setbscAllowance] = useState(0);
  const [isapproved, setisapproved] = useState(false);
  const [cardLoadAmountWEI, setcardLoadAmountWEI] = useState(0);
  const [cardLoadAmountBrutWEI, setcardLoadAmountBrutWEI] = useState(0);
  const [cardLoadAmount, setcardLoadAmount] = useState(LoadAmount);
  const [cardLoadAmountNet, setcardLoadAmountNet] = useState(LoadAmountNet);
  const [cardLoadAmountNetWei, setcardLoadAmountNetWei] = useState(
    Web3.utils.toWei(LoadAmountNet, "ether")
  );
  const bbfeeAmount = LoadAmount - LoadAmountNet;

  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isLoading, setisLoading] = useState(false);
  const [message, setMessage] = useState("");

  //WEB3 Contracts
  let chainid = chain.id;
  let currency;
  let usdt_contract;
  let split_contract;
  let approve_amount;
  let approve_amount_matic;
  let buy_amount;
  let cur_fcf;
  if (chainid === 137) {
    usdt_contract = POLYGON_USDT_CONTRACT;
    split_contract = POLYGON_TOPUP_CONTRACT;

    // approve_amount = convertAmount(LoadAmount);
    if (localUser?.cards?.bb_active == "0") {
      approve_amount = Web3.utils.toWei(LoadAmount, "mwei");
      approve_amount_matic = Web3.utils.toWei(LoadAmount, "ether");
    } else {
      approve_amount = Web3.utils.toWei(LoadAmount, "mwei");
      approve_amount_matic = Web3.utils.toWei(LoadAmount, "ether");
    }
    console.log("approve_amount wei matic", approve_amount);
    buy_amount = approve_amount;
    currency = "matic_usdt";
    cur_fcf = "MATIC.USDT";

    // console.log("buy_amount", buy_amount);
    console.log("approve_amount", approve_amount);
  } else if (chainid === 56) {
    usdt_contract = BSC_USDT_CONTRACT;
    split_contract = BSC_TOPUP_CONTRACT;
    if (localUser?.cards?.bb_active == "0") {
      approve_amount = Web3.utils.toWei(LoadAmount + 20, "ether");
    } else {
      approve_amount = Web3.utils.toWei(LoadAmount, "ether");
    }
    buy_amount = Web3.utils.toWei(LoadAmount, "ether");
    currency = "bsc_usdt";
    cur_fcf = "BSC.USDT";
    console.log("approve_amount bsc", approve_amount);
  } else if (chainid === 1) {
    usdt_contract = ETH_USDT_CONTRACT;
    split_contract = ETH_TOPUP_CONTRACT;
    approve_amount = Web3.utils.toWei(LoadAmount, "ether");
    buy_amount = Web3.utils.toWei(LoadAmount, "ether");
    currency = "eth_usdt";
    cur_fcf = "ETH.USDT";
    console.log("approve_amount", approve_amount);
  }

  const { data: readData, isLoading: readLoading } = useContractRead({
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "balanceOf",
    args: [address],
  });
  console.log("balanceOf", readData);

  useEffect(() => {
    console.log("useEffect isConnecting", isConnecting);
  }, [isConnecting]);

  useEffect(() => {
    let amount = LoadAmount;
    console.log("useEffect cardLoadAmount", amount);
    if (amount != 0) {
      let min_amount;
      if (localUser?.cards?.bb_active == "0") {
        min_amount = 80;
      } else {
        min_amount = 50;
      }

      if (amount >= min_amount) {
        let LoadAmountWei = Web3.utils.toWei(amount, "ether");
        console.log("LoadAmountWei", LoadAmountWei);
        setcardLoadAmountWEI(LoadAmountWei);
        setcardLoadAmount(amount);
        let LoadAmountNetWei = Web3.utils.toWei(LoadAmountNet, "ether");
        setcardLoadAmountNetWei(LoadAmountNetWei);
        let LoadAmountBrut;
        if (localUser?.cards?.bb_active == "0") {
          LoadAmountBrut = Web3.utils.toWei(LoadAmount + 20, "ether");
        } else {
          LoadAmountBrut = Web3.utils.toWei(LoadAmount, "ether");
        }
        setcardLoadAmountBrutWEI(LoadAmountBrut);
        console.log("LoadAmount Brut calc", LoadAmount);
        console.log("LoadAmount Brut WEI", LoadAmountBrut);
        console.log("bscAllowance", bscAllowance);
        console.log("LoadAmount Net calc", LoadAmountNet);
        if (LoadAmountBrut >= bscAllowance) {
          console.log("Not Enough Allowance 1");
          setisapproved(false);
        } else {
          setisapproved(true);
        }

        if (localUser) {
          // console.log("localUser", localUser);
        }
      } else {
        setcardLoadAmount(0);
      }
    }
  }, [LoadAmount]);

  useEffect(() => {
    if (readData) {
      console.log("Balance Wei Amount", readData);
      if (chainid === 137) {
        let tokenBalance = Number(readData) / Math.pow(10, 6);
        console.log("bscBalanceETH", tokenBalance);
        setbscBalance(tokenBalance);
        setbscBalanceWei(readData);
      } else if (chainid === 56) {
        let bscBalanceETH = Web3.utils.fromWei(String(readData), "ether");
        console.log("bscBalanceETH", bscBalanceETH);
        setbscBalance(bscBalanceETH);
      }
    }
  }, [readData]);

  // READ ALLOWANCE
  const { data: readDataAllowance, isLoading: readLoadingAllowance } =
    useContractRead({
      address: usdt_contract,
      abi: usdt_abi,
      functionName: "allowance",
      args: [address, split_contract],
    });
  console.log("allowance", readDataAllowance);

  useEffect(() => {
    console.log("useEffect readDataAllowance", readDataAllowance);
    if (readDataAllowance != null) {
      let bscAllowanceETH = 0;
      if (chainid === 137) {
        bscAllowanceETH = Web3.utils.fromWei(String(readDataAllowance), "mwei");
      } else {
        bscAllowanceETH = Web3.utils.fromWei(
          String(readDataAllowance),
          "ether"
        );
      }
      console.log("bscAllowanceETH", bscAllowanceETH);
      setbscAllowance(bscAllowanceETH);
    }
  }, [readDataAllowance]);

  const {
    data: txhashapprove,
    write: writeValue,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "approve",
    args: [split_contract, approve_amount],
    onSuccess(data) {
      console.log("Success approve");
      // window.scrollTo(0, document.body.scrollHeight);
      // setbscAllowance(approve_amount);
      // handleSetValue();
    },
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: txhashapprove?.hash,
    onSuccess(data) {
      console.log("onSuccess approve", data);
      setbscAllowance(approve_amount);
      onStatusChange("Approved");
      setisapproved(true);
      // handleSetValue();
    },
    onerror(error) {
      console.log("onError", error);
      isLoading(false);
    },
  });

  useEffect(() => {
    setisLoading(true);
  }, [approveisPending]);

  const getWei = (amount, chainid) => {
    // console.log("getWei", amount, chainid);
    switch (chainid) {
      case 56:
        return Web3.utils.toWei(amount, "ether");
      case 137:
        console.log("tofixed", Number(amount).toFixed(6));
        return Web3.utils.toWei(Number(amount).toFixed(6), "mwei");
      // return convertAmount(amount);
      default:
        console.log("Unsupported chain", chain);
        return null;
    }
  };

  //SEND TO CONTRACT
  const {
    data: topupdata,
    write: topupwrite,
    isLoading: topupisPending,
  } = useContractWrite({
    address: split_contract,
    abi: BSC_SPLIT_TOPUP_ABI,
    functionName: "topUp",
    args: [
      [fcftopayaddress, TEAM_DIRECT, BB_80_WALLET, CR_10_WALLET, BB_10_WALLET],
      [
        getWei(tofcfamount, chainid),
        getWei(bb_teamAmount, chainid),
        getWei(bb_marketingAmount, chainid),
        getWei(bb_crAmount, chainid),
        getWei(bb_crAmount, chainid),
      ],
    ],
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });

  const {
    data: topupdataceipt,
    isLoading: topupdataPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: topupdata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      onTopUpSuccess(topupdata?.hash);
      sendTopUpData();
    },
    onerror(error) {
      console.log("onError", error);
      isLoading(false);
    },
  });

  useEffect(() => {
    console.log("useEffect");
    // console.log("fcftopayaddress", fcftopayaddress);
    // console.log("topupisPending", topupisPending);
    // console.log("isapproved", isapproved);
    if (
      fcftopayaddress !== "" &&
      topupisPending === false &&
      isapproved === true
    ) {
      // console.log("useEffect cardLoadAmountNet", LoadAmountNet);
      // console.log("useEffect bbfeeAmount", bbfeeAmount);
      // console.log(
      //   "useEffect cardLoadAmountNet",
      //   Web3.utils.toWei(LoadAmountNet, "ether")
      // );
      // console.log(
      //   "useEffect bbfeeAmount",
      //   Web3.utils.toWei(bbfeeAmount, "ether")
      // );

      const args = [
        [
          fcftopayaddress,
          TEAM_DIRECT,
          BB_80_WALLET,
          CR_10_WALLET,
          BB_10_WALLET,
        ],
        [
          getWei(tofcfamount, chainid),
          getWei(bb_teamAmount, chainid),
          getWei(bb_marketingAmount, chainid),
          getWei(bb_crAmount, chainid),
          getWei(bb_crAmount, chainid),
        ],
      ];
      console.log("TopUp Args", args);
      // console.log("chainid", chainid);

      // console.log(
      //   "approve_amount",
      //   Web3.utils.fromWei(approve_amount, "ether")
      // );
      // console.log("bscBalance", bscBalance);
      if (chainid === 56) {
        if (
          Number(approve_amount) > Number(Web3.utils.toWei(bscBalance, "ether"))
        ) {
          settopuperror("Not enough balance 1");
          setisLoading(false);
        } else {
          topupwrite?.();
        }
      } else {
        if (approve_amount > bscBalanceWei) {
          settopuperror("Not enough balance 1");
          setisLoading(false);
        } else {
          topupwrite?.();
        }
      }
    } else {
      console.log("chainid", chainid);
      if (chainid === 56) {
        if (
          Number(approve_amount) > Number(Web3.utils.toWei(bscBalance, "ether"))
        ) {
          console.log("chainid", chainid);
          settopuperror("Not enough balance 2");
          setisLoading(false);
        } else {
          topupwrite?.();
        }
      } else {
        if (approve_amount > bscBalanceWei) {
          console.log("chainid", chainid);
          settopuperror("Not enough balance 2");
          setisLoading(false);
        } else {
          topupwrite?.();
        }
      }
    }
  }, [fcftopayaddress, isapproved]);

  useEffect(() => {
    if (writeLoading) {
      onStatusChange("Confirm approve in your wallet");
    } else if (topupisPending) {
      onStatusChange("Confirm TopUp in wallet");
    }
  }, [writeLoading, topupisPending]);

  useEffect(() => {
    if (bscAllowance) {
      setbscAllowance(bscAllowance);
    }
  }, [bscAllowance]);

  const handleSetValue = async () => {
    setisLoading(true);
    // console.log("handleSetValue");
    // console.log(bscAllowance);
    // console.log("cardLoadAmountNet", LoadAmountFCF);
    // console.log("bbfeeAmount", bbfeeAmount);
    let numbercardloadamount = Number(LoadAmountFCF);

    let min_amount;
    if (localUser?.cards?.bb_active == "0") {
      min_amount = 80;
    } else {
      min_amount = 50;
    }

    console.log("LoadAmount 3", Number(LoadAmount));
    console.log("bscBalance 3", Number(bscBalance));
    if (Number(LoadAmount) > Number(bscBalance)) {
      settopuperror("Not enough balance 3");
      setisLoading(false);
      return;
    }

    if (cardLoadAmount >= min_amount) {
      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        numbercardloadamount.toFixed(2),
        cur_fcf
      );
      console.log("apiResponse", apiResponse);
      // settofcfamount(apiResponse.data.crypto_currency_amount);
      settofcfamount(apiResponse.data.crypto_currency_amount);

      let order_amount = apiResponse.data.crypto_currency_amount;

      //TOPUP SPLITTING
      let bb_amount = LoadAmount - order_amount;
      console.log("bb_amount", bb_amount);

      let bb_team;
      let to_allow;
      bb_team = (bb_amount * 0.0025 + 3).toFixed(10);
      console.log("bb_team", bb_team);
      to_allow = cardLoadAmount;

      // let bb_team = (bb_amount * 0.1).toFixed(10);
      setbb_teamAmount(bb_team);

      let difference = bb_amount - bb_team;
      console.log("difference", difference);

      // let bb_amount = LoadAmount - apiResponse.data.crypto_currency_amount;
      // console.log("LoadAmount", apiResponse.data.crypto_currency_amount);
      // console.log("LoadAmount", apiResponse.data.order_amount);
      // console.log("LoadAmount", Number(LoadAmount));
      // console.log("bb_amount", bb_amount);
      let bb_marketing = (difference * 0.8).toFixed(10);
      setbb_marketingAmount(bb_marketing);
      console.log("bb_marketing", bb_marketing);
      let bb_cr = (difference * 0.1).toFixed(10);
      setbb_crAmount(bb_cr);
      console.log("bb_cr", bb_cr);
      console.log("bb_team", bb_cr);
      let control = Number(bb_marketing) + Number(bb_cr) + Number(bb_team); // now this will result in 60
      console.log("control", control);

      console.log("Check Allowance");
      console.log("Number(bscAllowance)", Number(bscAllowance));
      console.log("Number(cardLoadAmount)", Number(to_allow));

      // window.scrollTo(0, document.body.scrollHeight);
      console.log("bscAllowance", bscAllowance);
      console.log("buy_amount", buy_amount);
      if (Number(bscAllowance) < Number(to_allow)) {
        console.log("Not enough Allowance 2");
        setfcftopayaddress(apiResponse.data.address);
        onStatusChange("Need to approve USDT Amount");
        try {
          await writeValue();
          setfcftopayaddress(apiResponse.data.address);
        } catch (err) {
          console.error(`Error while writing value: ${err}`);
        }
      } else {
        setisapproved(true);
        setfcftopayaddress(apiResponse.data.address);
      }
    }
  };

  async function sendTopUpData() {
    axios
      .post("https://api.bridge-bit.com/api/split_topup", {
        user: localUser.id,
        currency: currency,
        amount: bb_marketingAmount,
        brut_amount: LoadAmount,
        net_amount: LoadAmountNet,
        fcf_amount: LoadAmountFCF,
        bb_amount: bb_marketingAmount,
        marketing_amount: bb_marketingAmount,
        txn_hash: topupdata?.hash,
      })
      .then(async (response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    console.log("cardNumber", cardNumber);
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://api.bridge-bit.com/api/loadDepositVirtual",
      {
        user: localUser.id,
        amount: LoadAmountNet,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  return (
    <button
      className="btn btn-light"
      onClick={handleSetValue}
      disabled={isLoading || !cardLoadAmount || localUser?.settings?.BSC === 0}
      // style={{ height: "40px" }}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        ></Spinner>
      ) : (
        "TopUp"
      )}
    </button>
  );
};

export default Topupbutton;

function convertAmount(amount) {
  // Convert the number to a string and remove the dot.
  let numString = amount.toString().replace(".", "");

  // Extract only first 6 digits (3 before decimal and 3 after).
  numString = numString.substring(0, 8);

  // Convert back to an integer.
  while (numString.length < 8) {
    // If so, add a trailing zero.
    numString += "0";
  }

  return numString;
}
