import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Button,
  Form,
  Alert,
  Col,
  Row,
  Spinner,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import CountdownTimer from "../components/CountdownTimer";
import { virtual_countries } from "../components/virtual_countries";
import { virtual_countries_prohibited } from "../components/virtual_countries";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import { loadableReady } from "@loadable/component";
import Topupbutton from "../../../src/jsx/components/web3/TopupbuttonVirtual.js";
import Issuebutton from "../../../src/jsx/components/web3/IssuebuttonVirtual.js";
import VirtualCardWidget from "../../../src/jsx/components/VirtualCardWidget.js";
import VirtualCardWidgetBlank from "../../../src/jsx/components/VirtualCardWidgetBlank.js";
import BuyButton from "../../../src/jsx/components/web3/BuyButtonReloadable.js"; // Import BuyButton
import CardTransactions from "../../../src/jsx/components/CardTransactions.js";

const VirtualCardsReloadable = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [buyError, setbuyError] = useState("");
  const [buySuccess, setBuySuccess] = useState(null);

  const handlebuyerror = (error) => {
    setbuyError(error);
  };

  const handlebuysuccess = (cardID) => {
    setStep(2);
    setBuySuccess(`Card with ID ${cardID} purchased successfully!`);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middlename: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    email: "",
    birth_date: "",
    genderId: "",
    pod: "",
    occupation: "",
    nationality: "",
  });

  const germanStates = [
    { name: "Choose State", shortCode: "CHOOSE" },
    { name: "Baden-Württemberg", shortCode: "BW" },
    { name: "Bayern", shortCode: "BY" },
    { name: "Berlin", shortCode: "BE" },
    { name: "Brandenburg", shortCode: "BB" },
    { name: "Bremen", shortCode: "HB" },
    { name: "Hamburg", shortCode: "HH" },
    { name: "Hessen", shortCode: "HE" },
    { name: "Niedersachsen", shortCode: "NI" },
    { name: "Mecklenburg-Vorpommern", shortCode: "MV" },
    { name: "Nordrhein-Westfalen", shortCode: "NW" },
    { name: "Rheinland-Pfalz", shortCode: "RP" },
    { name: "Saarland", shortCode: "SL" },
    { name: "Sachsen", shortCode: "SN" },
    { name: "Sachsen-Anhalt", shortCode: "ST" },
    { name: "Schleswig-Holstein", shortCode: "SH" },
    { name: "Thüringen", shortCode: "TH" },
  ];

  const [alert, setAlert] = useState(null);
  const [validated, setValidated] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [initialLoadSubmitted, setInitialLoadSubmitted] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [depositDetails, setDepositDetails] = useState(null);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [firstload, setfirstload] = useState(0);
  const [step, setStep] = useState(1); // Step 1: Choose plan, Step 2: Submit KYC, Step 3: Top-up
  const [network, setNetwork] = useState(null);
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(false);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [isLoadingTransactionIssue, setIsLoadingTransactionIssue] =
    useState(false);
  const [isLoadingKYC, setisLoadingKYC] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [createdKYCLink, setCreatedKYCLink] = useState(""); // Store the generated KYC link

  const [addressIn, setAddressIn] = useState("");
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [confirmations, setConfirmations] = useState(0);
  const [transactionsConfirmed, setTransactionsConfirmed] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [kycstatus, setkycstatus] = useState("");
  const [loadAmount, setloadAmount] = useState(0);
  const [net_topup, setnet_topup] = useState(0);
  const [ToFcf, setToFcf] = useState(0);
  const [fcftopayaddress, setfcftopayaddress] = useState(0);

  const [toFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);

  const [allow_load, setallow_load] = useState(false);

  const [pageLoading, setpageLoading] = useState(true);
  const [loadingIssue, setloadingIssue] = useState(false);
  const [loadingCardIssue, setloadingCardIssue] = useState(false);
  const [status, setStatus] = useState("");
  const [success_message, setsuccess_message] = useState(false);
  const [old_balance, set_old_balance] = useState(false);
  const [topuperror, settopuperror] = useState("");
  const [cardBalance, setcardBalance] = useState(0);
  const [kyccountdown, setkyccountdown] = useState(0);
  const [linkloading, setlinkloading] = useState(false);

  const [virtualCard, setVirtualCard] = useState(null);

  const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

  const loadingfees = {
    1: [0.0499, 5],
    2: [0.0399, 4],
    3: [0.0349, 3],
    10: [0, 0],
  };

  useEffect(() => {
    // settopuperror("");
    if (loadAmount > 0) {
      console.log("useEffect Loadmount");
      if (localUser?.virtualcards_reloadable) {
        console.log("topup");
        let min_amount = 40;
        let fix_fee = 0;
        if (loadAmount >= min_amount) {
          // if (Number(loadAmount) > Number(limitleft)) {
          //   console.log("Limit exceeded");
          //   setdailylimit(false);
          //   return;
          // }

          let InputAmount = loadAmount - fix_fee;
          // console.log("InputAmount", InputAmount);
          let cryptapifee = loadAmount * 0.01;
          // console.log("cryptapifee", cryptapifee);

          let bridgetloadingfee =
            InputAmount * loadingfees[localUser?.virtual_type][0] +
            loadingfees[localUser?.virtual_type][1];
          console.log("lfee", bridgetloadingfee);

          let net_card_amount;
          if (address !== undefined) {
            net_card_amount = InputAmount - bridgetloadingfee;
          } else {
            net_card_amount =
              InputAmount - bridgetloadingfee - cryptapifee - 0.5;
          }
          setnet_topup(net_card_amount.toFixed(0));
          setToFcf(net_card_amount.toFixed(0));
          setallow_load(true);
        } else {
          setallow_load(false);
        }
      } else {
        console.log("issuing");
        let fix_amount = 50;
        // if (Number(loadAmount) > Number(limitleft)) {
        //   console.log("Limit exceeded");
        //   setdailylimit(false);
        //   return;
        // }

        let InputAmount = loadAmount;
        // console.log("InputAmount", InputAmount);
        let cryptapifee = loadAmount * 0.01;
        // console.log("cryptapifee", cryptapifee);
        console.log("%", loadingfees[localUser?.virtual_type][0]);
        console.log("$", loadingfees[localUser?.virtual_type][1]);

        let bridgetloadingfee =
          InputAmount * loadingfees[localUser?.virtual_type][0] +
          loadingfees[localUser?.virtual_type][1];
        console.log("bridgetloadingfee", bridgetloadingfee);

        let net_card_amount;
        if (address !== undefined) {
          net_card_amount = InputAmount - bridgetloadingfee;
        } else {
          net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
        }
        setnet_topup(net_card_amount.toFixed(0));
        setToFcf(net_card_amount.toFixed(0));
        setallow_load(true);
      }
    }
  }, [loadAmount]);

  useEffect(() => {
    if (firstload !== 0) {
      if (localUser?.virtual_date) {
        // const countdownTime =
        // new Date(localUser.virtual_date).getTime() + 24 * 60 * 60 * 1000;
        // const now = new Date().getTime();

        let virtual_date = new Date(localUser.virtual_date);
        virtual_date.setUTCHours(virtual_date.getHours() + 24);
        let nowUTC = new Date();
        let delta = virtual_date.getTime() - nowUTC.getTime();
        if (delta <= 0) {
          console.log("24h gone");
          console.log("Countdown null");
          setCountdown(1);
        } else {
          const countdownTime =
            new Date(virtual_date).getTime() + 24 * 60 * 60 * 1000;
          console.log("Set Countdown", countdownTime);
          setCountdown(countdownTime);
        }
      }

      if (localUser?.virtual_type && !localUser.virtual_chid) {
        console.log("Set step2");
        setStep(2);
        setpageLoading(false);
      }

      if (localUser?.virtual_chid) {
        console.log("Set step2");

        let apiUrl =
          "https://dashboard.croesus-asset.management/api/virtual-cards/check-card-holder-status";
        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
          body: JSON.stringify({
            cardHolderId: localUser?.virtual_chid,
          }),
        };

        fetch(apiUrl, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log("handle result", result);
            if (result.success) {
              console.log("handle success", result.message);
              if (result.message == 0 || result.message == "init") {
                setkycstatus("KYC Not Verified");
                setpageLoading(false);
              } else {
                setkycstatus("KYC Verified");
              }
            } else {
              console.log("handle error", result.message);
              // handle error
            }
          })
          .catch((error) => console.log("Error:", error));

        setStep(3);
      }

      if (localUser?.virtual_chid) {
        setCreatedKYCLink(localUser?.kyc_url);
      }

      if (localUser?.virtual_uuid) {
        if (!localUser?.virtualcards_reloadable) {
          console.log("Check Card Status");
          if (localUser?.virtual_date) {
            let virtual_date = new Date(localUser.virtual_date);
            virtual_date.setUTCHours(virtual_date.getHours() + 24);
            let nowUTC = new Date();
            let delta = (virtual_date.getTime() - nowUTC.getTime()) / 1000;
            if (delta <= 0) {
              console.log("24h gone");
              const asyncFunction = async () => {
                await checkCard();
                setpageLoading(false);
              };
              asyncFunction();
            } else {
              let hours = Math.floor(delta / 3600);
              delta -= hours * 3600;
              let minutes = Math.floor(delta / 60);
              console.log(hours + " hours " + minutes + " minutes ");
              setpageLoading(false);
            }
          } else {
            setpageLoading(false);
          }
        } else {
          setStep(4);
          setpageLoading(false);
        }
      }

      if (localUser?.kyc_at) {
        console.log("kyc_at (UTC)", localUser?.kyc_at);

        // Convert kyc_at to a Date object in UTC (this is already correct)
        let kycDateTime = new Date(localUser.kyc_at); // This is already in UTC

        // Add 2 hours to kyc_at in UTC
        kycDateTime.setUTCHours(kycDateTime.getHours() + 2);
        console.log(
          "kycDateTime + 2 hours (in UTC)",
          kycDateTime.toISOString()
        );

        const updateCountdown = () => {
          // Get the current time in UTC
          let nowUTC = new Date(); // Still works in local timezone but handles UTC internally

          // Calculate the difference in seconds between kycDateTime and now
          let delta = (kycDateTime.getTime() - nowUTC.getTime()) / 1000; // Using getTime() ensures we use UTC timestamps in milliseconds

          // If the countdown is already over, set it to 0 and stop the interval
          if (delta <= 0) {
            setkyccountdown(0);
            return;
          }

          // Calculate hours and minutes remaining
          let hours = Math.floor(delta / 3600);
          delta -= hours * 3600;

          let minutes = Math.floor(delta / 60);

          // Update the countdown display
          setkyccountdown(hours + " hours " + minutes + " minutes ");
        };

        // Initial call to set the countdown right away
        updateCountdown();

        // Start the interval to update the countdown every second
        const intervalId = setInterval(() => {
          updateCountdown();
          let nowUTC = new Date();
          console.log("now (UTC): ", nowUTC.toISOString()); // This will print the current UTC time in ISO format

          if (kycDateTime <= nowUTC) {
            clearInterval(intervalId);
          }
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }
    } else {
      fetch(`https://api.bridge-bit.com/api/user/${localUser.firebaseUid}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("User not found.");
          }
          return response.json();
        })
        .then((userData) => {
          localStorage.setItem("user", JSON.stringify(userData));
          setfirstload(1);
          setLocalUser(userData);
          console.log("User data updated:", userData);
          setpageLoading(false);
        });
    }
    // if (localUser?.virtualcards_reloadable) {
    //   setpageLoading(false);
    // }
    setpageLoading(false);
  }, [localUser, firstload]);

  const vipModel = {
    name: "VIP",
    cardid: 3,
    amount: 50,
    normal_price: 799,
    fee: "3.49% + 3 USD",
    text: [
      "- Instant Load",
      "- Unlock Potential of Rank Gold Director to maximize passive income",
      "- UNLIMITED USD per Transaction",
      "- UNLIMITED USD Limit per Month",
    ],
  };
  const premiumModel = {
    name: "Premium",
    cardid: 2,
    amount: 142,
    normal_price: 355,
    fee: "3.99% + 4 USD",
    text: [
      "- Unlock the Potential of Passive Income",
      "- Instant Loads",
      "- 3.000 USDT Daily Load Limit",
    ],
  };
  const standardModel = {
    name: "Standard",
    cardid: 1,
    amount: 88,
    normal_price: 88,
    fee: "4.99% + 5 USD",
    text: [
      "- Join the World of Bridge Bit",
      "- Instant Loads",
      "- 1.500 USDT Daily Top Up Limit",
      "- 2.000 USD Daily ATM",
    ],
  };

  let pricingModels = [vipModel, premiumModel, standardModel];

  if (!localUser?.cards?.card_type) {
    for (let model of pricingModels) {
      model.amount = model.normal_price;
    }
  } else if (localUser.cards.card_type == 1) {
    pricingModels = [standardModel];
  } else if (localUser.cards.card_type == 2) {
    pricingModels = [premiumModel];
  } else if (localUser.cards.card_type == 3) {
    pricingModels = [vipModel];
  }

  const handlePlanSelection = (plan) => {
    console.log("selected Plan:", plan);
    setSelectedPlan(plan);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({
        ...formData,
        kycImage: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleStatusChange = (status) => {
    // console.log("handleStatusChange", status);
    setStatus(status);
  };

  const handlesettopuperror = (message) => {
    // console.log("handleettopuperror", message);
    settopuperror(message);
  };

  const handleIssueSuccess = async () => {
    console.log("handleIssueSuccess");
    let virtualdate = new Date().toISOString();
    const response = await axios.post(
      "https://api.bridge-bit.com/api/set_virtual_date",
      {
        user: localUser.id,
        virtual_date: virtualdate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    } else {
      setnet_topup(0);
      let virtual_date = new Date(localUser.virtual_date);
      virtual_date.setUTCHours(virtual_date.getHours() + 24);
      let nowUTC = new Date();
      const countdownTime = new Date(nowUTC).getTime() + 24 * 60 * 60 * 1000;
      console.log("Set Countdown", countdownTime);
      setCountdown(countdownTime);
    }
  };

  const handleTopUpSuccess = async () => {
    // console.log("handleTopUpSuccess");
    setsuccess_message(true);
    set_old_balance(cardBalance);

    // const interval = setInterval(() => {
    //   handlerefreshbalance();
    // }, 15000);

    // return () => clearInterval(interval);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoadingKYC(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      setisLoadingKYC(false);
      return;
    }

    setValidated(true);

    try {
      // Step 1: Submit form data to the external API
      const externalApiResponse = await axios.post(
        "https://dashboard.croesus-asset.management/api/virtual-cards/create-card-holder-virtual-bot",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg`,
          },
        }
      );

      if (externalApiResponse.data.success) {
        console.log("KYC submitted successfully", externalApiResponse.data);
        const cardholderID = externalApiResponse.data.message;
        const virtual_date = new Date().toISOString();
        const kycLink = externalApiResponse.data.sumsub_link;

        // Update local user data
        const updatedUser = {
          ...localUser,
          virtual_chid: cardholderID,
          // virtual_date: virtual_date,
          kyc_url: kycLink,
        };
        setLocalUser(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));

        setCreatedKYCLink(kycLink); // Set the KYC link to show to the user

        // Step 2: Send cardholderID and virtual_date to your Laravel API
        const laravelApiResponse = await axios.post(
          "https://api.bridge-bit.com/api/virtualKYC", // replace with your actual Laravel API endpoint
          {
            user: localUser.id,
            cardHolderId: cardholderID,
            virtual_date: virtual_date,
            kycLink: kycLink,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (laravelApiResponse.data.success) {
          // setAlert({
          //   type: "success",
          //   message: "Generated KYC Link!",
          // });
          setisLoadingKYC(false);
          setStep(3);
          // Set countdown timer
          // const countdownTime =
          //   new Date(virtual_date).getTime() + 24 * 60 * 60 * 1000;
          // setCountdown(countdownTime);
        } else {
          setisLoadingKYC(false);
          setAlert({
            type: "danger",
            message: "Error!",
          });
        }
      } else {
        setisLoadingKYC(false);
        setAlert({
          type: "danger",
          message: "Something went wrong with the external API!",
        });
      }
    } catch (error) {
      setisLoadingKYC(false);
      setAlert({
        type: "danger",
        message: error.response?.data?.message || "An error occurred!",
      });
    }
  };

  const createapiaddress = async (network) => {
    setIsLoadingNetwork(true); // Show loading spinner
    setNetwork(network); // Store the selected network
    setIsLoadingTransaction(true);
    try {
      let ticker;
      let address;
      const blockchain = network;

      if (blockchain === "matic") {
        ticker = "polygon/usdt";
        address =
          "0.25" +
          "@0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803|" +
          "0.1" +
          "@0x593f27504F2bE394C648F2216319Cb7fC9cf37bE| " +
          "0.65" +
          "@0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
        address =
          "0.25" +
          "@0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803|" +
          "0.1" +
          "@0x593f27504F2bE394C648F2216319Cb7fC9cf37bE| " +
          "0.65" +
          "@0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
        address =
          "0.25" +
          "@TBp9LQvsEgy7H5cFQN3f1zPtMRk7ZPmENC|" +
          "0.1" +
          "@TXzaQQXPK7CuyA9s1kLbxW8WJNPVw5dNWG| " +
          "0.65" +
          "@TA69wZdotYePP96R9CTbb8RHSZjbgrSaBH";
      } else if (blockchain === "btc") {
        ticker = "btc";
        address = "1Gm7aeCHGfCapRbxfPuK7RQiCXNNkyFEwo";
      }

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/handleVirtualReloadableCallback?cardID=${
          selectedPlan.name
        }&user_id=${localUser.id}&time=${Math.floor(Date.now() / 1000)}`,
        multi_token: 1,
        address: address, // Replace with actual addresses and percentages
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      console.log("Address created:", response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "error") {
        setAlert({ type: "danger", message: data.error });
      } else if (data.status === "success") {
        setCreatedAddress(data.address_in);
        setAddressIn(data.address_in);
        fetchQrCode(network, data.address_in);
        // setIsLoadingTransaction(false);
        setAlert({
          type: "success",
          message: "Deposit address created! Please proceed with the payment.",
        });
      }
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoadingTransaction(false);
      setAlert({
        type: "danger",
        message: "An error occurred while creating the deposit address.",
      });
    } finally {
      setIsLoadingNetwork(false); // Hide loading spinner
    }
  };

  const createapiaddressTopup = async (network) => {
    setIsLoadingNetwork(true); // Show loading spinner
    setNetwork(network); // Store the selected network
    setIsLoadingTransactionIssue(true);

    let fcf_network;
    let crypt_network;
    if (network === "bep20") {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      // setIsLoadingTransactionIssue(true);
    } else if (network === "matic") {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      // setIsLoadingTransactionIssue(true);
    }

    let min_amount = 80;
    let fix_fee = 0;
    try {
      let amount = loadAmount;
      // console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading
      let InputAmount = loadAmount - fix_fee;

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee createapiaddress", cryptapifee);
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.virtual_type][0] +
        loadingfees[localUser?.virtual_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(0));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData();

      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.crypto_currency_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      // let topupsplit = bridgebit_amount * 0.8;
      // settopupref(topupsplit);
      // console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      // console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      setbb_marketingAmount(bridgebit_amount * 0.8);
      setbb_crAmount(bridgebit_amount * 0.1);
      setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setAddressIn(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(crypt_network, data.address_in);
        setIsLoadingTransactionIssue(true);
      } else {
        // Handle API response error
      }
      setIsLoadingNetwork(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoadingNetwork(false);
    }
  };

  async function fetchDepositData() {
    // console.log("cardNumber", cardNumber);
    // console.log("amount", amount.toString());
    // console.log("tokenSymbol", tokenSymbol);
    const virtual_date = new Date().toISOString();

    const laravelApiResponse = await axios.post(
      "https://api.bridge-bit.com/api/issueVirtualCard",
      {
        user: localUser.id,
        amount: ToFcf,
        virtual_date: virtual_date,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    console.log("Issue card API response:", laravelApiResponse.data);

    if (laravelApiResponse.data.success === true) {
      return laravelApiResponse.data.data;
    }
  }

  const fetchQrCode = async (blockchain, address) => {
    console.log("Fetching QR code for address:", address);
    console.log("Blockchain:", blockchain);
    try {
      const query = new URLSearchParams({
        address: address,
        size: "512",
      }).toString();

      let ticker;
      if (blockchain === "matic") {
        ticker = "polygon/usdt";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
      } else if (blockchain === "btc") {
        ticker = "btc";
      }

      const response = await fetch(
        `https://api.cryptapi.io/${blockchain}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      if (data.status === "error") {
        alert(data.error);
      } else if (data.qr_code) {
        setQrCode(data.qr_code);
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const checkCard = async () => {
    setloadingCardIssue(true);
    setIsLoadingTransaction(true);
    try {
      console.error("fetching checkCardResponse:");
      const checkCardResponse = await axios.post(
        "https://api.bridge-bit.com/api/check_card",
        { user: localUser?.id },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );
      console.log("Check card API response 1:", checkCardResponse.data);
      if (checkCardResponse.data.success) {
        setVirtualCard(checkCardResponse.data.virtualCard); // Set virtual card data to state
        // setError(""); // Clear any previous error
        setloadingCardIssue(false);
        setIsLoadingTransaction(false);
        window.location.reload();
      } else {
        setVirtualCard(null);
        // setError(checkCardResponse.data.message || "An error occurred.");
        setloadingCardIssue(false);
        setIsLoadingTransaction(false);
      }
      // if (checkCardResponse.data.success === true) {
      //   setloadingCardIssue(false);
      //   // Card has been loaded, stop checking
      //   // clearInterval(checkCardStatusInterval);
      //   setIsLoadingTransaction(false);
      // }
    } catch (error) {
      console.error("Error while checking the card status:", error);
    }
  };

  const fetchUserTransactionIssue = async () => {
    try {
      console.log("address in", addressIn);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${addressIn}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      setTxnHash(data.txid_in);
      setConfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("User transactions fetched successfully", data);

      if (data.status === "confirmed") {
        setloadingCardIssue(true);
        // Start a timer when the card is successfully issued
        let checkCardStatusInterval = setInterval(async () => {
          try {
            const checkCardResponse = await axios.post(
              "https://dashboard.croesus-asset.management/api/virtual-cards/check-card",
              { uuid: localUser?.virtual_uuid },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization:
                    "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
                },
              }
            );
            console.log("Check card API response 2:", checkCardResponse.data);
            if (checkCardResponse.data.success === true) {
              setVirtualCard(checkCardResponse.data.virtualCard); // Set virtual card data to state
              setloadingCardIssue(false);
              // Card has been loaded, stop checking
              clearInterval(checkCardStatusInterval);
              setIsLoadingTransaction(false);
              window.location.reload();
            }
          } catch (error) {
            console.error("Error while checking the card status:", error);
          }
          window.location.reload();
        }, 60000); // Check every 1 minute
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  const fetchUserTransactions = async () => {
    try {
      console.log("address in", addressIn);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${addressIn}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      setTxnHash(data.txid_in);
      setConfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("User transactions fetched successfully", data);
      if (data.status === "confirmed" && data.amount >= selectedPlan.amount) {
        setIsLoadingTransaction(false);
        setTransactionsConfirmed(true);

        axios
          .post("https://api.bridge-bit.com/api/successbuy_virtual", {
            user: localUser.id,
            cardID: selectedPlan.name,
            coin: network,
            txn: data.txid_in,
            amount: selectedPlan.amount,
          })
          .then(async (response) => {
            console.log(response);
            setAlert({ type: "success", message: "Card Buy successful" });
            // await fetchUserData(localUser.firebaseUid);
            setStep(2); // Move to the next step after payment is confirmed

            // setTimeout(() => {
            //   window.location.reload();
            // }, 5000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        data.status === "confirmed" &&
        data.amount < selectedPlan.amount
      ) {
        setIsLoadingTransaction(false);
        setTransactionsConfirmed(false);
        setErrorMessage(
          "Amount not enough " + data.amount + " / " + selectedPlan.amount
        );
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  useEffect(() => {
    console.log("useEffect addressIn called");
    console.log("addressIn", addressIn);
    console.log("isLoadingTransaction", isLoadingTransaction);
    console.log("isLoadingTransactionIssue", isLoadingTransactionIssue);
    if (addressIn !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        console.log("interval isLoadingTransaction");
        fetchUserTransactions();
      }, 10000);

      return () => clearInterval(interval);
    }
    if (addressIn !== "" && isLoadingTransactionIssue) {
      const interval = setInterval(() => {
        console.log("interval isLoadingTransactionIssue");
        fetchUserTransactionIssue();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [addressIn]);

  const handleAmountChange = (event) => {
    console.log("handleAmountChange setloadAmount(50)");
    setloadAmount(50);
  };

  const handleIssueCard = async (e) => {
    setloadingIssue(true);
    setIsLoadingTransaction(true);
    //create card order
    try {
      const laravelApiResponse = await axios.post(
        "https://api.bridge-bit.com/api/issueVirtualCard",
        {
          user: localUser.id,
          amount: ToFcf,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Issue card API response:", laravelApiResponse.data);

      if (laravelApiResponse.data.success === true) {
        setDepositDetails(laravelApiResponse.data.data);
        // setInitialLoadSubmitted(true);
        setloadingIssue(false);
        setAlert({
          type: "success",
          message:
            "Initial load created successfully! Please proceed with the payment.",
        });

        // Start a timer when the card is successfully issued
        let checkCardStatusInterval = setInterval(async () => {
          try {
            const checkCardResponse = await axios.post(
              "https://dashboard.croesus-asset.management/api/virtual-cards/check-card",
              { uuid: laravelApiResponse.data.data.uuid },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization:
                    "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
                },
              }
            );
            console.log("Check card API response 3:", checkCardResponse.data);
            if (checkCardResponse.data.success === true) {
              setVirtualCard(checkCardResponse.data.virtualCard); // Set virtual card data to state
              // Card has been loaded, stop checking
              clearInterval(checkCardStatusInterval);
              window.location.reload();
              setIsLoadingTransaction(false);
            }
          } catch (error) {
            console.error("Error while checking the card status:", error);
          }
        }, 60000); // Check every 1 minute
      } else {
        setloadingIssue(false);
        setIsLoadingTransaction(false);
        setAlert({
          type: "danger",
          message: "Something went wrong with the deposit API!",
        });
      }
    } catch (error) {
      setloadingIssue(false);
      setIsLoadingTransaction(false);
      setAlert({
        type: "danger",
        message: error.response?.data?.message || "An error occurred!",
      });
    }
  };

  const handleInitialLoadSubmit = async (e) => {
    e.preventDefault();
    setloadingIssue(true);

    try {
      // Step 3: Create card order deposit
      const depositApiResponse = await axios.post(
        "https://dashboard.croesus-asset.management/api/virtual-cards/create-card-order-deposit",
        {
          cardHolderId: localUser.virtual_chid,
          amount: ToFcf,
          token_symbol: "BSC.USDT", // Assuming USDT as an example
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg`,
          },
        }
      );

      console.log("Deposit API response:", depositApiResponse.data);

      if (depositApiResponse.data.success) {
        setDepositDetails(depositApiResponse.data.data);
        // setInitialLoadSubmitted(true);
        setAlert({
          type: "success",
          message:
            "Initial load created successfully! Please proceed with the payment.",
        });
      } else {
        setAlert({
          type: "danger",
          message: "Something went wrong with the deposit API!",
        });
      }
      setloadingIssue(false);
    } catch (error) {
      setAlert({
        type: "danger",
        message: error.response?.data?.message || "An error occurred!",
      });
      setloadingIssue(false);
    }
  };

  const handlesetNetworktopup = (selectedNetwork) => {
    createapiaddressTopup(selectedNetwork);
  };

  const handlesetNetwork = (selectedNetwork) => {
    createapiaddress(selectedNetwork);
  };

  const handlegenerateKYCLink = async () => {
    setlinkloading(true);
    try {
      const laravelApiResponse = await axios.post(
        "https://api.bridge-bit.com/api/getsumsublink",
        {
          user: localUser.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (laravelApiResponse.data.success) {
        console.log("getsumsublink API response:", laravelApiResponse.data);
        setCreatedKYCLink(laravelApiResponse.data.kyc_url);
        let countdown = 7200000; // 2 hours in milliseconds

        const intervalId = setInterval(() => {
          countdown -= 1000; // decrementing by one second

          if (countdown <= 0) {
            // End the countdown when it reaches 0
            clearInterval(intervalId); // Clear the interval
          }

          // For illustration, converting to hours and minutes
          const hours = Math.floor(countdown / (1000 * 60 * 60));
          const minutes = Math.floor(
            (countdown % (1000 * 60 * 60)) / (1000 * 60)
          );

          setkyccountdown(hours + " hours " + minutes + " minutes "); // Update the countdown time
        }, 1000);

        window.open(laravelApiResponse.data.kyc_url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching getsumsublink API response:", error);
    } finally {
      setlinkloading(false);
    }
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">inbanx Virtual Card</h2>
      </div>

      <div className="row">
        <div className="col-xl-8 col-lg-8 col-sm-12">
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-sm-12"
              style={{ minHeight: "270px" }}
            >
              {pageLoading ? (
                <div className="text-center">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                  <br></br>
                  <span className="text-black">Loading Card Dashboard</span>
                </div>
              ) : (
                <>
                  {step === 2 && (
                    <Card className="overflow-hidden">
                      <Card.Body>
                        <Fragment>
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <Form.Group controlId="firstName" className="mb-3">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                pattern="^[a-zA-Z\s-]+$"
                                maxLength="22"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid first name (max 22
                                characters).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="middlename" className="mb-3">
                              <Form.Label>Middle Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="middlename"
                                value={formData.middlename}
                                onChange={handleChange}
                                pattern="^[a-zA-Z\s-]+$"
                                maxLength="22"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid middle name (max 22
                                characters).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="lastName" className="mb-3">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                pattern="^[a-zA-Z\s-]+$"
                                maxLength="22"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid last name (max 22
                                characters).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              controlId="address_line1"
                              className="mb-3"
                            >
                              <Form.Label>Address Line 1</Form.Label>
                              <Form.Control
                                type="text"
                                name="address_line1"
                                value={formData.address_line1}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid address.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              controlId="address_line2"
                              className="mb-3"
                            >
                              <Form.Label>Address Line 2</Form.Label>
                              <Form.Control
                                type="text"
                                name="address_line2"
                                value={formData.address_line2}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid address.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="zip" className="mb-3">
                              <Form.Label>Zip</Form.Label>
                              <Form.Control
                                type="text"
                                name="zip"
                                value={formData.zip}
                                onChange={handleChange}
                                required
                                minLength="3"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid zip code (min 3
                                characters).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="city" className="mb-3">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid city.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="country" className="mb-3">
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                as="select"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Country</option>
                                {virtual_countries.map((country) => (
                                  <option
                                    key={country.country}
                                    value={country.alpha3}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select a country.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="state" className="mb-3">
                              <Form.Label>State</Form.Label>
                              {formData.country === "DEU" ? (
                                <select
                                  name="state"
                                  value={formData.state}
                                  onChange={handleChange}
                                  className="form-control text-black"
                                >
                                  {germanStates.map((state) => (
                                    <option
                                      key={state.shortCode}
                                      value={state.shortCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  name="state"
                                  className="form-control text-black"
                                  type="text"
                                  value={formData.state}
                                  onChange={handleChange}
                                  maxLength="2"
                                  pattern="[A-Za-z]{2}"
                                  required
                                />
                              )}
                              {/* <Form.Control
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                required
                                maxLength="2"
                                pattern="[A-Za-z]{2}"
                              /> */}
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid state (2 characters).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="phone" className="mb-3">
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                pattern="^[0-9]{6,15}$"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid phone number (6-15 digits).
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="email" className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="birth_date" className="mb-3">
                              <Form.Label>Birth Date</Form.Label>
                              <input
                                type="date"
                                name="birth_date"
                                className="form-control"
                                onChange={handleChange}
                                value={formData.birth_date}
                                required
                              />
                              {/* <Form.Control
                                type="date"
                                name="birth_date"
                                value={formData.birth_date}
                                onChange={handleChange}
                                required
                              /> */}
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid birth date.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="genderId" className="mb-3">
                              <Form.Label>Gender</Form.Label>
                              <Form.Control
                                as="select"
                                name="genderId"
                                value={formData.genderId}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Gender...</option>
                                <option value="0">Male</option>
                                <option value="1">Female</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select a gender.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="pod" className="mb-3">
                              <Form.Label>Place of Birth</Form.Label>
                              <Form.Control
                                as="select"
                                name="pod"
                                value={formData.pod}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Place of Birth</option>
                                {virtual_countries.map((country) => (
                                  <option
                                    key={country.country}
                                    value={country.alpha3}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Place of Birth .
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* <Form.Group controlId="sharedToken">
                      <Form.Label>Shared Token</Form.Label>
                      <Form.Control
                        type="text"
                        name="sharedToken"
                        value={formData.sharedToken}
                        onChange={handleChange}
                      />
                    </Form.Group> */}

                            <Form.Group controlId="occupation" className="mb-3">
                              <Form.Label>Occupation</Form.Label>
                              <Form.Control
                                as="select"
                                name="occupation"
                                value={formData.occupation}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Occupation</option>
                                <option value="1">Politicians</option>
                                <option value="2">Civil Servants</option>
                                <option value="3">Gas Station Owners</option>
                                <option value="4">
                                  Retail Business Owners
                                </option>
                                <option value="5">Car Dealerships</option>
                                <option value="6">Travel Agencies</option>
                                <option value="7">Investment Brokers</option>
                                <option value="8">Jewelry Dealers</option>
                                <option value="9">Lawyers</option>
                                <option value="10">Accountants</option>
                                <option value="11">Other</option>
                                <option value="12">Financial Services</option>
                                <option value="13">Hospitality</option>
                                <option value="14">Banker</option>
                                <option value="15">
                                  Information Technology Financial Services
                                </option>
                                <option value="16">Entrepreneur</option>
                                <option value="17">Plumber</option>
                                <option value="18">Graphic Designer</option>
                                <option value="19">Photographer</option>
                                <option value="20">Journalist</option>
                                <option value="21">Brand Manager</option>
                                <option value="22">Event Planner</option>
                                <option value="23">Auto Mechanic</option>
                                <option value="24">Landscaper</option>
                                <option value="25">Groundskeeper</option>
                                <option value="26">Fisherman</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select an occupation.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              controlId="nationality"
                              className="mb-3"
                            >
                              <Form.Label>Nationality </Form.Label>
                              <Form.Control
                                as="select"
                                name="nationality"
                                value={formData.nationality}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Nationality</option>
                                {virtual_countries.map((country) => (
                                  <option
                                    key={country.country}
                                    value={country.alpha3}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select a nationality.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                              variant="primary"
                              type="submit"
                              className="mt-2"
                            >
                              Submit KYC
                            </Button>
                            {isLoadingKYC && (
                              <div className="text-center mt-4">
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              </div>
                            )}
                          </Form>

                          {createdKYCLink &&
                            kycstatus === "KYC Not Verified" && (
                              <>
                                <VirtualCardWidgetBlank />
                                <div className="mt-4">
                                  <Alert variant="info">
                                    Please complete your KYC verification using
                                    the link below:
                                    <br />
                                    <Button
                                      href={createdKYCLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      variant="primary"
                                    >
                                      Complete KYC
                                    </Button>
                                  </Alert>
                                </div>
                              </>
                            )}
                        </Fragment>
                      </Card.Body>
                    </Card>
                  )}

                  {alert && (
                    <Alert
                      variant={alert.type}
                      onClose={() => setAlert(null)}
                      dismissible
                    >
                      {alert.message}
                    </Alert>
                  )}
                  {localUser?.virtual_chid === null ? (
                    <Card className="overflow-hidden">
                      <Card.Body>
                        {step === 1 && (
                          <Fragment>
                            <Form.Group controlId="pricingPlan">
                              {/* <Form.Label>Buy Card</Form.Label> */}
                              <Row>
                                {pricingModels.map((plan) => (
                                  <>
                                    <div className="items p-3">
                                      <h3
                                        className="mt-3 text-black"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {plan.name} Virtual Card
                                      </h3>
                                      <div
                                        className="card-bx mb-0 d-flex"
                                        style={{
                                          borderRadius: "10px",
                                          backgroundImage: `url(https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/5fbb9444-f771-4570-6c0b-fa6ea0a81900/public)`,
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          minHeight: "230px",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div className={`card-info text-black`}>
                                          {/* <div className="d-flex align-items-center justify-content-between text-center mb-sm-5 mb-3"> */}

                                          <div className="d-flex align-items-center justify-content-center text-center mb-sm-5 mt-sm-4 mb-3 mt-3">
                                            <h2 className="fs-36 text-black mb-sm-2 mb-1">
                                              ${plan.amount}
                                            </h2>
                                          </div>
                                          <div
                                            className="d-flex "
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div className="me-5">
                                              <p className="fs-14 mb-1 op6"></p>
                                            </div>
                                            <div>
                                              <p className="fs-14 mb-1 op6"></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                      {buyError && (
                                        <div className="p-3">
                                          <Alert variant="danger">
                                            {buyError}
                                          </Alert>
                                        </div>
                                      )}
                                      {address ? (
                                        <>
                                          <BuyButton
                                            Price={plan.amount}
                                            cardID={plan.cardid}
                                            onError={handlebuyerror}
                                            onSuccessBuy={handlebuysuccess}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-primary mt-3"
                                            onClick={() =>
                                              handlePlanSelection(plan)
                                            }
                                            // disabled={localUser?.kyc_step !== "4"}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              minWidth: "100px",
                                              margin: "0px auto",
                                            }}
                                          >
                                            {isLoading ? (
                                              <>
                                                <Spinner
                                                  as="span"
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                  className="me-2"
                                                />
                                              </>
                                            ) : (
                                              <div>
                                                <>BUY</>
                                              </div>
                                            )}
                                          </button>
                                        </>
                                      )}
                                      <Accordion
                                        className="accordion accordion-primary-solid mt-3 p-3"
                                        defaultActiveKey="0"
                                        style={{
                                          maxWidth: "350px",
                                          margin: "0px auto",
                                        }}
                                      >
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header className="accordion-header fs-20">
                                            Benefits
                                          </Accordion.Header>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="accordion__body  fs-16 text-black"
                                          >
                                            <div className="accordion-body">
                                              <span>
                                                {plan.text.map((line, idx) => (
                                                  <span key={idx}>
                                                    {line} <br />
                                                  </span>
                                                ))}
                                              </span>
                                            </div>
                                          </Accordion.Collapse>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                    {/* <Col
                                      md={4}
                                      key={plan.name}
                                      className="mb-4"
                                    >
                                      <Card
                                        className={`${
                                          selectedPlan?.name === plan.name
                                            ? "border-primary"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handlePlanSelection(plan)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Card.Body>
                                          <Card.Title>
                                            {plan.name} TEST
                                          </Card.Title>
                                          <Card.Text>
                                            <strong>Amount:</strong>{" "}
                                            {plan.amount} USD
                                            <br />
                                            <strong>Fee:</strong> {plan.fee}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </Col> */}
                                  </>
                                ))}
                              </Row>
                            </Form.Group>
                            {selectedPlan && (
                              <div>
                                <label className="text-black font-w600 ">
                                  Select Blockchain
                                </label>
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row", // Change from 'column' to 'row'
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    value="bep20"
                                    onClick={(e) => handlesetNetwork("bep20")}
                                    className="btn btn-primary mt-3 m-1"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    disabled={network && network === "bep20"}
                                  >
                                    <img
                                      src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                                      alt="network"
                                      style={{
                                        marginRight: "10px",
                                        width: "25px",
                                      }}
                                    />
                                    USDT BEP20
                                  </button>
                                  <button
                                    value="matic"
                                    onClick={(e) => handlesetNetwork("matic")}
                                    className="btn btn-primary mt-3 m-1"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    disabled={network && network === "matic"}
                                  >
                                    <img
                                      src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                                      alt="network"
                                      style={{
                                        marginRight: "10px",
                                        width: "25px",
                                      }}
                                    />
                                    USDT POLYGON
                                  </button>
                                </div>

                                {isLoadingNetwork && (
                                  <div className="text-center mt-4">
                                    <Spinner animation="border" role="status">
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </Spinner>
                                  </div>
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}

                        {step === 1 && createdAddress && (
                          <Card
                            className="mt-4 d-flex"
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Card.Body
                              className="d-flex"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <h4>Payment Details</h4>
                          <p>Address: {createdAddress}</p> */}
                              <label className="text-black font-w600 ">
                                Address
                              </label>
                              <div className="input-group mb-3 mt-3">
                                <input
                                  type="text"
                                  className="form-control text-black"
                                  placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                                  value={createdAddress}
                                  readOnly
                                />

                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard
                                      .writeText(createdAddress)
                                      .then(() =>
                                        setAlert({
                                          type: "success",
                                          message:
                                            "Address copied to clipboard",
                                        })
                                      )
                                      .catch((error) =>
                                        console.error(
                                          "Could not copy text: ",
                                          error
                                        )
                                      );
                                  }}
                                >
                                  COPY
                                </button>
                              </div>

                              <div className="text-danger font-w600">
                                Sending funds on any other blockchain will
                                result in the loss of your funds.
                              </div>

                              <label className="text-black font-w600 mt-2">
                                Amount
                              </label>
                              <div
                                className="input-group mb-3 mt-3"
                                style={{ maxWidth: "250px" }}
                              >
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  USDT
                                </button>
                                <input
                                  type="number"
                                  className="form-control text-black"
                                  placeholder="0"
                                  value={selectedPlan.amount}
                                  readOnly
                                />

                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard
                                      .writeText(selectedPlan.amount)
                                      .then(() =>
                                        setAlert({
                                          type: "success",
                                          message: "Amount copied to clipboard",
                                        })
                                      )
                                      .catch((error) =>
                                        console.error(
                                          "Could not copy text: ",
                                          error
                                        )
                                      );
                                  }}
                                >
                                  COPY
                                </button>
                              </div>
                              {qrCode && (
                                <div className="text-center">
                                  <img
                                    src={`data:image/png;base64,${qrCode}`}
                                    alt="QR Code"
                                    style={{ maxWidth: "220px" }}
                                  />
                                </div>
                              )}
                              {isLoadingTransaction ? (
                                <div className="text-center mt-4">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                  <div></div>
                                  <br></br>
                                  {txnHash ? (
                                    <Fragment>
                                      <p>Transaction Hash: {txnHash}</p>
                                      <p>Confirmations: {confirmations}</p>
                                    </Fragment>
                                  ) : (
                                    <>
                                      <br></br>
                                      <label className="text-black font-w600 mt-3">
                                        Waiting for Payment
                                      </label>
                                      <br></br>
                                    </>
                                  )}
                                </div>
                              ) : (
                                transactionsConfirmed && (
                                  <Alert
                                    variant="success"
                                    className="text-center mt-4"
                                  >
                                    Transaction confirmed! Card purchase
                                    successful.
                                  </Alert>
                                )
                              )}
                              {errormessage && (
                                <Alert
                                  variant="danger"
                                  className="text-center mt-4"
                                >
                                  {errormessage}
                                </Alert>
                              )}
                            </Card.Body>
                          </Card>
                        )}
                      </Card.Body>
                    </Card>
                  ) : !initialLoadSubmitted ? (
                    <Card className="overflow-hidden text-center">
                      <Card.Body>
                        {step === 4 && <VirtualCardWidget />}

                        {step === 3 && (
                          <>
                            {createdKYCLink &&
                            kycstatus === "KYC Not Verified" ? (
                              <>
                                <VirtualCardWidgetBlank />{" "}
                                <div className="row">
                                  <div className="col-xl-8 col-lg-12 col-sm-12">
                                    <div
                                      className="row"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div className="col-xl-8 col-lg-12 col-md-7 col-sm-8">
                                        <Alert className="text-black">
                                          Please complete your KYC verification
                                          using the link below:
                                          <br />
                                          {kyccountdown !== 0 ? (
                                            <>
                                              <Button
                                                className="mt-2"
                                                href={createdKYCLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="primary"
                                              >
                                                Complete KYC
                                              </Button>

                                              <h5 className="mt-2">
                                                Link expires in {kyccountdown}.
                                              </h5>
                                            </>
                                          ) : (
                                            <>
                                              <Button
                                                className="mt-2"
                                                variant="primary"
                                                onClick={handlegenerateKYCLink}
                                              >
                                                {linkloading ? (
                                                  <Spinner
                                                    style={{
                                                      width: "1rem",
                                                      height: "1rem",
                                                    }}
                                                    animation="border"
                                                    role="status"
                                                  ></Spinner>
                                                ) : (
                                                  "Complete KYC"
                                                )}
                                              </Button>
                                            </>
                                          )}
                                          <h4
                                            className="mt-2"
                                            style={{
                                              color:
                                                kycstatus === "KYC Not Verified"
                                                  ? "red"
                                                  : "green",
                                            }}
                                          >
                                            {kycstatus}
                                          </h4>
                                        </Alert>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {countdown === null && (
                                  <>
                                    {" "}
                                    <div className="col-xl-8 col-lg-12 col-md-7 col-sm-8 text-center">
                                      <h4
                                        className="mt-2"
                                        style={{
                                          color:
                                            kycstatus === "KYC Not Verified"
                                              ? "red"
                                              : "green",
                                          padding: "0px 50px 0px 35px",
                                        }}
                                      >
                                        <Alert
                                          variant="info"
                                          style={{
                                            color:
                                              kycstatus === "KYC Not Verified"
                                                ? "red"
                                                : "green",
                                          }}
                                        >
                                          {kycstatus}
                                        </Alert>
                                      </h4>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {countdown > 1 ? (
                              <>
                                <div className="d-flex justify-content-center">
                                  <div className="m-3 col-12 text-center">
                                    <h3>
                                      Issuing in process. It takes 24h to issue
                                      virtual card.
                                    </h3>
                                  </div>
                                </div>
                                <VirtualCardWidgetBlank />
                                <CountdownTimer countdownTime={countdown} />
                              </>
                            ) : (
                              <>
                                {kycstatus === "KYC Verified" && (
                                  <>
                                    <div className="col-xl-10 col-lg-12 col-md-7 col-sm-8">
                                      <div
                                        className="card-bx "
                                        style={{
                                          minWidth: "270px",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <img
                                          src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/5fbb9444-f771-4570-6c0b-fa6ea0a81900/public"
                                          alt=""
                                          //   className="mw-100 "
                                          style={{
                                            height: "auto",
                                            minHeight: "270px",
                                            position: "relative",
                                          }}
                                        />
                                        <div
                                          className="card-info text-black w-100 mt-3"
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            padding: "35px",
                                            paddingRight: "50px",
                                          }}
                                        >
                                          <div className="d-flex justify-content-center mt-3">
                                            <div className="m-3 mt-5 col-12 text-center">
                                              <h4>
                                                Issue Card With Initial Topup
                                              </h4>
                                              <Form.Group>
                                                <h3>$50</h3>
                                                {/* <Form.Label className="text-info">
                                                  $50
                                                </Form.Label> */}
                                                {/* <Form.Control
                                                  type="number"
                                                  min="80"
                                                  onChange={handleAmountChange}
                                                  style={{ color: "black" }}
                                                /> */}{" "}
                                                <button
                                                  className="btn btn-light"
                                                  onClick={handleAmountChange}
                                                  disabled={loadingIssue}
                                                  // style={{ height: "40px" }}
                                                >
                                                  {loadingIssue ? (
                                                    <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                      className="me-2"
                                                    ></Spinner>
                                                  ) : (
                                                    "Issue Card"
                                                  )}
                                                </button>
                                              </Form.Group>
                                              {/* <Button
                                            className="m-3"
                                            variant="primary"
                                            onClick={handleIssueCard}
                                            // disabled={!allow_load}
                                            disabled
                                          >
                                            {loadingIssue ? (
                                              <Spinner
                                                animation="border"
                                                role="status"
                                              ></Spinner>
                                            ) : (
                                              "Issue Card"
                                            )}
                                          </Button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {net_topup !== 0 && (
                          <Card
                            className="text-center"
                            style={{ maxHeight: "250px" }}
                          >
                            {/* <Card.Body> */}
                            <div>
                              <p className="fs-18 mb-3 text-black">
                                NET TOP UP: ${net_topup}
                              </p>
                              {address ? (
                                <>
                                  {!localUser?.virtualcards_reloadable ? (
                                    <Issuebutton
                                      LoadAmount={loadAmount}
                                      LoadAmountNet={net_topup}
                                      LoadAmountFCF={ToFcf}
                                      onTopUpSuccess={handleIssueSuccess}
                                      onStatusChange={handleStatusChange}
                                      settopuperror={handlesettopuperror}
                                    />
                                  ) : (
                                    <Topupbutton
                                      LoadAmount={loadAmount}
                                      LoadAmountNet={net_topup}
                                      LoadAmountFCF={ToFcf}
                                      onTopUpSuccess={handleTopUpSuccess}
                                      onStatusChange={handleStatusChange}
                                      settopuperror={handlesettopuperror}
                                    />
                                  )}
                                  {/* {chain?.id === 56 &&
                                      localUser?.settings?.BSC === 1 &&
                                      localUser?.settings?.web3 === 1 && (
                                        <Topupbutton
                                        LoadAmount={loadAmount}
                                        LoadAmountNet={net_topup}
                                        LoadAmountFCF={toFcf}
                                        onTopUpSuccess={handleTopUpSuccess}
                                        onStatusChange={handleStatusChange}
                                        settopuperror={handlesettopuperror}
                                        dailylimitexceeded={isdailylimit}
                                        />
                                      )}
                                    {chain?.id !== 56 &&
                                      localUser?.settings?.MATIC === 1 &&
                                      localUser?.settings?.web3 === 1 && (
                                        <Topupbutton
                                        LoadAmount={loadAmount}
                                        LoadAmountNet={net_topup}
                                        LoadAmountFCF={toFcf}
                                        onTopUpSuccess={handleTopUpSuccess}
                                        onStatusChange={handleStatusChange}
                                        settopuperror={handlesettopuperror}
                                        dailylimitexceeded={isdailylimit}
                                        />
                                      )} */}
                                </>
                              ) : (
                                <>
                                  <label className="text-black font-w600 ">
                                    Select Blockchain
                                  </label>
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row", // Change from 'column' to 'row'
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      value="bep20"
                                      onClick={(e) =>
                                        handlesetNetworktopup("bep20")
                                      }
                                      className="btn btn-primary mt-3 m-1"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      disabled={network && network === "bep20"}
                                    >
                                      <img
                                        src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                                        alt="network"
                                        style={{
                                          marginRight: "10px",
                                          width: "25px",
                                        }}
                                      />
                                      USDT BEP20
                                    </button>
                                    <button
                                      value="matic"
                                      onClick={(e) =>
                                        handlesetNetworktopup("matic")
                                      }
                                      className="btn btn-primary mt-3 m-1"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      disabled={network && network === "matic"}
                                    >
                                      <img
                                        src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                                        alt="network"
                                        style={{
                                          marginRight: "10px",
                                          width: "25px",
                                        }}
                                      />
                                      USDT POLYGON
                                    </button>
                                  </div>
                                </>
                              )}

                              {isLoadingNetwork && (
                                <div className="text-center mt-4">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </div>
                              )}
                            </div>
                            {/* </Card.Body> */}
                          </Card>
                        )}

                        {createdAddress && (
                          <Card
                            className="mt-4 d-flex"
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Card.Body
                              className="d-flex"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <h4>Payment Details</h4>
                          <p>Address: {createdAddress}</p> */}
                              <label className="text-black font-w600 ">
                                Address
                              </label>
                              <div className="input-group mb-3 mt-3">
                                <input
                                  type="text"
                                  className="form-control text-black"
                                  placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                                  value={createdAddress}
                                  readOnly
                                />

                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard
                                      .writeText(createdAddress)
                                      .then(() =>
                                        setAlert({
                                          type: "success",
                                          message:
                                            "Address copied to clipboard",
                                        })
                                      )
                                      .catch((error) =>
                                        console.error(
                                          "Could not copy text: ",
                                          error
                                        )
                                      );
                                  }}
                                >
                                  COPY
                                </button>
                              </div>

                              <div className="text-danger font-w600">
                                Sending funds on any other blockchain will
                                result in the loss of your funds.
                              </div>

                              <label className="text-black font-w600  mt-2">
                                Amount
                              </label>
                              <div
                                className="input-group mb-3 mt-3"
                                style={{ maxWidth: "250px" }}
                              >
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  USDT
                                </button>
                                <input
                                  type="number"
                                  className="form-control text-black"
                                  placeholder="0"
                                  value={loadAmount}
                                  readOnly
                                />

                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard
                                      .writeText(loadAmount)
                                      .then(() =>
                                        setAlert({
                                          type: "success",
                                          message: "Amount copied to clipboard",
                                        })
                                      )
                                      .catch((error) =>
                                        console.error(
                                          "Could not copy text: ",
                                          error
                                        )
                                      );
                                  }}
                                >
                                  COPY
                                </button>
                              </div>
                              {qrCode && (
                                <div className="text-center">
                                  <img
                                    src={`data:image/png;base64,${qrCode}`}
                                    alt="QR Code"
                                    style={{ maxWidth: "220px" }}
                                  />
                                </div>
                              )}
                              {isLoadingTransactionIssue ? (
                                <div className="text-center mt-4">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                  <div></div>
                                  <br></br>
                                  {txnHash ? (
                                    <Fragment>
                                      <p>Transaction Hash: {txnHash}</p>
                                      <p>Confirmations: {confirmations}</p>
                                    </Fragment>
                                  ) : (
                                    <>
                                      <br></br>
                                      <label className="text-black font-w600 mt-1">
                                        Waiting for Payment
                                      </label>
                                      <br></br>
                                    </>
                                  )}
                                  {loadingCardIssue && (
                                    <>
                                      <br></br>
                                      <label className="text-black font-w600 mt-1">
                                        Card issuing can take up to 10 Minutes.
                                        Stay on that page.
                                      </label>
                                      <br></br>
                                    </>
                                  )}
                                </div>
                              ) : (
                                transactionsConfirmed && (
                                  <Alert
                                    variant="success"
                                    className="text-center mt-4"
                                  >
                                    Transaction confirmed! Your card is being
                                    issued.
                                  </Alert>
                                )
                              )}
                              {errormessage && (
                                <Alert
                                  variant="danger"
                                  className="text-center mt-4"
                                >
                                  {errormessage}
                                </Alert>
                              )}
                            </Card.Body>
                          </Card>
                        )}

                        {depositDetails && (
                          <Card className="mt-4 text-center">
                            <Card.Body>
                              <div>
                                <h4 className="text-black font-w600">
                                  Deposit Details
                                </h4>

                                <label className="text-black font-w600">
                                  Address
                                </label>
                                <div className="input-group mb-3 mt-3">
                                  <input
                                    type="text"
                                    className="form-control text-black"
                                    value={depositDetails.address}
                                    readOnly
                                  />
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      navigator.clipboard
                                        .writeText(depositDetails.address)
                                        .then(() =>
                                          setAlert({
                                            type: "success",
                                            message:
                                              "Address copied to clipboard",
                                          })
                                        )
                                        .catch((error) =>
                                          console.error(
                                            "Could not copy text: ",
                                            error
                                          )
                                        );
                                    }}
                                  >
                                    COPY
                                  </button>
                                </div>

                                <label className="text-black font-w600">
                                  Crypto Currency
                                </label>
                                <p className="text-black">
                                  {depositDetails.crypto_currency_name}
                                </p>

                                <label className="text-black font-w600 text-center">
                                  Amount
                                </label>
                                <div
                                  className="input-group mb-3 mt-3"
                                  style={{ maxWidth: "250px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control text-black"
                                    value={
                                      depositDetails.crypto_currency_amount
                                    }
                                    readOnly
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      navigator.clipboard
                                        .writeText(
                                          depositDetails.crypto_currency_amount.toString()
                                        )
                                        .then(() =>
                                          setAlert({
                                            type: "success",
                                            message:
                                              "Amount copied to clipboard",
                                          })
                                        )
                                        .catch((error) =>
                                          console.error(
                                            "Could not copy text: ",
                                            error
                                          )
                                        );
                                    }}
                                  >
                                    COPY
                                  </button>
                                </div>

                                {isLoadingTransaction ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      role="status"
                                      aria-hidden="true"
                                      className="me-2"
                                    />
                                    <br></br>
                                    {/* {txn_hash ? (
                                            <>
                                              <label className="text-black font-w600 mt-3">
                                                Transaction Hash
                                              </label>
                                              <label className="text-black font-w600 mt-3">
                                                {txn_hash.substring(0, 15)}...
                                              </label>
                                              <label className="text-black font-w600 mt-3">
                                                Confirmations : {confirmations}
                                              </label>
                                            </>
                                          ) : ( */}
                                    <>
                                      <label className="text-black font-w600 mt-3">
                                        Waiting for Payment
                                      </label>
                                      <br></br>
                                    </>
                                    {/* )} */}
                                  </>
                                ) : (
                                  <div
                                    className="alert alert-warning"
                                    role="alert"
                                  >
                                    Transaction Confirmed. The card balance
                                    loading can take up to 5 minutes. Please be
                                    patient and do not reload this page.
                                  </div>
                                )}

                                {/* <label className="text-black font-w600">
                                  Checkout Page Test
                                </label>
                                <a
                                  href={depositDetails.checkout_page_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {depositDetails.checkout_page_url}
                                </a> */}
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </Card.Body>
                    </Card>
                  ) : (
                    depositDetails && (
                      <Card className="mt-4">
                        <Card.Body>
                          <h4>Deposit Details</h4>
                          <p>Address: {depositDetails.address}</p>
                          <p>
                            Crypto Currency:{" "}
                            {depositDetails.crypto_currency_name}
                          </p>
                          <p>Amount: {depositDetails.crypto_currency_amount}</p>
                          <p>
                            Checkout Page:{" "}
                            <a
                              href={depositDetails.checkout_page_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {depositDetails.checkout_page_url}
                            </a>
                          </p>
                        </Card.Body>
                      </Card>
                    )
                  )}
                </>
              )}
            </div>
          </div>
          <CardTransactions />
        </div>
      </div>
    </Fragment>
  );
};

export default VirtualCardsReloadable;
