import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import TransferOwl from "./../Mophy/Dashboard/TransferOwl";
import TransferBarGraph from "./../Mophy/Dashboard/TransferBarGraph";
import HomeTab from "./../Mophy/Dashboard/HomeTab";
import Donut from "./../Mophy/Dashboard/Donut";
import Wizard from "../Forms/Wizard/Wizard";
import Spinner from "react-bootstrap/Spinner";
import { Row, Card, Col, Button, Nav, Alert, Accordion } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";

import { Table, InputGroup, FormControl } from "react-bootstrap";

import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";

//Images
import card1 from "./../../../images/card/card.png";
import dual from "./../../../images/dual-dot.png";
import profile from "./../../../images/user_logo.png";
import visa from "./../../../images/visa_black.PNG";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

//WEB3
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { usdt_abi } from "../../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_TOPUP_ABI } from "../../../../src/contracts/BSC_TOPUP_ABI.js";
import Web3 from "web3";

import Topupbutton from "../../../../src/jsx/components/web3/Topupbutton.js";

const bnblogo =
  "https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png";
const maticlogo = "https://cryptologos.cc/logos/polygon-matic-logo.png";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const POLYGON_USDT_CONTRACT = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";

const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";
const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

const LimitChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/LimitChart"), 1000)
);
const IncomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/IncomeChart"), 1000)
);
const OutomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/OutomeChart"), 1000)
);
const WeeklyChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/WeeklyChart"), 1000)
);

const Home = () => {
  const [sendMessage, setSendMessage] = useState(false);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [userWallet, setUserWallet] = useState("");
  const user = useSelector((state) => state.user.user);
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);

  const [apiFee, setApiFee] = useState(0);
  const [qrFee, setqrFee] = useState(0);
  const [blockchainFee, setblockchainFee] = useState(0.5);
  const [camFee, setCamFee] = useState(0);
  const [loadOnCard, setLoadOnCard] = useState(0);
  const [fcfFee, setFcfFee] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [toFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [topupref, settopupref] = useState(0);
  const [bscBalance, setbscBalance] = useState(0);

  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingActivate, setisLoadingActivate] = useState(false);

  const [cardBalance, setcardBalance] = useState(0);
  const [issuer_Last4, setissuer_Last4] = useState("0000");
  const [card_status, setcard_status] = useState("");
  const [pin, setpin] = useState(null);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const handleShowPinDialog = () => setShowPinDialog(true);
  const handleClosePinDialog = () => {
    setShowPinDialog(false);
    setpin(null); // Reset the pin state when the modal is closed
    setIsChangePin(false); // Reset the change pin view
    setNewPin(""); // Reset new pin input
  };
  const [isChangePin, setIsChangePin] = useState(false);
  const [newPin, setNewPin] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [net_topup, setnet_topup] = useState(0);
  const [refreshloading, setrefreshloading] = useState(false);
  const [showpinloading, setshowpinloading] = useState(false);
  const [minAmount, setminAmount] = useState(0);
  const [real_amount, setreal_amount] = useState(0);
  //FIRST STEPS
  const [goSteps, setGoSteps] = useState(0);

  //TOPUPCARD
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [address_in, setaddressin] = useState("");
  const [txn_hash, settxn_hash] = useState("");
  const [confirmations, setconfirmations] = useState(0);
  const [success_message, setsuccess_message] = useState(false);
  const [success_message_pin, setsuccess_message_pin] = useState(false);
  const [old_balance, set_old_balance] = useState(false);
  const [show, setShow] = useState(false);
  const [showCardFee, setShowCardFee] = useState(true);

  const [status, setStatus] = useState("");
  const [network, setNetwork] = useState("");

  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);
  const [topupsended, settopupsended] = useState(false);
  const [dailytopups, setdailytopups] = useState(0);
  const [isdailylimit, setdailylimit] = useState(false);
  const [limitleft, setlimitleft] = useState(0);

  const [isLoadingTransactionMATIC, setisLoadingTransactionMATIC] =
    useState(false);
  const [isLoadingTransactionBSC, setisLoadingTransactionBSC] = useState(false);

  const [maintenance, setmaintenance] = useState("");
  const [datasendtoDB, setdatasendtoDB] = useState(false);
  const [topuperror, settopuperror] = useState("");
  const [activeTab, setActiveTab] = useState("transactions");

  // Add pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDeposit, setCurrentPageDeposit] = useState(1);
  const [itemsPerPage] = useState(10);

  //success_activate
  const [success_activate, setsuccess_activate] = useState(false);
  const [error_activate, seterror_activate] = useState(false);
  // Calculate indices
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItemDeposit = currentPageDeposit * itemsPerPage;
  const indexOfFirstItemDeposit = indexOfLastItemDeposit - itemsPerPage;

  // Slice the transactions array
  const currentItems = localUser?.transactions
    .filter(
      (transaction) =>
        transaction !== null && transaction.description !== "Card Top Up"
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const currentItemsDeposits = localUser?.transactionstopup
    .filter((transaction) => transaction !== null)
    .slice(indexOfFirstItemDeposit, indexOfLastItemDeposit);

  const handleTabClick = (tab) => {
    // console.log(tab);
    setActiveTab(tab);
  };

  const loadingfees = {
    1: [0.0499, 5],
    2: [0.0399, 4],
    3: [0.0349, 3],
    10: [0, 0],
  };

  const loadinglimit = {
    1: [1500],
    2: [3000],
    3: [150000],
    10: [0, 0],
  };

  let usdt_contract;
  let chainid;
  if (address) {
    chainid = chain.id;
    if (chain.id === 137) {
      usdt_contract = POLYGON_USDT_CONTRACT;
    } else if (chain.id === 56) {
      usdt_contract = BSC_USDT_CONTRACT;
    }
  }

  const { data: readData, isLoading: readLoading } = useContractRead({
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "balanceOf",
    args: [address],
  });

  useEffect(() => {
    // console.log(
    //   "localUser.transactionstopuprealtime",
    //   localUser.transactionstopuprealtime.length
    // );
    if (localUser.transactionstopuprealtime.length > 0) {
      const totalBrutAmount = localUser.transactionstopuprealtime.reduce(
        (total, transaction) => total + parseFloat(transaction.brut_amount),
        0
      );
      // console.log("Total Brut Amount:", totalBrutAmount);
      setdailytopups(totalBrutAmount);
      let dailylimit;
      if (localUser?.cards?.card_type) {
        // console.log("dailylimit", dailylimit);
        dailylimit =
          Number(loadinglimit[localUser?.cards?.card_type][0]) -
          totalBrutAmount;
        // console.log("dailylimit left", dailylimit);
      }
      setlimitleft(dailylimit);
      // console.log("dailylimit", dailylimit);
      // console.log("LoadAmount", LoadAmount);
      if (LoadAmount !== 0) {
        if (dailylimit >= LoadAmount) {
          // console.log("setdailylimit true");
          setdailylimit(true);
        }
      } else {
        if (dailylimit >= totalBrutAmount) {
          // console.log("setdailylimit true");
          setdailylimit(true);
        }
      }
    } else {
      if (localUser?.cards?.card_type) {
        setlimitleft(Number(loadinglimit[localUser?.cards?.card_type][0]));
        if (LoadAmount !== 0) {
          if (
            Number(loadinglimit[localUser?.cards?.card_type][0]) >= LoadAmount
          ) {
            // console.log("setdailylimit true");
            setdailylimit(true);
          } else {
            setdailylimit(false);
          }
        } else {
          setdailylimit(true);
        }
      }
    }
  }, [localUser, LoadAmount]);

  useEffect(() => {
    console.log("Set new USDT Balance");
    if (chainid === 137) {
      if (readData) {
        let tokenBalance = Number(readData) / Math.pow(10, 6);
        // console.log("bscBalanceETH", tokenBalance);
        setbscBalance(tokenBalance.toFixed(2));
      }
    } else if (chainid === 56) {
      if (readData) {
        let bscBalanceETH = Web3.utils.fromWei(String(readData), "ether");
        // console.log("bscBalanceETH", bscBalanceETH);
        setbscBalance(Number(bscBalanceETH).toFixed(2));
      }
    }
  }, []);

  const handleonPinChanged = () => {
    setShow(true);
    setsuccess_message_pin(true);
    setGoSteps(1);
  };

  const nextPageDeposit = () => {
    setCurrentPageDeposit((prev) => prev + 1);
  };

  const prevPageDeposit = () => {
    if (currentPageDeposit > 1) {
      setCurrentPageDeposit((prev) => prev - 1);
    }
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleStatusChange = (status) => {
    // console.log("handleStatusChange", status);
    setStatus(status);
  };

  const handlesettopuperror = (message) => {
    // console.log("handleettopuperror", message);
    settopuperror(message);
  };

  const handleTopUpSuccess = async () => {
    // console.log("handleTopUpSuccess");
    setsuccess_message(true);
    set_old_balance(cardBalance);
    const interval = setInterval(() => {
      handlerefreshbalance();
    }, 15000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (old_balance != 0) {
      if (cardBalance != old_balance) {
        // console.log("Balance refreshed");
        setrefreshloading(false);
      }
    }
  }, [cardBalance]);

  useEffect(() => {
    if (user) {
      //   console.log("set user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      setLocalUser(user);
      if (user.settings.card_load_status === 0) {
        setmaintenance(user.settings.card_load_error);
      } else {
        setmaintenance("");
      }
    }
  }, [user]);

  const handleChangePin = () => {
    setIsChangePin(true);
  };

  const handleSubmitNewPin = async () => {
    if (!/^\d{4}$/.test(newPin)) {
      alert("PIN must be exactly 4 digits.");
      return;
    }
    setIsSubmitting(true); // Show loading spinner on submit button
    try {
      const data = {
        card_number: localUser?.cards?.cardKitNumber,
        PIN: newPin,
      };

      const response = await fetch(
        "https://dashboard.croesus-asset.management/api/physical-cards/set-pin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      console.log("Pin changed successfully");
      setIsSubmitting(false); // Stop loading spinner
      alert("Pin changed successfully!"); // Display success message
    } catch (error) {
      console.error("Error changing pin:", error);
      setIsSubmitting(false); // Stop loading spinner
      alert("Error changing PIN. Please try again.");
    } finally {
      setIsSubmitting(false); // Stop loading spinner
      handleClosePinDialog(); // Close modal after submission
    }
  };

  const handleShowPin = async () => {
    setshowpinloading(true);
    try {
      const data = {
        card_number: localUser?.cards?.cardKitNumber,
      };

      fetch(
        "https://dashboard.croesus-asset.management/api/physical-cards/get-pin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Pin fetched successfully");
          setpin(data.message);
          setshowpinloading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log("Error fetching pin", error);
      setshowpinloading(false);
    }
  };

  const handlerefreshbalance = async () => {
    setrefreshloading(true);
    const balance = await fetchcardbalance();
    if (balance !== cardBalance) {
      console.log("Balance changed");
      if (localUser?.cards?.bb_active == "0") {
        console.log("Card is inactive");
        const data = {
          userId: localUser.id,
        };

        fetch("https://api.bridge-bit.com/api/update_card_status", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .finally(() => {
            console.log("bb active successful DB");
            setcard_status("1");
          });
      } else {
        console.log("Card active");
      }
      setrefreshloading(false);
      window.location.reload();
    }
    setcardBalance(balance);
    setrefreshloading(false);
  };

  useEffect(() => {
    console.log("fetching Balance");
    // console.log(localUser);

    const firebaseUid = localUser?.firebaseUid;

    fetch(`https://api.bridge-bit.com/api/user/${firebaseUid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("User not found.");
        }
        return response.json();
      })
      .then((userData) => {
        console.log("SetLocalUser", userData);
        setLocalUser(userData);
        setcardBalance(userData.cards.balance);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const fetchBalance = async () => {
      if (localUser?.cards?.cardKitNumber !== "NULL") {
        console.log("fetched Balance");
        setcard_status(localUser.cards.bb_active);
        setrefreshloading(true);
        const balance = await fetchcardbalance();
        setcardBalance(balance ?? localUser.cards.balance);
        setissuer_Last4(localUser?.cards?.issuer_Last4);
        setrefreshloading(false);
        if (localUser.cards.initial_pin === "1") {
          setGoSteps(1);
        }
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    settopuperror("");
    console.log("useEffect Loadmount");
    console.log("Address", address);
    let min_amount;
    let fix_fee;
    let real_amount;
    if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === "1"
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 30;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === null
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 20;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.monthly_fee === "1" &&
      localUser?.cards?.bb_active !== "0"
    ) {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 10;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }
    if (LoadAmount >= min_amount) {
      if (Number(LoadAmount) > Number(limitleft)) {
        console.log("Limit exceeded");
        setdailylimit(false);
        return;
      }

      let InputAmount = LoadAmount - fix_fee;
      // console.log("InputAmount", InputAmount);
      let cryptapifee = LoadAmount * 0.01;
      // console.log("cryptapifee", cryptapifee);

      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);

      let net_card_amount;
      if (address !== undefined) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      // console.log("net_card_amount", net_card_amount);
      //   let fcf_fee = net_card_amount * 0.0275 + 3;
      //   console.log("fcf_fee", fcf_fee);
      //   let net_card_loading_amount = net_card_amount - fcf_fee;
      //   console.log("net_card_loading_amount", net_card_loading_amount);

      //   //TOPUP SPLITTING
      //   let bb_amount = InputAmount - net_card_amount;
      //   console.log("bb_amount", bb_amount);
      //   let bb_marketing = bb_amount * 0.8;
      //   console.log("bb_marketing", bb_marketing);
      //   let bb_cr = bb_amount * 0.1;
      //   console.log("bb_cr", bb_cr);
      //   let bb_team = bb_amount * 0.1;
      //   console.log("bb_team", bb_team);
      //   let control = bb_marketing + bb_cr + bb_team;
      //   console.log("control", control);

      setnet_topup(net_card_amount.toFixed(2));
      setToFcf(net_card_amount.toFixed(2));
    } else {
    }
  }, [LoadAmount]);

  const fetchUserTransactions = async () => {
    try {
      // console.log("address in", address_in);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${address_in}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      settxn_hash(data.txid_in);
      setconfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTransactions(response.data);
      if (response.data) {
        let forwarded_coin = response.data.value_forwarded_coin;
        // console.log("Forwardediting coin", forwarded_coin);
        let bb_amount = forwarded_coin * toCamPercentage;
        // console.log("bb_amount", bb_amount);
        let bb_split_amount = bb_amount * 0.8;
        // console.log("bb_split_amount", bb_split_amount);
        setbb_marketingAmount(bb_split_amount);
      }

      if (data.status == "confirmed") {
        setisLoadingTransaction(false);
        sendTopUpData(data.txid_in);
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  useEffect(() => {
    // console.log("setUserWallet", address);
    if (address != undefined) {
      setUserWallet(address);
    }
  }, [address]);

  useEffect(() => {
    if (address_in !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        fetchUserTransactions();
        fetchcardbalance();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [address_in]);

  const handlesetNetwork = (target) => {
    // console.log("Network:", target);
    setNetwork(target);

    createapiaddress(target);
  };

  const createWeb3API = async () => {
    console.log("Loadamount", LoadAmount);
    setIsLoading(true);
    setisLoadingTransaction(true);
    let fcf_network;
    let crypt_network;
    if (chainid === 56) {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      setisLoadingTransactionBSC(true);
    } else if (chainid === 137) {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      setisLoadingTransactionMATIC(true);
    }
    let min_amount;
    let fix_fee;
    let real_amount;
    if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === "1"
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 30;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === null
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 20;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.monthly_fee === "1" &&
      localUser?.cards?.bb_active !== "0"
    ) {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 10;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }

    try {
      let amount = LoadAmount;
      console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading
      let InputAmount = LoadAmount - fix_fee;

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee createapiaddress", cryptapifee);
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;

      net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;

      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(2));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        fcf_network
      );

      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.data.order_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      let topupsplit = bridgebit_amount * 0.8;
      settopupref(topupsplit);
      console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      setbb_marketingAmount(bridgebit_amount * 0.8);
      setbb_crAmount(bridgebit_amount * 0.1);
      setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        // setaddressin(data.address_in);
        // setCreatedAddress(data.address_in);
        // fetchQrCode(data.address_in, crypt_network);
        setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  const createapiaddress = async (network) => {
    // console.log("network", network);
    setIsLoading(true);
    setisLoadingTransaction(true);

    let fcf_network;
    let crypt_network;
    if (network === "bep20") {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      setisLoadingTransactionBSC(true);
    } else if (network === "matic") {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      setisLoadingTransactionMATIC(true);
    }

    let min_amount;
    let fix_fee;
    let real_amount;
    if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === "1"
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 30;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === null
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 20;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.monthly_fee === "1" &&
      localUser?.cards?.bb_active !== "0"
    ) {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 10;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }

    try {
      let amount = LoadAmount;
      // console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading
      let InputAmount = LoadAmount - fix_fee;

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee createapiaddress", cryptapifee);
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(2));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        fcf_network
      );

      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.data.order_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      let topupsplit = bridgebit_amount * 0.8;
      settopupref(topupsplit);
      console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      // console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      setbb_marketingAmount(bridgebit_amount * 0.8);
      setbb_crAmount(bridgebit_amount * 0.1);
      setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(data.address_in, crypt_network);
        setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  const handleActivateCard = async () => {
    const userConfirmedReceive = window.confirm(
      "Did you already receive your card?"
    );

    if (userConfirmedReceive) {
      setisLoadingActivate(true);
      const userConfirmed = window.confirm(
        "Do you really want to assign your card? Your first monthly fee will be due end of the month. Please load your card as soon as possible."
      );
      if (userConfirmed) {
        try {
          let cardkitnumber = localUser.shipping.cardKitNumber;
          console.log("cardkitnumber", cardkitnumber);
          console.log("localUser.id", localUser.id);
          const response = await axios.post(
            "https://api.bridge-bit.com/api/activateCard",
            {
              cardKitNumber: localUser.shipping.cardKitNumber,
              userid: localUser.id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          console.log("response", response);
          setsuccess_activate(true);
          setisLoadingActivate(false);
          window.location.reload();
        } catch (error) {
          console.error(error); // keep this line if you want general error logging
          if (error.response && error.response.status === 500) {
            console.log("API responded with 500 error:", error.response.data);
          }
          setisLoadingActivate(false);
          seterror_activate(true);
        }
      } else {
        alert("You need to confirm the rules to assign the card.");
      }
      setisLoadingActivate(false);
    } else {
      alert("Please wait until you receive your card.");
    }
  };

  const handleLoadClick = async () => {
    setIsLoading(true);
    setisLoadingTransaction(true);
    try {
      // const fee = await fetchblockhainfee();
      // if (isNaN(fee)) {
      //   console.log("Invalid fee value: ", fee);
      // } else {
      //   console.log("fee", fee);
      //   setblockchainFee(Number(fee));
      // }

      let amount = LoadAmount;
      // console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee", cryptapifee);
      let bridgetloadingfee =
        amount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = amount - bridgetloadingfee;
      } else {
        net_card_amount = amount - bridgetloadingfee - cryptapifee - 0.5;
      }
      // console.log("net_card_amount", net_card_amount);

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      let fcf_fee = net_card_amount * 0.0275 + 3;
      // console.log("fcf_fee", fcf_fee);
      let net_card_loading_amount = net_card_amount - fcf_fee;
      // console.log("net_card_loading_amount", net_card_loading_amount);

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        "BSC.USDT"
      );
      // console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.data.order_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      // console.log("bridgebit_amount", bridgebit_amount);
      let topupsplit = bridgebit_amount * 0.8;
      settopupref(topupsplit);
      // console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      // console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      setToCamPercentage(to_cam_percentage);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      // console.log(perc_to_fcf);
      // console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const ticker = "bep20/usdt"; // Replace with your ticker (e.g., 'btc', 'eth', etc.)
      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log("Address created:", data);

      if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(data.address_in);
        setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    // console.log("cardNumber", cardNumber);
    // console.log("amount", amount.toString());
    // console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/load-deposit",
      {
        card_number: cardNumber,
        amount: amount.toString(),
        token_symbol: tokenSymbol,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  const fetchcardbalance = async () => {
    if (localUser?.cards?.cardKitNumber) {
      try {
        const response = await axios.post(
          "https://api.bridge-bit.com/api/getBalanceByUser",
          {
            cardKitNumber: localUser.cards.cardKitNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        console.log(" ", response);

        if (response.data.success === true) {
          return response.data.message;
        } else {
          if (
            response.data.message === "Failed to retrieve balance" ||
            response.data.message === "Something went wrong"
          ) {
            if (localUser) {
              return localUser.cards.balance;
            }
          }
          return localUser.cards.balance;
        }

        if (!response.status === 200) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.log(error); // log the error to the console for debugging

        if (localUser) {
          return localUser.cards.balance;
        }
        // Handle the error based on the error message
        if (error.response && error.response.status === 500) {
          // Server error, handle it as per the requirement
        } else if (error.request) {
          // The request was made but no response was received
          console.log("The request was made but no response was received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      }
    }
  };

  const fetchblockhainfee = async (address) => {
    try {
      const response = await fetch(
        `https://api.cryptapi.io/bep20/usdt/estimate/?addresses=2&priority=default`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log("fetchblockhainfee:", data.estimated_cost_currency.USD);
      return data.estimated_cost_currency.USD;
    } catch (error) {
      console.error("Error fetching fetchblockhainfee:", error);
    }
  };

  const fetchQrCode = async (address, network) => {
    try {
      var value = LoadAmount;
      const query = new URLSearchParams({
        address: address,
        size: "512",
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${network}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      // Assuming the QR code data contains a base64 encoded image
      if (data.qr_code) {
        setQrCode(data.qr_code); // Update the state with the QR code data
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  async function sendTopUpData(txid_in) {
    console.log("sendTopUpData");
    if (!topupsended) {
      const payload = {
        user: localUser.id,
        currency: network,
        amount: bb_marketingAmount,
        brut_amount: LoadAmount,
        net_amount: net_topup,
        fcf_amount: toFcf,
        bb_amount: bb_marketingAmount,
        marketing_amount: bb_marketingAmount,
        txn_hash: txn_hash,
      };
      // console.log("Payload:", payload);

      axios
        .post("https://api.bridge-bit.com/api/split_topup", payload)
        .then(async (response) => {
          console.log("Response received:", response);
          settopupsended(true);
        })
        .catch((error) => {
          console.error("Error encountered while sending topup data:", error);
        });
    }
  }

  const UsdtBalances = () => {
    //console.log("usdtabi data", usdtabi);
    // console.log("usdtabi address", address);

    let usdt_address;

    if (address) {
      if (chain.id == 56) {
        console.log("chain.id", chain.id);
        usdt_address = BSC_USDT_CONTRACT;
      } else {
        console.log("chain.id", chain.id);
        usdt_address = POLYGON_USDT_CONTRACT;
      }
    }

    const {
      data: usdtdata,
      isError,
      isLoading,
    } = useContractRead({
      address: usdt_address,
      abi: usdt_abi,
      functionName: "balanceOf",
      args: [address],
    });

    if (isLoading) return <div>Fetching balance...</div>;
    if (isError) return <div>Error fetching balance</div>;

    // Wait for the data to be fetched before accessing it
    if (!usdtdata) return null;
    // console.log("usdtdata", usdtdata);

    console.log("balanceOf data", usdtdata);
    let formattedBalance = 0;

    if (chain.id == 56) {
      formattedBalance = Web3.utils.fromWei(usdtdata.toString(), "ether");
    } else {
      formattedBalance = Web3.utils.fromWei(usdtdata.toString(), "mwei");
    }
    // console.log("formattedBalance", formattedBalance);
    const roundedBalance = parseFloat(formattedBalance).toFixed(2);

    return (
      <div>
        {roundedBalance}{" "}
        <span className="text-large text-white" style={{ fontSize: "1rem" }}>
          USDT
        </span>
      </div>
    );
  };

  const BNBBalances = () => {
    const { data, isError, isLoading, refetch } = useBalance({
      address,
      watch: true,
    });

    // console.log("data", data);

    if (isLoading) return <div>Fetching balance...</div>;
    if (isError) return <div>Error fetching balance</div>;

    return (
      <div>
        {data?.formatted}{" "}
        <span className="text-large " style={{ fontSize: "1rem" }}>
          {" "}
          {data?.symbol}
        </span>
      </div>
    );
  };

  const handlecardfeepopup = () => {
    const data = {
      userId: localUser.id,
    };

    fetch("https://api.bridge-bit.com/api/confirm_popup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .finally(() => {
        console.log("confirm_popup success");
        setShowCardFee(false);
        const newUser = { ...localUser };
        newUser.cards.popup = null;
        setLocalUser(newUser);
      });
  };

  return (
    <Fragment>
      {isLoadingActivate && (
        <div style={loadingScreenStyle}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <br></br>
          <p>
            Assigning your card, please wait. This can take up to 5 minutes.
          </p>
        </div>
      )}

      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Card Management</h2>
      </div>
      {localUser?.cards?.card_type !== 0 ? (
        <>
          {card_status == "1" ? (
            <></>
          ) : (
            <>
              {localUser?.cards?.cardKitNumber !== null && (
                <div className="row">
                  <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          First Steps With Your Bridge Bit Card
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="form-wizard ">
                          <Stepper
                            className="nav-wizard"
                            activeStep={goSteps}
                            // label={false}
                          >
                            <Step
                              className="nav-link"
                              onClick={() => setGoSteps(0)}
                            />
                            <Step
                              className="nav-link"
                              onClick={() => setGoSteps(1)}
                            />
                            {/* <Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
                          </Stepper>
                          {goSteps === 0 && (
                            <>
                              <h4 className="card-title text-center">
                                SET PERSONAL PIN
                              </h4>
                              <StepOne
                                localUser={localUser}
                                onPinChanged={handleonPinChanged}
                              />

                              {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={() => setGoSteps(1)}
                      >
                        Next
                      </button>
                    </div> */}
                            </>
                          )}
                          {goSteps === 1 && (
                            <>
                              <div className="text-center mb-3">
                                <h4 className="card-title text-center">
                                  INITIAL TOP UP
                                </h4>
                                <label className="text-black font-w600 ">
                                  To activate your card you have to top up at
                                  least $100 or more. The activation fee of 20$
                                  will be automatically substracted from your
                                  initial top up.
                                </label>
                              </div>
                              <StepTwo localUser={localUser} />
                              {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Next
                      </button>
                    </div> */}
                            </>
                          )}
                          {goSteps === 2 && (
                            <>
                              {/* <StepThree /> */}
                              <div className="text-end toolbar toolbar-bottom p-2">
                                <button
                                  className="btn btn-secondary sw-btn-prev me-1"
                                  onClick={() => setGoSteps(1)}
                                >
                                  Prev
                                </button>
                                <button
                                  className="btn btn-primary sw-btn-next ms-1"
                                  onClick={() => setGoSteps(3)}
                                >
                                  Next
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {success_message_pin || localUser?.cards?.initial_pin === "1" ? (
            <>
              {show && (
                <Alert variant="success" dismissible className="text-center">
                  <strong>Success! </strong> Initial PIN set.
                  <button
                    className="btn-close"
                    onClick={() => setShow(false)}
                  ></button>
                </Alert>
              )}

              {address && (
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-sm-12">
                    <div className="row">
                      <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                        <div
                          className="card card-bx card-body overflow-hidden p-0 mb-2 d-flex rounded"
                          style={{
                            height: "100px",
                            background: "black",
                            marginLeft: "5px",
                            marginRight: "5px",
                            width: "auto",
                          }}
                        >
                          <div
                            className="card-info d-flex"
                            style={{
                              height: "100%",
                              alignItems: "start",
                              flexDirection: "column",
                              justifyContent: "center",
                              backgroundImage:
                                chain.id == 56
                                  ? `url(${bnblogo})`
                                  : `url(${maticlogo})`,
                              backgroundSize: chain.id == 56 ? "90px" : "100px",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "calc(100% - 10px) center", // Adjust the pixel value to move the image away from the right edge
                              boxSizing: "border-box",
                              padding: "10px",
                            }}
                          >
                            <h4 className="text-white">Balances</h4>
                            <h4 className="text-white">
                              {/* {bscBalance} */}
                              <UsdtBalances></UsdtBalances>
                            </h4>
                            <h5 className="text-white">
                              <BNBBalances></BNBBalances>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {localUser?.cards?.popup && (
                <>
                  {cardBalance < 20 && localUser?.cards?.popup === null && (
                    <>
                      <div className="row">
                        <div className="col-xl-8 col-lg-12 col-sm-12">
                          <div className="row">
                            <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                              <Modal show={showCardFee}>
                                <Modal.Header>
                                  <Modal.Title>Monthly Card Fee</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Dear Client,
                                  <br></br>
                                  <br></br>
                                  The monthly card fee (10 USD) is always paid
                                  in advance and is deducted from your card
                                  balance. This means that in the month of
                                  receiving your card, you get to use it for
                                  free until the first of the following month.{" "}
                                  <br></br>
                                  <br></br>Please top up your balance at the
                                  latest on the last day of this month. If no
                                  top up is made, your card becomes inactive and
                                  you will have to pay a reissue fee in order to
                                  receive a new card. <br></br>
                                  <br></br>Yours sincerely, <br></br>BridgeBit
                                  Team
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="primary"
                                    onClick={handlecardfeepopup}
                                  >
                                    Confirm
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              <div className="row">
                <div className="col-xl-8 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                      {localUser?.cards?.monthly_fee === "1" && (
                        <Alert
                          variant="danger"
                          dismissible
                          className="text-center"
                        >
                          <strong>
                            Your monthly fee is due. $10 will be automatically
                            deducted during your next top up.
                          </strong>
                          <button
                            className="btn-close"
                            onClick={() => setShow(false)}
                          ></button>
                        </Alert>
                      )}
                      <div className="card-bx " style={{ minWidth: "270px" }}>
                        <img
                          src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/a9354c5a-e6d4-47e1-ea4b-dc3505089800/public"
                          alt=""
                          className="mw-100 "
                          style={{
                            height: "auto",
                            minHeight: "270px",
                          }}
                        />
                        <div className="card-info text-white">
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="mb-1">Card Balance</p>
                            {card_status == "1" ? (
                              <p className="mb-1 text-success font-w600">
                                Active
                              </p>
                            ) : (
                              <p className="mb-1 text-danger font-w600">
                                Inactive
                              </p>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2 className="fs-36 text-white mb-sm-2 mb-3">
                              {refreshloading ? (
                                <div className="loadingDots">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              ) : (
                                `$${cardBalance}`
                              )}
                            </h2>
                            <button
                              className="btn btn-light ml-2 p-2"
                              onClick={handlerefreshbalance}
                            >
                              <i
                                className={`fas fa-sync-alt ${
                                  refreshloading ? "fa-spin" : ""
                                }`}
                                style={{ fontSize: "25px" }}
                              ></i>
                            </button>
                          </div>

                          {LoadAmount < minAmount ? (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "space-between" }}
                            >
                              <span className="fs-14 text-danger mb-0">
                                Min. Amount {minAmount}
                              </span>

                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "end" }}
                            >
                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          )}

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-auto text-black"
                                placeholder="Enter Amount"
                                onChange={(e) => setLoadAmount(e.target.value)}
                                disabled={
                                  isLoadingTransactionBSC ||
                                  isLoadingTransactionMATIC
                                }
                              />
                              {address ? (
                                <>
                                  {maintenance ? (
                                    <>
                                      {chain?.id === 56 &&
                                        localUser?.settings?.BSC === 1 &&
                                        localUser?.settings?.web3 === 1 && (
                                          <Topupbutton
                                            LoadAmount={real_amount}
                                            LoadAmountNet={net_topup}
                                            LoadAmountFCF={toFcf}
                                            onTopUpSuccess={handleTopUpSuccess}
                                            onStatusChange={handleStatusChange}
                                            settopuperror={handlesettopuperror}
                                            dailylimitexceeded={isdailylimit}
                                          />
                                        )}
                                      {chain?.id !== 56 &&
                                        localUser?.settings?.MATIC === 1 &&
                                        localUser?.settings?.web3 === 1 && (
                                          <Topupbutton
                                            LoadAmount={real_amount}
                                            LoadAmountNet={net_topup}
                                            LoadAmountFCF={toFcf}
                                            onTopUpSuccess={handleTopUpSuccess}
                                            onStatusChange={handleStatusChange}
                                            settopuperror={handlesettopuperror}
                                            dailylimitexceeded={isdailylimit}
                                          />
                                        )}
                                      {/* <Topupbutton
                                        LoadAmount={real_amount}
                                        LoadAmountNet={net_topup}
                                        LoadAmountFCF={toFcf}
                                        onTopUpSuccess={handleTopUpSuccess}
                                        onStatusChange={handleStatusChange}
                                        settopuperror={handlesettopuperror}
                                        dailylimitexceeded={isdailylimit}
                                      /> */}
                                      {/* <button
                                        className="btn btn-light"
                                        onClick={createWeb3API}
                                        disabled={isLoading}
                                        // style={{ height: "40px" }}
                                      >
                                        {isLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                          ></Spinner>
                                        ) : (
                                          "TopUp"
                                        )}
                                      </button> */}
                                    </>
                                  ) : (
                                    <Topupbutton
                                      LoadAmount={real_amount}
                                      LoadAmountNet={net_topup}
                                      LoadAmountFCF={toFcf}
                                      onTopUpSuccess={handleTopUpSuccess}
                                      onStatusChange={handleStatusChange}
                                      settopuperror={handlesettopuperror}
                                      dailylimitexceeded={isdailylimit}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                                // <button
                                //   onClick={handleLoadClick}
                                //   className="btn btn-light"
                                //   type="button"
                                //   disabled={LoadAmount === 0 ? true : false}
                                // >
                                //   {isLoading ? (
                                //     <Spinner
                                //       animation="border"
                                //       size="sm"
                                //       role="status"
                                //       aria-hidden="true"
                                //       className="me-2"
                                //     ></Spinner>
                                //   ) : (
                                //     <div>TopUp</div>
                                //   )}
                                // </button>
                              )}
                            </div>{" "}
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="input-group mt-4">
                              <button
                                className="btn btn-light ml-2 p-2"
                                onClick={handleShowPinDialog}
                              >
                                <i
                                  className={`fa-solid fa-ellipsis ${
                                    showpinloading ? "fa-spin" : ""
                                  }`}
                                  style={{ fontSize: "25px" }}
                                ></i>
                              </button>
                            </div>
                          </div>

                          <Modal
                            show={showPinDialog}
                            onHide={handleClosePinDialog}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Pin Management</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                              {/* <p>Select an option:</p> */}
                              {/* <div>
                                <Button
                                  variant="primary"
                                  onClick={handleShowPin}
                                >
                                  Show Pin
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleChangePin}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Change Pin
                                </Button>
                              </div> */}
                              {isChangePin ? (
                                // Display change PIN form
                                <div className="text-center">
                                  <h4>Enter your new PIN:</h4>
                                  <InputGroup
                                    className="mb-3"
                                    style={{
                                      maxWidth: "150px",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <FormControl
                                      placeholder="New PIN"
                                      value={newPin}
                                      onChange={(e) =>
                                        setNewPin(e.target.value)
                                      }
                                    />
                                  </InputGroup>
                                  <Button
                                    variant="primary"
                                    onClick={handleSubmitNewPin}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      "Submit New Pin"
                                    )}
                                  </Button>
                                </div>
                              ) : (
                                // Show the "Show Pin" and "Change Pin" buttons if not changing PIN
                                <div>
                                  {/* <p>Select an option:</p> */}
                                  {showpinloading ? (
                                    <Spinner animation="border" />
                                  ) : pin ? (
                                    <div>
                                      <h5>Your PIN: {pin}</h5>
                                    </div>
                                  ) : (
                                    <div>
                                      <Button
                                        variant="primary"
                                        onClick={handleShowPin}
                                      >
                                        Show Pin
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={handleChangePin}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Change Pin
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={handleClosePinDialog}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <>
                            {Number(LoadAmount) > Number(limitleft) && (
                              <h4 className="fs-14 text-danger mb-0">
                                Daily limit exceeded.
                              </h4>
                            )}

                            {topuperror !== "" && (
                              <h4 className="fs-14 text-danger mb-0">
                                You don´t have enough USDT on your wallet.
                              </h4>
                            )}
                            {maintenance !== "" && (
                              <>
                                {localUser?.settings?.BSC === 0 &&
                                localUser?.settings?.MATIC === 0 ? (
                                  <>
                                    <h4 className="fs-14 text-danger mb-0">
                                      {maintenance}
                                    </h4>
                                  </>
                                ) : (
                                  <>
                                    {localUser?.settings?.web3 === 1 ? (
                                      <>
                                        {localUser?.settings?.BSC === 0 &&
                                          localUser?.settings?.MATIC === 0 && (
                                            <>
                                              <h4 className="fs-14 text-danger mb-0">
                                                USDT BEP20 Card loads currently
                                                unavailable due to bank
                                                maintenance. Will resume
                                                shortly.
                                              </h4>
                                            </>
                                          )}
                                        {localUser?.settings?.BSC === 0 &&
                                          localUser?.settings?.MATIC === 1 && (
                                            <>
                                              <h4 className="fs-14 text-danger mb-0">
                                                USDT BEP20 Card loads currently
                                                unavailable due to bank
                                                maintenance. Will resume
                                                shortly. <br></br>Please use
                                                USDT POLYGON.
                                              </h4>
                                            </>
                                          )}
                                        {localUser?.settings?.MATIC === 0 &&
                                          localUser?.settings?.BSC === 0 && (
                                            <>
                                              <h4 className="fs-14 text-danger mb-0">
                                                USDT POLYGON Card loads
                                                currently unavailable due to
                                                bank maintenance. Will resume
                                                shortly.
                                              </h4>
                                            </>
                                          )}
                                        {localUser?.settings?.MATIC === 0 &&
                                          localUser?.settings?.BSC === 1 && (
                                            <>
                                              <h4 className="fs-14 text-danger mb-0">
                                                USDT POLYGON Card loads
                                                currently unavailable due to
                                                bank maintenance. Will resume
                                                shortly. <br></br>Please use
                                                USDT BEP20.
                                              </h4>
                                            </>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {address ? (
                                          <h4 className="fs-14 text-danger mb-0">
                                            WEB3 Card loads currently
                                            unavailable due to bank maintenance.
                                            Will resume shortly. Please
                                            disconnect wallet and generate
                                            address.
                                          </h4>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        </div>
                      </div>{" "}
                      {(localUser?.cards?.card_type === 1 ||
                        localUser?.cards?.card_type === 2) && (
                        <Accordion
                          className="accordion accordion-primary-solid mt-4"
                          defaultActiveKey="0"
                          style={{ maxWidth: "350px", margin: "0px auto" }}
                        >
                          <Accordion.Item eventKey={1}>
                            <Accordion.Header className="accordion-header fs-20">
                              Daily Top Up Limit
                            </Accordion.Header>
                            <Accordion.Collapse
                              eventKey={1}
                              className="accordion__body fs-16 text-black"
                            >
                              <div className="accordion-body text-center">
                                {dailytopups} USDT /{" "}
                                {loadinglimit[localUser?.cards?.card_type][0]}{" "}
                                USDT
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3 mt-md-5">
                  <Col xl="8">
                    <div className="row">
                      <Col xl="8" sm="8" lg="6">
                        <Card>
                          {net_topup != 0 &&
                            (localUser?.settings?.BSC === 1 ||
                              localUser?.settings?.MATIC === 1) && (
                              <Card.Body
                                className="d-flex text-center"
                                style={{
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <>
                                  <>
                                    {userWallet !== "" ? (
                                      <>
                                        <p className="fs-18 mb-2 text-black">
                                          {status}
                                        </p>
                                        <p className="fs-18 mb-1 text-black">
                                          Fee:{" "}
                                          {(
                                            loadingfees[
                                              localUser?.cards?.card_type
                                            ][0] * 100
                                          ).toFixed(2)}
                                          % + $
                                          {
                                            loadingfees[
                                              localUser?.cards?.card_type
                                            ][1]
                                          }
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <p className="fs-18 mb-3 text-black">
                                      NET TOP UP: ${net_topup}
                                    </p>
                                  </>
                                  {userWallet === "" ? (
                                    <div
                                      className="col-lg-12 d-flex"
                                      style={{
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label className="text-black font-w600 ">
                                        Select Blockchain
                                      </label>
                                      <div
                                        className="row"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row", // Change from 'column' to 'row'
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div className="col-6">
                                          <button
                                            value="bep20"
                                            onClick={(e) =>
                                              handlesetNetwork("bep20")
                                            }
                                            className="btn btn-primary mt-3 m-1"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              width: "max-content",
                                            }}
                                            //   disabled={network && network === "bep20"}
                                            disabled={
                                              isLoadingTransactionBSC ||
                                              isLoadingTransactionMATIC ||
                                              localUser?.settings?.BSC === 0 ||
                                              localUser?.settings?.api === 0
                                            }
                                          >
                                            {isLoadingTransactionBSC ? (
                                              <>
                                                <Spinner
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                  className="me-2"
                                                ></Spinner>
                                                USDT BEP20
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                                                  alt="network"
                                                  style={{
                                                    marginRight: "10px",
                                                    width: "25px",
                                                  }}
                                                />
                                                USDT BEP20
                                              </>
                                            )}
                                          </button>
                                        </div>
                                        <div className="col-6">
                                          <button
                                            value="matic"
                                            onClick={(e) =>
                                              handlesetNetwork("matic")
                                            }
                                            className="btn btn-primary mt-3 m-1"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              width: "max-content",
                                            }}
                                            //   disabled={network && network === "matic"}
                                            disabled={
                                              isLoadingTransactionBSC ||
                                              isLoadingTransactionMATIC ||
                                              localUser?.settings?.MATIC ===
                                                0 ||
                                              localUser?.settings?.api === 0
                                            }
                                          >
                                            {isLoadingTransactionMATIC ? (
                                              <>
                                                <Spinner
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                  className="me-2"
                                                ></Spinner>
                                                USDT POLYGON
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                                                  alt="network"
                                                  style={{
                                                    marginRight: "10px",
                                                    width: "25px",
                                                  }}
                                                />
                                                USDT POLYGON
                                              </>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="d-flex flex-column">
                                    {net_topup != 0 && (
                                      <>
                                        {success_message ? (
                                          <div
                                            className="alert alert-warning"
                                            role="alert"
                                          >
                                            Transaction Confirmed. The card
                                            balance loading can take up to 5
                                            minutes. Please be patient and do
                                            not reload this page.
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {createdAddress && (
                                    <>
                                      <label className="text-black font-w600 ">
                                        Address
                                      </label>
                                      <div className="input-group mb-3 mt-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="0x00000000000000"
                                          value={createdAddress}
                                          readOnly
                                        />

                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{ height: "40px" }}
                                          onClick={() => {
                                            navigator.clipboard
                                              .writeText(createdAddress)
                                              .then(() =>
                                                alert(
                                                  "Address copied to clipboard!"
                                                )
                                              )
                                              .catch((error) =>
                                                console.error(
                                                  "Could not copy text: ",
                                                  error
                                                )
                                              );
                                          }}
                                        >
                                          COPY
                                        </button>
                                      </div>
                                      <label className="text-black font-w600 ">
                                        Amount
                                      </label>
                                      <div className="text-danger font-w600">
                                        Please send the exact amount to the
                                        address!
                                      </div>
                                      <div
                                        className="input-group mb-3 mt-3"
                                        style={{ maxWidth: "250px" }}
                                      >
                                        {" "}
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{ height: "40px" }}
                                          disabled
                                        >
                                          USDT
                                        </button>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0"
                                          value={LoadAmount}
                                          readOnly
                                        />
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{ height: "40px" }}
                                          onClick={() => {
                                            navigator.clipboard
                                              .writeText(LoadAmount)
                                              .then(() =>
                                                alert(
                                                  "Amount copied to clipboard!"
                                                )
                                              )
                                              .catch((error) =>
                                                console.error(
                                                  "Could not copy text: ",
                                                  error
                                                )
                                              );
                                          }}
                                        >
                                          COPY
                                        </button>
                                      </div>
                                      <div className="text-danger font-w600">
                                        Sending funds on any other blockchain
                                        will result in the loss of your funds.
                                      </div>
                                      {qrCode && (
                                        <div
                                          className="d-flex mb-3"
                                          style={{ justifyContent: "center" }}
                                        >
                                          <img
                                            src={`data:image/png;base64,${qrCode}`}
                                            alt="QR Code"
                                            style={{ maxWidth: "220px" }}
                                          />
                                        </div>
                                      )}
                                      {isLoadingTransaction ? (
                                        <>
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                          />
                                          {txn_hash ? (
                                            <>
                                              <label className="text-black font-w600 mt-3">
                                                Transaction Hash
                                              </label>
                                              <label className="text-black font-w600 mt-3">
                                                {txn_hash.substring(0, 15)}...
                                              </label>
                                              <label className="text-black font-w600 mt-3">
                                                Confirmations : {confirmations}
                                              </label>
                                            </>
                                          ) : (
                                            <>
                                              <label className="text-black font-w600 mt-3">
                                                Waiting for Payment
                                              </label>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <div
                                          className="alert alert-warning"
                                          role="alert"
                                        >
                                          Transaction Confirmed. The card
                                          balance loading can take up to 5
                                          minutes. Please be patient and do not
                                          reload this page.
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              </Card.Body>
                            )}

                          {/* <Card.Footer className=" border-0 pt-0">
                    <Card.Text className=" d-inline">Card footer</Card.Text>
                  </Card.Footer> */}
                        </Card>
                      </Col>
                    </div>
                  </Col>
                </div>

                <div>
                  <Col>
                    <Card>
                      <div className="card-header border-0 pb-0">
                        <ul className="nav nav-tabs card-header-tabs">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                activeTab === "transactions" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("transactions")}
                            >
                              Spendings
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                activeTab === "deposits" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("deposits")}
                            >
                              Deposits
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <Card.Body>
                          {activeTab === "transactions" && (
                            <>
                              <div className="tab-pane fade show active">
                                <Table responsive>
                                  {/* <thead>
                                    <tr className="text-black text-weight-600">
                                      <th>Description</th>
                                      <th>Amount</th>
                                      <th>Date</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead> */}
                                  <tbody>
                                    {currentItems.length > 0 ? (
                                      currentItems.map((transaction) => (
                                        <tr
                                          className="text-black"
                                          key={transaction.id}
                                        >
                                          <td className="text-black">
                                            {transaction.description}
                                          </td>
                                          <td
                                            className="text-black"
                                            style={{ width: "110px" }}
                                          >
                                            -
                                            {Number(transaction.amount).toFixed(
                                              2
                                            )}{" "}
                                            USD
                                          </td>
                                          <td className="text-black">
                                            {transaction.date}
                                          </td>
                                          <td className="text-success">
                                            Completed
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td
                                          className="text-black text-center"
                                          colSpan="4"
                                        >
                                          No Transactions available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>{" "}
                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    className="btn btn-primary m-2"
                                    onClick={prevPage}
                                    disabled={currentPage === 1} // disable button when on first page
                                  >
                                    <i className="fa-solid fa-chevron-left"></i>
                                  </button>
                                  <button
                                    className="btn btn-primary m-2"
                                    onClick={nextPage}
                                    disabled={
                                      currentPage ===
                                      Math.ceil(
                                        localUser?.transactions.length /
                                          itemsPerPage
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}

                          {activeTab === "deposits" && (
                            <>
                              <Table responsive>
                                {/* <thead>
                                  <tr className="text-black text-weight-600">
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                  </tr>
                                </thead> */}
                                <tbody>
                                  {currentItemsDeposits.map((transaction) => (
                                    <tr
                                      className="text-black"
                                      key={transaction.id}
                                    >
                                      <td className="text-black">
                                        {transaction.description}
                                      </td>
                                      <td
                                        className="text-black"
                                        style={{ width: "130px" }}
                                      >
                                        +{Number(transaction.amount).toFixed(2)}{" "}
                                        USDT
                                      </td>
                                      <td className="text-black">
                                        {transaction.date}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>{" "}
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  className="btn btn-primary m-2"
                                  onClick={prevPageDeposit}
                                  disabled={currentPageDeposit === 1}
                                >
                                  <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <button
                                  className="btn btn-primary m-2"
                                  onClick={nextPageDeposit}
                                  disabled={
                                    currentPageDeposit ===
                                    Math.ceil(
                                      localUser?.transactionstopup.length /
                                        itemsPerPage
                                    )
                                  }
                                >
                                  <i className="fa-solid fa-chevron-right"></i>
                                </button>
                              </div>
                            </>
                          )}
                        </Card.Body>
                      </div>
                    </Card>
                  </Col>
                </div>

                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">{/* <HomeTab /> */}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                      <div className="card-bx " style={{ minWidth: "270px" }}>
                        <img
                          src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/a9354c5a-e6d4-47e1-ea4b-dc3505089800/public"
                          alt=""
                          className="mw-100 "
                          style={{
                            height: "auto",
                            minHeight: "270px",
                          }}
                        />
                        <div className="card-info text-white">
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="mb-1">Card Balance</p>
                            <p className="mb-1 text-danger font-w600">
                              Inactive
                            </p>
                          </div>
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2 className="fs-36 text-white mb-sm-2 mb-3">
                              $0.00
                            </h2>
                          </div>

                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          ></div>

                          <div
                            className="d-flex align-items-center mb-sm-2 mb-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <span className="fs-14 text-danger mb-0"></span>

                            <h4 className="fs-20 text-white mb-0"></h4>
                          </div>

                          {LoadAmount < minAmount ? (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "space-between" }}
                            >
                              <span className="fs-14 text-danger mb-0">
                                {/* Min. Amount {minAmount} */}
                              </span>

                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "end" }}
                            >
                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          )}

                          {localUser?.shipping?.cardKitNumber !== "200000" &&
                            !success_activate && (
                              <button
                                onClick={handleActivateCard}
                                className="btn btn-light"
                                type="button"
                                // disabled={LoadAmount === 0 ? true : false}
                              >
                                {/* {isLoadingActivate ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                ></Spinner>
                              ) : ( */}
                                <div>ASSIGN CARD</div>
                                {/* )} */}
                              </button>
                            )}
                          {error_activate && (
                            <>
                              <Alert
                                variant="danger"
                                className="text-center mt-1"
                              >
                                <strong>Error! </strong>
                                Please contact support.
                              </Alert>
                            </>
                          )}

                          {success_activate && (
                            <>
                              <Alert
                                variant="success"
                                className="text-center mt-1"
                              >
                                <strong>Success! </strong> Card activated.
                              </Alert>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                  <div
                    className="card-bx stacked"
                    style={{ minWidth: "270px" }}
                  >
                    <img
                      src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/a9354c5a-e6d4-47e1-ea4b-dc3505089800/public"
                      alt=""
                      className="mw-100 "
                      style={{
                        height: "auto",
                        minHeight: "270px",
                        maxHeight: "350px",
                      }}
                    />
                    <div className="card-info text-white">
                      <div
                        className="d-flex align-items-center mb-sm-1 mb-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p className="mb-1">Card Balance</p>
                        <p className="mb-1 text-danger font-w600">Inactive</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-sm-1 mb-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h2 className="fs-36 text-white mb-sm-2 mb-3">$0.00</h2>
                      </div>

                      {LoadAmount < minAmount ? (
                        <div
                          className="d-flex align-items-center mb-sm-2 mb-2"
                          style={{ justifyContent: "end" }}
                        >
                          <h4 className="fs-20 text-white mb-0">
                            **** **** **** {issuer_Last4}
                          </h4>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center mb-sm-2 mb-2"
                          style={{ justifyContent: "end" }}
                        >
                          <h4 className="fs-20 text-white mb-0">
                            **** **** **** {issuer_Last4}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

const loadingScreenStyle = {
  position: "fixed",
  fontSize: "1.4rem",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

export default Home;
