import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Alert,
  Pagination,
  CircularProgress,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { differenceInHours } from "date-fns";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useAccount,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { usdt_abi } from "../../../src/contracts/USDT_ABI.js";
import Web3 from "web3";

const bnblogo =
  "https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png";
const maticlogo = "https://cryptologos.cc/logos/polygon-matic-logo.png";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const POLYGON_USDT_CONTRACT = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";

const MANAGE_CARDS = () => {
  const { address, connector: activeConnector, isConnected } = useAccount();

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const { chain } = useNetwork();
  const {
    chains,
    error,
    pendingChainId,
    isLoading: changechainloading,
    switchNetwork,
  } = useSwitchNetwork();

  const cardTypes = { Standard: 1, Premium: 2, VIP: 3 };

  const [reload, setReload] = useState("");
  const [userplans, setUserplans] = useState([]);
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserPlan, setSelectedUserPlan] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cardDetails, setCardDetails] = useState("");
  const [cardKitNumbers, setCardKitNumbers] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogShipping, setOpenDialogShipping] = useState(false);
  const [newCHID, setNewCHID] = useState("");
  const [openAlert, setAlert] = useState("");
  const [deliveryLinkDHL, setDeliveryLinkDHL] = useState(null);
  const [deliveryLinkDHLExpress, setDeliveryLinkDHLExpress] = useState(null);
  const [loadingpush, setLoadingPush] = useState(false);
  const [successfulSend, setSuccessfulSend] = useState(false);
  const [loadingcidchange, setLoadingCidChange] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCards, setTotalCards] = useState(0);
  const [searchResult, setSearchResult] = useState(null);
  const [filterKyc, setFilterKyc] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingDetailsCHID, setisLoadingDetailsCHID] = useState(false);
  const [filterKitNumber, setFilterKitNumber] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const [cardKitNumberInput, setCardKitNumberInput] = useState(200000);
  const [cardKitNumberInputShipping, setCardKitNumberInputShipping] =
    useState(200000);
  const [loadingAssignCard, setLoadingAssignCard] = useState(false);
  const [loadingSaveCardkit, setLoadingSaveCardkit] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [openAssignSaveDialog, setOpenAssignSaveDialog] = useState(false);

  const [kycStatusLoading, setKycStatusLoading] = useState(false);
  const [kycStatus, setKycStatus] = useState(null);
  const [kycStatuses, setKycStatuses] = useState({});
  const [successfulAssign, setsuccessfulAssign] = useState(false);
  const [errorAssign, seterrorAssign] = useState("");
  const [statusAssign, setStatusAssign] = useState("Waiting to assign...");
  const [statusSave, setstatusSave] = useState("Waiting to save...");

  const [users, setUsers] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [filteredWithdrawals, setFilteredWithdrawals] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [withdrawalToProcess, setWithdrawalToProcess] = useState(null);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalCurrentPage, setwithdrawalCurrentPage] = useState(1);
  const [withdrawalPerPage, setwithdrawalPerPage] = useState(10);
  const [totalWithdrawals, settotalWithdrawals] = useState(0);

  const [filterShippingDetails, setFilterShippingDetails] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const [mailsuccess, setMailSuccess] = useState(false);
  const [mailerror, setmailerror] = useState(false);

  const cardTypeMapping = {
    1: "Standard Card",
    2: "Premium Card",
    3: "VIP Card",
  };
  //Admin Functions
  const [createVirtualInputs, setcreateVirtualInputs] = useState({
    userId: "",
    UUID: "",
  });

  const [splitVirtualInputs, setSplitVirtualInputs] = useState({
    userId: "",
    txHash: "",
    amount: "",
    splitAmount: "",
    currency: "",
  });

  const [splitTopupInputs, setSplitTopupInputs] = useState({
    userId: "",
    txHash: "",
    amount: "",
    splitAmount: "",
    currency: "",
  });

  const [cardBuyInputs, setCardBuyInputs] = useState({
    userId: "",
    cardId: "",
    currency: "",
    txHash: "",
  });
  const [statusvirtualcreate, setstatusvirtualcreate] = useState(
    "Waiting for input..."
  );
  const [statusvirtualsplit, setstatusvirtualsplit] = useState(
    "Waiting for input..."
  );
  const [statussplittopup, setstatussplittopup] = useState(
    "Waiting for input..."
  );
  const [statuscardbuy, setstatuscardbuy] = useState("Waiting for input...");

  let chainid;
  let chainname;
  let usdt_contract;
  if (chain?.id) {
    chainid = chain.id;
    if (chainid === 137) {
      chainname = "matic_usdt";
      usdt_contract = POLYGON_USDT_CONTRACT;
    } else if (chainid === 56) {
      chainname = "bsc_usdt";
      usdt_contract = BSC_USDT_CONTRACT;
    }
  }

  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchWithdrawals();
  }, []);

  useEffect(() => {
    const handleConnectorUpdate = (account, chain) => {
      if (account) {
        console.log("new account", account);
        window.location.reload(); // Reload the page when a new account is detected.
      } else if (chain) {
        console.log("new chain", chain);
      }
    };

    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  useEffect(() => {
    console.log("changechainloading", changechainloading);
  }, [changechainloading]);

  useEffect(() => {
    applyWithdrawalFilter();
  }, [withdrawalCurrentPage]);

  useEffect(() => {
    applyUserFilters();
  }, [users, searchUserTerm, userCurrentPage]);

  useEffect(() => {
    applyFilters();
  }, [
    cards,
    searchTerm,
    filterShippingDetails,
    filterKyc,
    filterKitNumber,
    currentPage,
    perPage,
  ]);

  const handleInputChange = (event, setInputs) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.bridge-bit.com/api/getcards`
      );
      console.log("API response:", response);
      setCards(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const response = await axios.get(
        `https://api.bridge-bit.com/api/withdrawals`
      );
      console.log("Withdrawals API response:", response.data);
      setWithdrawals(response.data);
      settotalWithdrawals(response.data.length);
      let filtered = response.data;
      setFilteredWithdrawals(
        filtered.slice(
          (withdrawalCurrentPage - 1) * withdrawalPerPage,
          withdrawalCurrentPage * withdrawalPerPage
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const response = await axios.get(`https://api.bridge-bit.com/api/users`);
      console.log("Users API response:", response.data);
      setUsers(response.data);
      setIsLoadingUsers(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setIsLoadingUsers(false);
    }
  };

  const applyWithdrawalFilter = () => {
    let filtered = withdrawals;
    setFilteredWithdrawals(
      filtered.slice(
        (withdrawalCurrentPage - 1) * withdrawalPerPage,
        withdrawalCurrentPage * withdrawalPerPage
      )
    );
  };

  const applyUserFilters = () => {
    let filtered = users;
    if (searchUserTerm) {
      filtered = filtered.filter((user) => {
        const email = user.email ? user.email.toString() : "";
        const username = user.username ? user.username.toString() : "";
        const name = user.name ? user.name.toString() : "";
        const chid = user.cardHolderId ? user.cardHolderId.toString() : "";
        const inbanx = user.virtual_type ? user.virtual_type.toString() : "";
        return (
          email.includes(searchUserTerm) ||
          username.includes(searchUserTerm) ||
          name.includes(searchUserTerm) ||
          chid.includes(searchUserTerm) ||
          inbanx.includes(searchUserTerm)
        );
      });
    }
    setTotalUsers(filtered.length);
    setFilteredUsers(
      filtered.slice(
        (userCurrentPage - 1) * userPerPage,
        userCurrentPage * userPerPage
      )
    );
  };

  const handleUserSearchChange = (event) => {
    setSearchUserTerm(event.target.value);
  };

  const handleUserPageChange = (event, value) => {
    setUserCurrentPage(value);
  };

  const handleWithdrawalPageChange = (event, value) => {
    setwithdrawalCurrentPage(value);
  };

  const handleSendTrackingMail = async (row) => {
    console.log("handleSendTrackingMail", row);
    console.log("DHL Tracking number", deliveryLinkDHL);
    console.log("DHL Express Tracking number", deliveryLinkDHLExpress);
    console.log("CardKitNumber", cardKitNumberInputShipping);
    if (!deliveryLinkDHL && !deliveryLinkDHLExpress) {
      setmailerror(true);
    }

    const userConfirmation = window.confirm(
      "Are you sure you want to proceed with the request?"
    );
    if (userConfirmation) {
      setLoadingMail(true);
      try {
        const response = await axios.post(
          "https://api.bridge-bit.com/api/send-tracking-mail",
          {
            user_id: row.user_id,
            tracking_number: deliveryLinkDHL,
            tracking_number_express: deliveryLinkDHLExpress,
            cardKitNumber: cardKitNumberInputShipping,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log("API Response:", response.data);
        if (response.data.message === "send") {
          setDeliveryLinkDHL(null);
          setDeliveryLinkDHLExpress(null);
          setLoadingMail(false);
          setMailSuccess(true);
        }
      } catch (error) {
        setmailerror(true);
      }
    }
  };

  const fetchFirstKycAcceptedUser = async (row) => {
    const userConfirmation = window.confirm(
      "Are you sure you want to proceed with the request?"
    );

    if (userConfirmation) {
      setisLoadingDetailsCHID(true);
      setSelectedRow(row);
      try {
        const response = await axios.post(
          "https://api.bridge-bit.com/api/first-kyc-accepted-user",
          { card_holder_id: row.cardholdersId },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log("Card Details Received:", response.data);
        setUserData(response.data);
        setNewCHID(Number(response.data.cardHolderId));
        // setOpenUserDialog(true);
        setisLoadingDetailsCHID(false);
        return Number(response.data.cardHolderId);
      } catch (error) {
        console.error("Error fetching user:", error);
        return null;
      }
    } else {
      console.log("Request cancelled by user.");
      return null;
    }
  };

  const applyFilters = () => {
    let filtered = cards;
    if (searchTerm) {
      filtered = filtered.filter((card) => {
        const name = card.name ? card.name.toString() : "";
        const cardholdersId = card.cardholdersId
          ? card.cardholdersId.toString()
          : "";
        return name.includes(searchTerm) || cardholdersId.includes(searchTerm);
      });
    }
    if (filterKyc) {
      filtered = filtered.filter((card) => card.isKycAccepted === "false");
    }

    if (filterKitNumber) {
      filtered = filtered.filter((card) => card.cardKitNumber === null);
    }
    if (filterShippingDetails) {
      filtered = filtered.filter(
        (card) => card.shipping_info !== null && card.cardKitNumber === null
      );
      console.log("filtered", filtered);
      filtered.sort((a, b) => {
        const idA = a.shipping_info.id;
        const idB = b.shipping_info.id;
        return idA - idB; // This line compares idA and idB to sort in ascending order.
      });
    }

    setTotalCards(filtered.length);
    setFilteredCards(
      filtered.slice((currentPage - 1) * perPage, currentPage * perPage)
    );
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    applyFilters();
  };

  const handleSearchInDialog = () => {
    const result = cards.find((card) => card.cardholdersId === newCHID);
    setSearchResult(result || null);
  };

  const handleFilterKycClick = () => {
    setFilterKyc(!filterKyc);
  };

  const handleChangeCHID = async () => {
    try {
      setLoadingCidChange(true);
      console.log("New CHID:", newCHID);
      console.log("Selected Row:", selectedRow);

      const dataToSend = {
        card_holder_id: selectedRow.cardholdersId,
        new_chid: newCHID,
        user_id: selectedRow.user_id,
      };
      console.log("Data to send:", dataToSend);

      //   const response = await axios.post(
      //     "https://api.bridge-bit.com/api/change-chid",
      //     {
      //       card_holder_id: selectedRow.cardholdersId,
      //       new_chid: newCHID,
      //       user_id: selectedRow.user_id,
      //     },
      //     { headers: { "Content-Type": "application/json" } }
      //   );

      //   if (response.data.message === "saved") {
      //     setAlert(true);
      //     setLoadingCidChange(false);
      //   } else {
      //     setLoadingCidChange(false);
      //   }
    } catch (error) {
      setLoadingCidChange(false);
      console.error("Error changing CHID:", error);
    }
  };

  const checkKycStatus = async (cardHolderId) => {
    setKycStatusLoading(true);

    try {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/physical-cards/kyc/status",
        {
          card_holder_id: cardHolderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      console.log("API response:", response);
      setCardDetails(JSON.stringify(response.data, null, 2));

      if (response.data.success) {
        setKycStatuses((prevStatuses) => ({
          ...prevStatuses,
          [cardHolderId]: response.data.kyc_status ? "REAL" : "FALSE",
        }));
      } else {
        setKycStatuses((prevStatuses) => ({
          ...prevStatuses,
          [cardHolderId]: "FALSE",
        }));
      }
    } catch (error) {
      setKycStatuses((prevStatuses) => ({
        ...prevStatuses,
        [cardHolderId]: "FALSE",
      }));
    }

    setKycStatusLoading(false);
  };

  const handleRefreshBalance = async (row) => {
    setSelectedRow(row);
    console.log("handleRefreshBalance", row?.cardKitNumber);

    if (row?.cardKitNumber) {
      try {
        const response = await axios.post(
          "https://api.bridge-bit.com/api/getBalanceByUser",
          {
            cardKitNumber: row.cardKitNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        console.log(" ", response);

        if (response.data.success === true) {
          setCards((prev) =>
            prev.map((card) =>
              card.balance === row
                ? { ...card, balance: response.data.message }
                : card
            )
          );
        } else {
          if (
            response.data.message === "Failed to retrieve balance" ||
            response.data.message === "Something went wrong"
          ) {
            setCards((prev) =>
              prev.map((card) =>
                card.balance === row
                  ? { ...card, balance: response.data.message }
                  : card
              )
            );
          }
          setCards((prev) =>
            prev.map((card) =>
              card.balance === row
                ? { ...card, balance: response.data.message }
                : card
            )
          );
        }

        if (!response.status === 200) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.log(error); // log the error to the console for debugging

        if (localUser) {
          return localUser.cards.balance;
        }
        // Handle the error based on the error message
        if (error.response && error.response.status === 500) {
          // Server error, handle it as per the requirement
        } else if (error.request) {
          // The request was made but no response was received
          console.log("The request was made but no response was received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      }
    }
  };

  const handleOpenDialogShipping = (row) => {
    setSelectedRow(row);
    setOpenDialogShipping(true);
    setMailSuccess(false);
    setmailerror(false);
  };

  const handleCloseDialogShipping = () => {
    setOpenDialogShipping(false);
    setMailSuccess(false);
    setmailerror(false);
  };

  const handleOpenDialog = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchCardDetails = async (cardHolderId) => {
    setIsLoadingDetails(true);
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/cards/get-details",
        { card_holder_id: cardHolderId },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Card Details Received:", response.data);
      setCardDetails(JSON.stringify(response.data, null, 2));
      const cardKitNumber = response.data.data.cards[0].cardKitNumber;
      console.log("cardKitNumber:", cardKitNumber);

      setCards((prev) =>
        prev.map((card) =>
          card.cardholdersId === cardHolderId
            ? { ...card, cardKitNumber }
            : card
        )
      );
    } catch (error) {
      console.error("Error fetching card details:", error);
      setCardDetails("Failed to fetch details");
      setCardKitNumbers((prev) => ({
        ...prev,
        [cardHolderId]: "Failed to fetch",
      }));
    }
    setIsLoadingDetails(false);
  };

  const handleOpenAssignSaveDialog = (row) => {
    console.log("handleOpenAssignSaveDialog Selected Row:", row);
    setSelectedRow(row);
    setOpenAssignSaveDialog(true);
  };

  const handleCloseAssignSaveDialog = () => {
    setOpenAssignSaveDialog(false);
  };

  const handleOpenAssignDialog = (row) => {
    console.log("handleOpenAssignDialog Selected Row:", row);
    setSelectedRow(row);
    setOpenAssignDialog(true);
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const handleSaveCardkit = async () => {
    console.log("Selected Row:", selectedRow);
    setLoadingSaveCardkit(true);
    try {
      setstatusSave("Saving Card...");
      const response = await axios.post(
        "https://api.bridge-bit.com/api/save_cardkit",
        {
          user_id: selectedRow.user_id,
          cardKitNumber: cardKitNumberInput,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );
      // const response = [];
      console.log("API response assign-bulk-card:", response);
      setstatusSave("Saved!");
      setLoadingSaveCardkit(false);
      setCardKitNumberInput(200000);
      //reload page
      window.location.reload();
    } catch (error) {
      console.error("Error assigning cardkit:", error);
      setstatusSave("Failed to save cardkit");
      setLoadingSaveCardkit(false);
    }
  };

  const handleAssignCard = async () => {
    setLoadingAssignCard(true);
    console.log("Selected Row:", selectedRow);
    console.log("KYC Status:", kycStatuses[selectedRow.cardholdersId]);

    if (kycStatuses[selectedRow.cardholdersId] === "FALSE") {
      setStatusAssign("Need to fake KYC...");
      try {
        const new_chid = await fetchFirstKycAcceptedUser(selectedRow);
        // const new_chid = 12345;
        setStatusAssign("New CHID:".new_chid);
        kycStatuses[new_chid] = "REAL";
        selectedRow.cardholdersId = new_chid;
        await handleAssignCard();
      } catch (error) {
        console.error("Failed to fetch first KYC accepted user:", error);
        setStatusAssign("Failed to fake KYC...");
      }
    } else {
      try {
        setStatusAssign("Assign Card...");
        const response = await axios.post(
          "https://dashboard.croesus-asset.management/api/physical-cards/assign-bulk-card",
          {
            card_holder_id: selectedRow.cardholdersId,
            CardKitNumber: cardKitNumberInput,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
            },
          }
        );
        // const response = [];
        console.log("API response assign-bulk-card:", response);

        // Handle the response as needed
        if (
          response?.data?.success == true ||
          response?.data?.success == "true" ||
          response?.data?.message == "CardKitNumber has already been assigned."
        ) {
          setStatusAssign(
            "Assign Card sucessfull. Waiting 60 seconds for activation..."
          );
          console.log("Card assigned successfully:", response.data);

          setTimeout(async () => {
            setStatusAssign("Activating Card First Time...");
            const response = await axios.post(
              "https://dashboard.croesus-asset.management/api/physical-cards/activate-card",
              {
                card_number: cardKitNumberInput,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization:
                    "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
                },
              }
            );
            console.log("API response activate:", response);

            if (response) {
              setTimeout(async () => {
                setStatusAssign("Activating Card Second Time...");
                const response = await axios.post(
                  "https://dashboard.croesus-asset.management/api/physical-cards/activate-card",
                  {
                    card_number: cardKitNumberInput,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization:
                        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
                    },
                  }
                );
                console.log("API response activate:", response);

                if (
                  response.data.success == true ||
                  response.data.success == "true"
                ) {
                  setStatusAssign("Card activated successfully");
                  setLoadingAssignCard(false);
                  setOpenAssignDialog(false);
                  setsuccessfulAssign(true);

                  //   fetchData();
                  const cardKitNumber = cardKitNumberInput;
                  setCards((prev) =>
                    prev.map((card) =>
                      card.cardholdersId === selectedRow.cardholdersId
                        ? { ...card, cardKitNumber }
                        : card
                    )
                  );

                  setStatusAssign("Getting Card Details...");
                  try {
                    const response = await axios.post(
                      "https://api.bridge-bit.com/api/cards/get-details",
                      { card_holder_id: selectedRow.cardholdersId },
                      { headers: { "Content-Type": "application/json" } }
                    );
                    console.log("Card Details Received:", response.data);
                    setStatusAssign("Card details received and saved...");
                    setCardDetails(JSON.stringify(response.data, null, 2));
                    window.location.reload();
                  } catch (err) {
                    // Error handling here
                    console.error("An error occurred:", err);
                    setStatusAssign("Error: Unable to fetch Card Details");
                  }
                }
              }, 10000);
            }
          }, 10000);
        }
      } catch (error) {
        console.error("Error assigning card:", error);
        seterrorAssign(error);
        //   setLoadingAssignCard(false);
      }
    }
  };

  //ADmin Function handles
  const handleCreateVirtual = async () => {
    setstatusvirtualcreate("Sending request...");

    const { userId, UUID } = createVirtualInputs;

    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/create_virtual_card",
        {
          userId: userId,
          UUID: UUID,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Split Virtual Card response:", response.data);
      setstatusvirtualcreate("Done...");
    } catch (error) {
      console.error("Error splitting virtual card:", error);
      setstatusvirtualcreate(error.message);
    }
  };

  const handleSplitVirtual = async () => {
    setstatusvirtualsplit("Sending request...");
    const { userId, txHash, amount, splitAmount, currency } =
      splitVirtualInputs;
    console.log("data", splitVirtualInputs);
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/split_virtual_card",
        {
          user: userId,
          currency: currency,
          amount: splitAmount,
          brut_amount: amount,
          net_amount: splitAmount,
          fcf_amount: splitAmount,
          bb_amount: splitAmount,
          marketing_amount: splitAmount,
          txn_hash: txHash,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Split Virtual Card response:", response.data);
      setstatusvirtualsplit("Done...");
    } catch (error) {
      console.error("Error splitting virtual card:", error);
    }
  };

  const handleSplitTopup = async () => {
    setstatusvirtualsplit("Sending request...");
    const { userId, txHash, amount, splitAmount, currency } = splitTopupInputs;
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/split_topup",
        {
          user: userId,
          currency: currency,
          amount: splitAmount,
          brut_amount: amount,
          net_amount: splitAmount,
          fcf_amount: splitAmount,
          bb_amount: splitAmount,
          marketing_amount: splitAmount,
          txn_hash: txHash,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Split Topup response:", response.data);
      setstatusvirtualsplit("Done...");
    } catch (error) {
      console.error("Error splitting topup:", error);
    }
  };

  const handleCardBuyInbanx = async () => {
    setstatusvirtualsplit("Sending request...");
    const { userId, cardId, txHash, currency } = cardBuyInputs;
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/successbuy_virtual",
        {
          user: userId,
          cardID: cardId,
          coin: currency,
          txn: txHash,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Card Buy response:", response.data);
      setstatusvirtualsplit("Done...");
    } catch (error) {
      console.error("Error buying card:", error);
    }
  };

  const handleCardBuy = async () => {
    setstatusvirtualsplit("Sending request...");
    const { userId, cardId, txHash, currency } = cardBuyInputs;
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/successbuy",
        {
          user: userId,
          cardID: cardId,
          coin: currency,
          txn: txHash,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Card Buy response:", response.data);
      setstatusvirtualsplit("Done...");
    } catch (error) {
      console.error("Error buying card:", error);
    }
  };

  const { config } = usePrepareContractWrite({
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "transfer",
    args: withdrawalToProcess
      ? [withdrawalToProcess.wallet, withdrawalAmount]
      : undefined,
    enabled: !!withdrawalToProcess, // only enable if withdrawalToProcess is set
  });
  const {
    data: senddata,
    write: transfer,
    isLoading: sendpending,
    error: prepareTransferError,
  } = useContractWrite(config);

  useEffect(() => {
    setWithdrawalToProcess(null);
  }, [prepareTransferError]);

  const {
    data: topupdataceipt,
    isLoading: withdrawisPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: senddata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      handleTransactionSuccess(data);
    },
    onError(error) {
      console.log("onError", error);
      alert("Error sending payout");
    },
  });

  useEffect(() => {
    if (withdrawalToProcess && transfer) {
      console.log("Executing transfer for", withdrawalToProcess);
      transfer();
    }
  }, [withdrawalToProcess]);

  const handleTransactionSuccess = async (data) => {
    console.log("handleTransactionSuccess:", data);
    // console.log("withdrawalId:", withdrawalToProcess.id);
    console.log("txHash:", data.transactionHash);
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/send-payout",
        {
          withdrawalId: withdrawalToProcess.id,
          txHash: data.transactionHash,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Payout sent successfully", response);
      if (response.data.message === "success") {
        setFilteredWithdrawals((prevWithdrawals) =>
          prevWithdrawals.map((withdrawal) =>
            withdrawal.id === withdrawalToProcess.id
              ? {
                  ...withdrawal,
                  status: "Done",
                  txn_hash: data.transactionHash,
                }
              : withdrawal
          )
        );
      }
      setWithdrawalToProcess(null);
      alert("Payout sent successfully");
      window.location.reload(true);
    } catch (error) {
      console.error("Error saving transaction hash:", error);
      // alert("Error saving transaction hash");
    } finally {
      setWithdrawalToProcess(null);
    }
  };

  const handlePayout = (withdrawal) => {
    if (!withdrawal || !withdrawal.amount) {
      console.error("Invalid withdrawal data");
      return;
    }
    if (!chain || !chain.id) {
      console.error("Chain information is missing");
      return;
    }

    console.log("Initiating handlePayout", withdrawal);
    setWithdrawalToProcess(null);

    let amount;
    try {
      if (chain.id === 137) {
        amount = Web3.utils.toWei(Number(withdrawal.amount), "mwei");
      } else {
        amount = Web3.utils.toWei(withdrawal.amount, "ether");
      }
      console.log("Converted amount", amount);
    } catch (error) {
      console.error("Error converting amount", error);
      return;
    }

    setTimeout(() => {
      setWithdrawalToProcess(withdrawal);
      setWithdrawalAmount(amount);
    }, 0);
  };

  const renderUserWithdrawals = (withdrawals) => {
    if (!Array.isArray(withdrawals)) {
      return null;
    }

    return (
      withdrawals
        // .filter((withdrawal) => withdrawal.status === "pending")
        .map((withdrawal) => {
          let linkHref = "";
          let txnHashShort = "";
          if (withdrawal.txn_hash) {
            txnHashShort = withdrawal.txn_hash.substring(0, 10) + "...";

            if (withdrawal.currency === "bsc_usdt") {
              linkHref = "https://www.bscscan.com/tx/" + withdrawal.txn_hash;
            } else {
              linkHref = "https://polygonscan.com/tx/" + withdrawal.txn_hash;
            }
          }

          let currency_img;
          if (withdrawal.currency === "bsc_usdt") {
            currency_img = bnblogo;
          } else {
            currency_img = maticlogo;
          }

          const hoursPassed = differenceInHours(
            new Date(),
            new Date(withdrawal.created_at)
          );
          return (
            <TableRow
              key={withdrawal.id}
              style={{
                backgroundColor:
                  withdrawal.status === "Done" ? "#00800029" : "#ffa50057",
              }}
            >
              <TableCell>{withdrawal.user.username}</TableCell>
              <TableCell>{withdrawal.wallet}</TableCell>
              <TableCell>
                <img
                  src={currency_img}
                  alt={withdrawal.currency}
                  style={{ height: "25px" }}
                />
              </TableCell>
              <TableCell>{withdrawal.amount}</TableCell>
              <TableCell>
                <a href={linkHref} target="_blank" rel="noopener noreferrer">
                  {txnHashShort}
                </a>
              </TableCell>
              <TableCell>
                {withdrawal.created_at} {`${hoursPassed}h ago`}
              </TableCell>
              <TableCell>
                {withdrawal.status === "pending" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePayout(withdrawal)}
                    disabled={
                      !address ||
                      sendpending ||
                      withdrawisPending ||
                      chainname !== withdrawal.currency
                    }
                  >
                    {sendpending || withdrawisPending ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      </>
                    ) : (
                      <>Pay</>
                    )}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          );
        })
    );
  };

  const renderUserTableRows = (users) => {
    if (!Array.isArray(users)) {
      return null;
    }

    return users.map((user) => (
      <TableRow key={user.id}>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.cardHolderId}</TableCell>
        <TableCell>{cardTypeMapping[user.virtual_type]}</TableCell>
      </TableRow>
    ));
  };

  if (isLoading) {
    return (
      <div>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <h4>Loading...</h4>
          </Grid>
        </Box>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </div>
    );
  }

  const renderTableRows = (cards) => {
    if (!Array.isArray(cards)) {
      return null;
    }

    return cards.map((card) => {
      const tracking = card?.shipping_info?.tracking || "NO";
      const tracking_expr = card?.shipping_info?.tracking_expr || "NO";

      const trackingURL = `https://www.deutschepost.de/de/s/sendungsverfolgung.html?piececode=${tracking}`;
      const trackingExprURL = `https://www.dhl.com/de-de/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_expr}`;
      let linkUrl = "",
        displayText = "";

      if (tracking !== "NO") {
        // If there is a tracking number, it's normal shipping
        linkUrl = trackingURL;
        displayText = tracking;
      } else if (tracking_expr !== "NO") {
        // If there is a tracking expression, it's express shipping
        linkUrl = trackingExprURL;
        displayText = tracking_expr;
      }
      return (
        <React.Fragment key={card.id}>
          <TableRow
            style={{
              backgroundColor:
                kycStatuses[card.cardholdersId] === "REAL"
                  ? "green"
                  : kycStatuses[card.cardholdersId] === "FALSE"
                  ? "red"
                  : card?.isKycAccepted === "true"
                  ? "#00800029"
                  : "#ffa50057",
            }}
          >
            <TableCell>{card?.id}</TableCell>
            <TableCell>{card?.card_type}</TableCell>
            <TableCell>{card?.user_id}</TableCell>
            <TableCell>{card?.name}</TableCell>
            <TableCell>{card?.cardholdersId}</TableCell>
            <TableCell>{card?.cardKitNumber}</TableCell>
            <TableCell>${card?.balance}</TableCell>
            <TableCell>{card?.issuer_Last4}</TableCell>
            <TableCell>{card?.isKycAccepted}</TableCell>
            <TableCell>
              {kycStatuses[card.cardholdersId] || "N/A"}
            </TableCell>{" "}
            <TableCell>
              {displayText === "NO" ? (
                "NO"
              ) : (
                <a
                  style={{ color: "blue" }}
                  href={linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {displayText}
                </a>
              )}
            </TableCell>
            <TableCell>
              {localUser?.admin === 1 ? (
                <>
                  {" "}
                  <Button
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenAssignSaveDialog(card)}
                    disabled={card?.shipping_info?.cardKitNumber !== "200000"}
                  >
                    Save CardKit
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  {card?.cardKitNumber === null && (
                    <>
                      <Button
                        style={{ marginLeft: "10px", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenAssignSaveDialog(card)}
                        disabled={
                          card?.shipping_info?.cardKitNumber !== "200000"
                        }
                      >
                        Save CardKit
                      </Button>
                      <Button
                        style={{ marginLeft: "10px", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenAssignDialog(card)}
                        disabled={!kycStatuses[card.cardholdersId]}
                      >
                        Assign Card
                      </Button>
                    </>
                  )}
                  {/* {(card?.cardKitNumber === null &&
                card?.isKycAccepted === "false") ||
                (kycStatuses[card?.cardholdersId] !== "REAL" && (
                  <Button
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => fetchFirstKycAcceptedUser(card)}
                  >
                    {isLoadingDetailsCHID ? "Loading..." : "ASSIGN FREE CHID"}
                  </Button>
                ))} */}
                  {card?.cardKitNumber === null &&
                    card?.isKycAccepted !== "false" && (
                      <Button
                        style={{ marginLeft: "10px", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => fetchCardDetails(card.cardholdersId)}
                      >
                        {isLoadingDetails ? "Loading..." : "Get Card Details"}
                      </Button>
                    )}
                  {card?.cardKitNumber === null &&
                    card?.isKycAccepted === "false" && (
                      <Button
                        style={{ marginLeft: "10px", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenDialog(card)}
                      >
                        Change CHID
                      </Button>
                    )}
                  <Button
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialogShipping(card)}
                  >
                    Shipping Details
                  </Button>
                  <Button
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleRefreshBalance(card)}
                  >
                    Refresh Balance
                  </Button>
                  {card?.cardKitNumber === null && (
                    <>
                      <Button
                        style={{ marginLeft: "10px", marginTop: "10px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => checkKycStatus(card.cardholdersId)}
                        disabled={kycStatusLoading}
                      >
                        {kycStatusLoading ? "Loading..." : "Check KYC Status"}
                      </Button>
                      {kycStatus && (
                        <div>
                          <p>{kycStatus.message || "KYC Status: Success"}</p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    });
  };

  if (isLoading) {
    return (
      <div>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <h4>Loading...</h4>
          </Grid>
        </Box>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </div>
    );
  }

  return (
    <>
      {localUser?.admin !== 0 && (
        <>
          <Dialog
            open={openUserDialog}
            onClose={() => setOpenUserDialog(false)}
          >
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {userData ? (
                <div style={{ width: "100%", minWidth: "400px" }}>
                  <p style={{ margin: "0.1em 0" }}>UserID: {userData.id}</p>
                  <p style={{ margin: "0.1em 0" }}>
                    Username: {userData.username}
                  </p>
                  <p style={{ margin: "0.1em 0" }}>Name: {userData.name}</p>
                  <p style={{ margin: "0.1em 0" }}>Email: {userData.email}</p>
                  <p style={{ margin: "0.1em 0" }}>
                    CHID: {userData.cardHolderId}
                  </p>
                </div>
              ) : (
                <p>No user found</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenUserDialog(false)} color="primary">
                Close
              </Button>
              <Button onClick={handleChangeCHID} color="primary">
                {loadingcidchange ? "Loading..." : "Activate Card"}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDialogShipping}
            onClose={handleCloseDialogShipping}
            style={{ minWidth: "250px" }}
          >
            <DialogTitle>{"Shipping Details"}</DialogTitle>
            <DialogContent style={{ minWidth: "450px" }}>
              {cardTypeMapping[selectedRow?.card_type]}
              <br />
              CHID: {selectedRow?.cardholdersId} <br />
              CardKit: {selectedRow?.shipping_info?.cardKitNumber} <br />
              <br />
              {selectedRow?.shipping_info?.name} <br />
              {selectedRow?.shipping_info?.address}
              <br />
              {selectedRow?.shipping_info?.zip}{" "}
              {selectedRow?.shipping_info?.city}
              <br />
              {selectedRow?.shipping_info?.state}
              <br />
              {selectedRow?.shipping_info?.country}
              <br />
              <br />
              <input
                type="text"
                className="form-control h-auto text-black"
                placeholder="DHL tracking number"
                onChange={(e) => setDeliveryLinkDHL(e.target.value)}
              />
              <br />
              <input
                type="text"
                className="form-control h-auto text-black"
                placeholder="DHL Express tracking number"
                onChange={(e) => setDeliveryLinkDHLExpress(e.target.value)}
              />
              {/* <br />
              <TextField
                autoFocus
                margin="dense"
                id="cardKitNumber"
                label="Card Kit Number"
                type="text"
                fullWidth
                value={cardKitNumberInputShipping}
                onChange={(event) =>
                  setCardKitNumberInputShipping(event.target.value)
                }
              /> */}
              {successfulSend && (
                <Alert severity="success">Push successful send</Alert>
              )}
              {mailsuccess && (
                <Alert severity="success">Mail successful send</Alert>
              )}
              {mailerror && (
                <Alert severity="error">Error - please try again</Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleSendTrackingMail(selectedRow)}
                color="primary"
                disabled={!deliveryLinkDHL && !deliveryLinkDHLExpress}
              >
                {loadingMail ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                  </>
                ) : (
                  <>Send Mail</>
                )}
              </Button>
              <Button onClick={handleCloseDialogShipping} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{"Change Card Holder ID"}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="New Card Holder ID"
                type="text"
                fullWidth
                value={newCHID}
                onChange={(event) => setNewCHID(event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearchInDialog}
                style={{ marginTop: "10px" }}
              >
                Search
              </Button>
              {searchResult && (
                <div style={{ marginTop: "20px" }}>
                  <p>Card ID: {searchResult.id}</p>
                  <p>Name: {searchResult.name}</p>
                  <p>Card Holder ID: {searchResult.cardholdersId}</p>
                  <p>Kit Number: {searchResult.cardKitNumber}</p>
                </div>
              )}
              {openAlert && (
                <Alert onClose={() => setAlert(false)} severity="success">
                  CHID has been successfully changed!
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleChangeCHID} color="primary">
                {loadingcidchange ? "Loading..." : "Confirm"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openAssignSaveDialog}
            onClose={handleCloseAssignSaveDialog}
          >
            <DialogTitle>{"Save Card Kit Number"}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="cardKitNumber"
                label="Card Kit Number"
                type="text"
                fullWidth
                value={cardKitNumberInput}
                onChange={(event) => setCardKitNumberInput(event.target.value)}
              />
            </DialogContent>

            <Alert severity="info">{statusSave}</Alert>
            {errorAssign !== "" && (
              <Alert severity="success">{errorAssign}</Alert>
            )}
            {successfulAssign && (
              <Alert severity="success">Kitnumber saved</Alert>
            )}
            <DialogActions>
              <Button onClick={handleCloseAssignSaveDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveCardkit} color="primary">
                {loadingSaveCardkit ? "Loading..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAssignDialog} onClose={handleCloseAssignDialog}>
            <DialogTitle>{"Assign Card Kit Number"}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="cardKitNumber"
                label="Card Kit Number"
                type="text"
                fullWidth
                value={cardKitNumberInput}
                onChange={(event) => setCardKitNumberInput(event.target.value)}
              />
            </DialogContent>

            <Alert severity="info">{statusAssign}</Alert>
            {errorAssign !== "" && (
              <Alert severity="success">{errorAssign}</Alert>
            )}
            {successfulAssign && (
              <Alert severity="success">Kitnumber assigned</Alert>
            )}
            <DialogActions>
              <Button onClick={handleCloseAssignDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAssignCard} color="primary">
                {loadingAssignCard ? "Loading..." : "Confirm"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Withdraw Table */}

          {localUser?.admin === 2 && (
            <div className="row">
              <div className="col-xl-12 col-12 m-3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="card-title">
                        <div className="col-12">
                          <h5 className="card-title">Withdrawals</h5>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>All Withdrawals</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer
                              component={Paper}
                              style={{ marginTop: "20px" }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Wallet</TableCell>
                                    <TableCell>Chain</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>TxH</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {renderUserWithdrawals(filteredWithdrawals)}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Pagination
                              count={Math.ceil(
                                totalWithdrawals / withdrawalPerPage
                              )}
                              page={withdrawalCurrentPage}
                              onChange={handleWithdrawalPageChange}
                              color="primary"
                              style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 m-3">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="card-title">
                      <div className="col-12">
                        <h5 className="card-title">Functions</h5>
                      </div>
                    </div>
                    {/* Virtual inbanx creation */}
                    <div className="col-12 mt-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>inbanx Virtual Card Buy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            name="userId"
                            label="UserID"
                            variant="outlined"
                            value={cardBuyInputs.userId}
                            onChange={(e) =>
                              handleInputChange(e, setCardBuyInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          >
                            <InputLabel id="cardId-label">Card Type</InputLabel>
                            <Select
                              labelId="cardId-label"
                              // Displayed value is the card type (e.g., 'Standard')
                              value={cardBuyInputs.cardType}
                              onChange={(e) => {
                                const newEvent = {
                                  target: {
                                    name: e.target.name,
                                    value: cardTypes[e.target.value],
                                  },
                                };

                                setCardBuyInputs({
                                  ...cardBuyInputs,
                                  cardId: e.target.value, // The numerical ID
                                  cardType: e.target.value, // The displayed card type
                                });

                                handleInputChange(newEvent, setCardBuyInputs);
                              }}
                              label="Card Type"
                            >
                              <MenuItem value={"Standard"}>Standard</MenuItem>
                              <MenuItem value={"Premium"}>Premium</MenuItem>
                              <MenuItem value={"VIP"}>VIP</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            name="txHash"
                            label="Txhash"
                            variant="outlined"
                            value={cardBuyInputs.txHash}
                            onChange={(e) =>
                              handleInputChange(e, setCardBuyInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />{" "}
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          >
                            <InputLabel>Currency</InputLabel>
                            <Select
                              name="currency"
                              value={cardBuyInputs.currency}
                              onChange={(e) =>
                                handleInputChange(e, setCardBuyInputs)
                              }
                            >
                              <MenuItem value="matic">Matic</MenuItem>
                              <MenuItem value="bep20">Bep20</MenuItem>
                            </Select>
                          </FormControl>
                          <Alert severity="info">{statusvirtualsplit}</Alert>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCardBuyInbanx}
                            style={{ marginTop: "10px" }}
                          >
                            Card Buy
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="col-12 mt-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Create Virtual Card</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            name="userId"
                            label="UserID"
                            variant="outlined"
                            value={createVirtualInputs.userId}
                            onChange={(e) =>
                              handleInputChange(e, setcreateVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="UUID"
                            label="UUID"
                            variant="outlined"
                            value={createVirtualInputs.UUID}
                            onChange={(e) =>
                              handleInputChange(e, setcreateVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />{" "}
                          <Alert severity="info">{statusvirtualcreate}</Alert>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateVirtual}
                            style={{ marginTop: "10px" }}
                          >
                            Create Virtual Card
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-12 mt-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Split Virtual Cards</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            name="userId"
                            label="UserID"
                            variant="outlined"
                            value={splitVirtualInputs.userId}
                            onChange={(e) =>
                              handleInputChange(e, setSplitVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="txHash"
                            label="Txhash"
                            variant="outlined"
                            value={splitVirtualInputs.txHash}
                            onChange={(e) =>
                              handleInputChange(e, setSplitVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />{" "}
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          >
                            <InputLabel>Currency</InputLabel>
                            <Select
                              name="currency"
                              value={splitVirtualInputs.currency}
                              onChange={(e) =>
                                handleInputChange(e, setSplitVirtualInputs)
                              }
                            >
                              <MenuItem value="matic">Matic</MenuItem>
                              <MenuItem value="bep20">Bep20</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            name="amount"
                            label="Card Amount"
                            variant="outlined"
                            value={splitVirtualInputs.amount}
                            onChange={(e) =>
                              handleInputChange(e, setSplitVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="splitAmount"
                            label="Amount to split"
                            variant="outlined"
                            value={splitVirtualInputs.splitAmount}
                            onChange={(e) =>
                              handleInputChange(e, setSplitVirtualInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <Alert severity="info">{statusvirtualsplit}</Alert>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSplitVirtual}
                            style={{ marginTop: "10px" }}
                          >
                            Split Virtual Card
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-12 mt-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Split Topup</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            name="userId"
                            label="UserID"
                            variant="outlined"
                            value={splitTopupInputs.userId}
                            onChange={(e) =>
                              handleInputChange(e, setSplitTopupInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="txHash"
                            label="Txhash"
                            variant="outlined"
                            value={splitTopupInputs.txHash}
                            onChange={(e) =>
                              handleInputChange(e, setSplitTopupInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />{" "}
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          >
                            <InputLabel>Currency</InputLabel>
                            <Select
                              name="currency"
                              value={splitTopupInputs.currency}
                              onChange={(e) =>
                                handleInputChange(e, setSplitTopupInputs)
                              }
                            >
                              <MenuItem value="matic">Matic</MenuItem>
                              <MenuItem value="bep20">Bep20</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            name="amount"
                            label="Amount"
                            variant="outlined"
                            value={splitTopupInputs.amount}
                            onChange={(e) =>
                              handleInputChange(e, setSplitTopupInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="splitAmount"
                            label="Amount to split"
                            variant="outlined"
                            value={splitTopupInputs.splitAmount}
                            onChange={(e) =>
                              handleInputChange(e, setSplitTopupInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <Alert severity="info">{statussplittopup}</Alert>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSplitTopup}
                            style={{ marginTop: "10px" }}
                          >
                            Split Topup
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-12 mt-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>Card Buy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            name="userId"
                            label="UserID"
                            variant="outlined"
                            value={cardBuyInputs.userId}
                            onChange={(e) =>
                              handleInputChange(e, setCardBuyInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="cardId"
                            label="CardID"
                            variant="outlined"
                            value={cardBuyInputs.cardId}
                            onChange={(e) =>
                              handleInputChange(e, setCardBuyInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            name="txHash"
                            label="Txhash"
                            variant="outlined"
                            value={cardBuyInputs.txHash}
                            onChange={(e) =>
                              handleInputChange(e, setCardBuyInputs)
                            }
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          />{" "}
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "10px" }}
                          >
                            <InputLabel>Currency</InputLabel>
                            <Select
                              name="currency"
                              value={cardBuyInputs.currency}
                              onChange={(e) =>
                                handleInputChange(e, setCardBuyInputs)
                              }
                            >
                              <MenuItem value="matic">Matic</MenuItem>
                              <MenuItem value="bep20">Bep20</MenuItem>
                            </Select>
                          </FormControl>
                          <Alert severity="info">{statusvirtualsplit}</Alert>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCardBuy}
                            style={{ marginTop: "10px" }}
                          >
                            Card Buy
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 m-3">
                      <h5>User Management</h5>
                      <TextField
                        className="mt-2 mb-2"
                        label="Search by email, username, name, inbanx or CHID"
                        variant="outlined"
                        value={searchUserTerm}
                        onChange={handleUserSearchChange}
                        fullWidth
                      />
                      <TableContainer
                        component={Paper}
                        style={{ marginTop: "20px" }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Username</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>CHID</TableCell>
                              <TableCell>inbanx</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {renderUserTableRows(filteredUsers)}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Pagination
                        count={Math.ceil(totalUsers / userPerPage)}
                        page={userCurrentPage}
                        onChange={handleUserPageChange}
                        color="primary"
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 m-3">
              <TextField
                className="mt-2 mb-2"
                label="Search by name or CHID"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleFilterKycClick}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                {filterKyc ? "Show All KYC" : "Show KYC Not Accepted"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setFilterKitNumber(!filterKitNumber)}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                {filterKitNumber
                  ? "Show All Kitnumbers"
                  : "Show Empty Kit Numbers"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setFilterShippingDetails(!filterShippingDetails);
                  setCurrentPage(1);
                  applyFilters();
                }}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                {filterShippingDetails ? "Show All Cards" : "Cards To Ship"}
              </Button>

              <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>CardID</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>UserID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>CHID</TableCell>
                      <TableCell>KitNumber</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>L4Digits</TableCell>
                      <TableCell>KYC</TableCell>
                      <TableCell>KYCFCF</TableCell>
                      <TableCell>Shipped</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableRows(filteredCards)}</TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={Math.ceil(totalCards / perPage)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <TextField
                component={Paper}
                fullWidth
                label="Console"
                readOnly
                multiline
                InputProps={{
                  readOnly: true,
                  inputProps: { style: { minHeight: "100px" } },
                }}
                margin="normal"
                variant="outlined"
                value={cardDetails}
                rows={4}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MANAGE_CARDS;
