import React from "react";

const BalanceDisplay = ({
  cardBalance,
  refreshloading,
  handlerefreshbalance,
  issuer_Last4,
}) => {
  return (
    <div
      className="d-flex align-items-center mb-sm-3 mb-1"
      style={{ justifyContent: "space-between" }}
    >
      <h2 className="fs-36 text-black mb-sm-2 mb-3">
        {refreshloading ? (
          <div className="loadingDots">
            <span style={{ backgroundColor: "black" }}></span>
            <span style={{ backgroundColor: "black" }}></span>
            <span style={{ backgroundColor: "black" }}></span>
          </div>
        ) : (
          `$${cardBalance}`
        )}
      </h2>
      <button
        className="btn btn-light btn-outline-dark ml-2 p-2"
        onClick={handlerefreshbalance}
      >
        <i
          className={`fas fa-sync-alt ${refreshloading ? "fa-spin" : ""}`}
          style={{ fontSize: "25px" }}
        ></i>
      </button>
      <div
        className="d-flex align-items-center mb-sm-2 mb-2"
        style={{ justifyContent: "end" }}
      >
        <h4 className="fs-20 text-black mb-0">**** **** **** {issuer_Last4}</h4>
      </div>
    </div>
  );
};

export default BalanceDisplay;
