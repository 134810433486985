import React, { useState, useEffect, Fragment } from "react";
import { Card, Button, Form, Alert, Col, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import Topupbutton from "../../../src/jsx/components/web3/TopupbuttonVirtual.js";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Eye icons for toggle

import BalanceDisplay from "./BalanceDisplay.js";

const VirtualCardWidgetBlank = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [network, setNetwork] = useState("");
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [cardBalance, setcardBalance] = useState("0.00");
  const [cardnumber, setCardNumber] = useState("**** **** **** ****");
  const [issuer_Last4, setissuer_Last4] = useState("0000");
  const [ccv, setCcv] = useState("***");
  const [validTill, setValidTill] = useState("**/**");
  const [showDetails, setShowDetails] = useState(false);

  const [refreshloading, setrefreshloading] = useState(false);
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [confirmations, setConfirmations] = useState(0);
  const [transactionsConfirmed, setTransactionsConfirmed] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);
  const [topuperror, settopuperror] = useState("");
  const [minAmount, setminAmount] = useState(0);
  const [net_topup, setnet_topup] = useState(0);
  const [toFcf, setToFcf] = useState(0);

  const [addressIn, setAddressIn] = useState("");
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(false);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [alert, setAlert] = useState(null);
  const [success_message, setsuccess_message] = useState(false);
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

  const loadingfees = {
    1: [0.0499, 5],
    2: [0.0399, 4],
    3: [0.0349, 3],
    10: [0, 0],
  };

  useEffect(() => {
    if (localUser?.virtual_uuid) {
      if (!localUser?.virtualcards_reloadable) {
      } else {
        let cardNumber = localUser?.virtualcards_reloadable?.card_number;
        let lastFourDigits = cardNumber?.slice(-4);
        setCardNumber("**** **** **** " + lastFourDigits);
        let cardBalance = localUser?.virtualcards_reloadable?.balance;
        setcardBalance(cardBalance);
      }
    }
  }, [localUser]);

  return (
    <Fragment>
      {" "}
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-8 col-lg-12 col-md-7 col-sm-8">
              <div
                className="card-bx "
                style={{ minWidth: "270px", marginBottom: "20px" }}
              >
                <img
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/5fbb9444-f771-4570-6c0b-fa6ea0a81900/public"
                  alt=""
                  //   className="mw-100 "
                  style={{
                    height: "auto",
                    minHeight: "270px",
                    position: "relative",
                  }}
                />
                <div
                  className="card-info text-black w-100"
                  style={{
                    position: "absolute",
                    top: "0",
                    padding: "35px",
                    paddingRight: "50px",
                  }}
                >
                  {" "}
                  <div
                    className="d-flex align-items-center mb-sm-3 mb-1"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="mb-1 ">Card Balance</p>
                    {/* <p className="mb-1 text-success font-w600">Virtual</p> */}
                  </div>
                  <div
                    className="d-flex align-items-center mb-sm-3 mb-1"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="d-flex align-items-center">
                      <h2 className="fs-36 text-black mb-sm-0 mb-0">
                        {/* mb-sm-2 mb-3 */}
                        {refreshloading ? (
                          <div className="loadingDots">
                            <span style={{ backgroundColor: "black" }}></span>
                            <span style={{ backgroundColor: "black" }}></span>
                            <span style={{ backgroundColor: "black" }}></span>
                          </div>
                        ) : (
                          `$${cardBalance}`
                        )}
                      </h2>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mb-sm-2 mb-2"
                    style={{ justifyContent: "end" }}
                  >
                    {/* <div
                      className="input-group"
                      style={{ paddingRight: "1rem" }}
                    >
                      <input
                        type="text"
                        className="form-control h-auto text-black"
                        placeholder="Enter Amount"
                        // onChange={(e) => setLoadAmount(e.target.value)}
                        style={{ maxWidth: "150px" }}
                        // disabled={
                        //   isLoadingTransactionBSC ||
                        //   isLoadingTransactionMATIC
                        // }
                      />
                    </div> */}
                    <h4
                      className="fs-20 text-black mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {/* **** **** **** {issuer_Last4} */}
                      {cardnumber}
                    </h4>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mt-4"
                    style={{ paddingRight: "6rem" }}
                  >
                    <div>
                      <h5 className="m-0" style={{ fontSize: "10px" }}>
                        CCV:
                      </h5>
                      <p>{ccv}</p>
                    </div>

                    <div>
                      <h5 className="m-0" style={{ fontSize: "10px" }}>
                        VALID THRU:
                      </h5>
                      <p>{validTill}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VirtualCardWidgetBlank;
