import React, { useEffect, useState } from "react";
import "./CountdownTimer.css";

const CountdownTimer = ({ countdownTime }) => {
  const calculateTimeLeft = () => {
    const difference = countdownTime - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [countdownTime]);

  return (
    <div className="countdown">
      {/* <div className="box">
        <span className="num" id="day-box">
          {String(timeLeft.days).padStart(2, "0")}
        </span>
        <span className="text">Days</span>
      </div> */}
      <div className="box">
        <span className="num" id="hr-box">
          {String(timeLeft.hours).padStart(2, "0")}
        </span>
        <span className="text">Hours</span>
      </div>
      <div className="box">
        <span className="num" id="min-box">
          {String(timeLeft.minutes).padStart(2, "0")}
        </span>
        <span className="text">Minutes</span>
      </div>
      <div className="box">
        <span className="num" id="sec-box">
          {String(timeLeft.seconds).padStart(2, "0")}
        </span>
        <span className="text">Seconds</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
