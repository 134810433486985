import React, { useState, useEffect, Fragment } from "react";
import { Card, Button, Form, Alert, Col, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import Topupbutton from "../../../src/jsx/components/web3/TopupbuttonVirtual.js";
import CardTransactions from "../../../src/jsx/components/CardTransactions.js";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Eye icons for toggle

import BalanceDisplay from "./BalanceDisplay.js";

const VirtualCardWidget = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [network, setNetwork] = useState("");
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [cardBalance, setcardBalance] = useState("0.00");
  const [cardnumber, setCardNumber] = useState("**** **** **** ****");
  const [issuer_Last4, setissuer_Last4] = useState("0000");
  const [ccv, setCcv] = useState("***");
  const [validTill, setValidTill] = useState("**/**");
  const [showDetails, setShowDetails] = useState(false);

  const [refreshloading, setrefreshloading] = useState(false);
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [confirmations, setConfirmations] = useState(0);
  const [transactionsConfirmed, setTransactionsConfirmed] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);
  const [topuperror, settopuperror] = useState("");
  const [minAmount, setminAmount] = useState(0);
  const [net_topup, setnet_topup] = useState(0);
  const [toFcf, setToFcf] = useState(0);

  const [addressIn, setAddressIn] = useState("");
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(false);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [alert, setAlert] = useState(null);
  const [success_message, setsuccess_message] = useState(false);
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [old_balance, set_old_balance] = useState(0);

  const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

  const loadingfees = {
    1: [0.0499, 5],
    2: [0.0399, 4],
    3: [0.0349, 3],
    10: [0, 0],
  };

  useEffect(() => {
    console.log("useEffect Loadmount");
    settopuperror("");

    let min_amount;
    let fix_fee;
    let real_amount;

    setminAmount(50);
    min_amount = 50;
    fix_fee = 0;
    real_amount = LoadAmount - fix_fee;
    console.log("real_amount", real_amount);

    if (LoadAmount >= min_amount) {
      let InputAmount = LoadAmount - fix_fee;
      console.log("InputAmount - fixfee", InputAmount);

      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.virtual_type][0] +
        loadingfees[localUser?.virtual_type][1];
      console.log("Overall Fee", bridgetloadingfee);

      let TAmount = InputAmount * 0.0025 + 3;
      console.log("TAmount", TAmount);
      let CAmount = TAmount / 2;
      console.log("CAmount", CAmount);
      console.log("restAmount", CAmount);

      let net_card_amount;
      if (address !== undefined) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        let cryptapifee = LoadAmount * 0.01;
        console.log("cryptapifee", cryptapifee);
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      setnet_topup(Math.floor(net_card_amount));
      setToFcf(net_card_amount.toFixed(2));
    }
  }, [LoadAmount]);

  const fetchcardbalance = async () => {
    if (localUser?.virtualcards_reloadable) {
      try {
        const response = await axios.post(
          "https://api.bridge-bit.com/api/getBalanceByUserReloadable",
          {
            user: localUser.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        console.log(" ", response);

        if (response.data.success === true) {
          return response.data.message;
        } else {
          if (
            response.data.message === "Failed to retrieve balance" ||
            response.data.message === "Something went wrong"
          ) {
            if (localUser) {
              return Number(localUser.virtualcards_reloadable.balance);
            }
          }
          return Number(localUser.virtualcards_reloadable.balance);
        }

        if (!response.status === 200) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.log(error); // log the error to the console for debugging

        if (localUser) {
          return Number(localUser.virtualcards_reloadable.balance);
        }
        // Handle the error based on the error message
        if (error.response && error.response.status === 500) {
          // Server error, handle it as per the requirement
        } else if (error.request) {
          // The request was made but no response was received
          console.log("The request was made but no response was received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      }
    }
  };

  const toggleShowDetails = () => {
    if (!detailsClicked) {
      // First click: show the card number only
      setCardNumber(
        localUser?.virtualcards_reloadable?.card_number
          .match(/.{1,4}/g)
          .join(" ")
      );
      setDetailsClicked(true); // Mark that the button was clicked once
    } else {
      // Second click: ask for confirmation to show the CVV and expiry date
      const userConfirmation = window.confirm("Show Date and CVV?");

      if (userConfirmation) {
        // Show details for 10 seconds
        setCcv(localUser?.virtualcards_reloadable?.cvv);
        setValidTill(
          localUser?.virtualcards_reloadable?.exp_month +
            "/" +
            localUser?.virtualcards_reloadable?.exp_year
        );
        setCountdown(10); // Start the countdown from 10

        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown > 1) {
              return prevCountdown - 1;
            } else {
              clearInterval(countdownInterval);
              return 0;
            }
          });
        }, 1000);

        setTimeout(() => {
          // Hide details after 10 seconds
          setCcv("***");
          setValidTill("**/**");
          setCountdown(0); // Reset the countdown
        }, 10000);
      } else {
        // Hide the details if user cancels
        setCcv("***");
        setValidTill("**/**");
      }

      setCardNumber("**** **** **** ****"); // Hide the card number
      setDetailsClicked(false); // Reset the state for the next series of clicks
    }
  };

  const handlerefreshbalance = async () => {
    setrefreshloading(true);
    const balance = await fetchcardbalance();
    setcardBalance(balance);
    setrefreshloading(false);
    console.log("balance ", balance);
    console.log("old_balance ", old_balance);
    if (old_balance > balance) {
      console.log("Balance updated - fetching transactions");
      fetchcardTransactions();
    }
  };

  const fetchcardTransactions = async () => {
    try {
      axios
        .post("https://api.bridge-bit.com/api/get_transactions_inbanx", {
          user: localUser.id,
        })
        .then(async (response) => {
          console.log("reponse", response);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (localUser?.virtualcards_reloadable) {
      console.log(
        "set oldbalance ",
        Number(localUser?.virtualcards_reloadable?.balance)
      );
      set_old_balance(Number(localUser?.virtualcards_reloadable?.balance));
      handlerefreshbalance();
    }

    if (localUser?.virtual_uuid) {
      if (!localUser?.virtualcards_reloadable) {
      } else {
        let cardNumber = localUser?.virtualcards_reloadable?.card_number;
        let lastFourDigits = cardNumber?.slice(-4);
        setCardNumber("**** **** **** " + lastFourDigits);
        let cardBalance = localUser?.virtualcards_reloadable?.balance;
        setcardBalance(cardBalance);
      }
    }
  }, [localUser]);

  useEffect(() => {
    console.log("useEffect addressIn called");
    console.log("addressIn", addressIn);
    console.log("isLoadingTransaction", isLoadingTransaction);
    if (addressIn !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        console.log("interval isLoadingTransaction");
        fetchUserTransactions();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [addressIn]);

  const fetchUserTransactions = async () => {
    try {
      console.log("address in", addressIn);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${addressIn}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      setTxnHash(data.txid_in);
      setConfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("User transactions fetched successfully", data);
      if (data.status === "confirmed" && data.amount >= LoadAmount) {
        setIsLoadingTransaction(false);
        setTransactionsConfirmed(true);

        axios
          .post("https://api.bridge-bit.com/api/split_inbanx_topup", {
            user: localUser.id,
            brut_amount: data.amount,
            currency: network,
            txn_hash: data.txid_in,
          })
          .then(async (response) => {
            console.log(response);
            setAlert({ type: "success", message: "Card Buy successful" });
            // await fetchUserData(localUser.firebaseUid);

            // setTimeout(() => {
            //   window.location.reload();
            // }, 5000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (data.status === "confirmed" && data.amount < LoadAmount) {
        setIsLoadingTransaction(false);
        setTransactionsConfirmed(false);
        setErrorMessage(
          "Amount not enough " + data.amount + " / " + LoadAmount
        );
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  async function fetchDepositData() {
    // console.log("cardNumber", cardNumber);
    // console.log("amount", amount.toString());
    // console.log("tokenSymbol", tokenSymbol);

    const laravelApiResponse = await axios.post(
      "https://api.bridge-bit.com/api/loadDepositVirtual",
      {
        user: localUser.id,
        amount: net_topup,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    console.log("Issue card API response:", laravelApiResponse.data);

    if (laravelApiResponse.data.success === true) {
      return laravelApiResponse.data.data;
    }
  }

  const fetchQrCode = async (address, network) => {
    try {
      var value = LoadAmount;
      const query = new URLSearchParams({
        address: address,
        size: "512",
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${network}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      // Assuming the QR code data contains a base64 encoded image
      if (data.qr_code) {
        setQrCode(data.qr_code); // Update the state with the QR code data
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const createapiaddressTopup = async (network) => {
    setIsLoadingNetwork(true); // Show loading spinner
    setNetwork(network); // Store the selected network
    setIsLoadingTransaction(true);

    let fcf_network;
    let crypt_network;
    if (network === "bep20") {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      // setIsLoadingTransaction(true);
    } else if (network === "matic") {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      // setIsLoadingTransaction(true);
    }

    let min_amount = 80;
    let fix_fee = 10;
    try {
      let amount = LoadAmount;
      // console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading
      let InputAmount = LoadAmount - fix_fee;

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee createapiaddress", cryptapifee);
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.virtual_type][0] +
        loadingfees[localUser?.virtual_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(0));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData();

      console.log("apiResponse", apiResponse);
      //   setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.crypto_currency_amount;
      //   setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("TAmount", bridgebit_amount);
      // let topupsplit = bridgebit_amount * 0.8;
      // settopupref(topupsplit);
      // console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      // console.log("to_fcf_percentage", to_fcf_percentage);
      //   setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      //   setbb_marketingAmount(bridgebit_amount * 0.8);
      //   setbb_crAmount(bridgebit_amount * 0.1);
      //   setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      //   setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=2&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setAddressIn(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(data.address_in, crypt_network);
        setIsLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoadingNetwork(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoadingNetwork(false);
    }
  };

  const handlesetNetwork = (selectedNetwork) => {
    createapiaddressTopup(selectedNetwork);
  };

  const handleStatusChange = (status) => {
    // console.log("handleStatusChange", status);
    // setStatus(status);
  };

  const handlesettopuperror = (message) => {
    // console.log("handleettopuperror", message);
    settopuperror(message);
  };

  const handleTopUpSuccess = async () => {
    console.log("handleTopUpSuccess");
    setsuccess_message(true);
    // set_old_balance(cardBalance);
    const interval = setInterval(() => {
      handlerefreshbalance();
    }, 15000);
    // return () => clearInterval(interval);
  };

  return (
    <Fragment>
      {" "}
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12">
          {/* <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-7 col-sm-8"> */}
          <div
            className="card-bx "
            style={{ minWidth: "270px", marginBottom: "20px" }}
          >
            <img
              src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/5fbb9444-f771-4570-6c0b-fa6ea0a81900/public"
              alt=""
              //   className="mw-100 "
              style={{
                height: "auto",
                minHeight: "270px",
                position: "relative",
              }}
            />
            <div
              className="card-info text-black w-100"
              style={{
                position: "absolute",
                top: "0",
                padding: "35px",
                paddingRight: "50px",
              }}
            >
              {" "}
              <div
                className="d-flex align-items-center mb-sm-3 mb-1"
                style={{ justifyContent: "space-between" }}
              >
                <p className="mb-1 ">Card Balance</p>
                {/* <p className="mb-1 text-success font-w600">Virtual</p> */}
              </div>
              <div
                className="d-flex align-items-center mb-sm-3 mb-1"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-items-center">
                  <h2 className="fs-36 text-black mb-sm-0 mb-0">
                    {/* mb-sm-2 mb-3 */}
                    {refreshloading ? (
                      <div className="loadingDots">
                        <span style={{ backgroundColor: "black" }}></span>
                        <span style={{ backgroundColor: "black" }}></span>
                        <span style={{ backgroundColor: "black" }}></span>
                      </div>
                    ) : (
                      `$${cardBalance}`
                    )}
                  </h2>
                  <button
                    className="btn btn-light btn-outline-dark ml-2 p-2"
                    style={{ marginLeft: "5px" }}
                    onClick={handlerefreshbalance}
                  >
                    <i
                      className={`fas fa-sync-alt ${
                        refreshloading ? "fa-spin" : ""
                      }`}
                      style={{ fontSize: "25px" }}
                    ></i>
                  </button>
                </div>
                {/* Eye Icon for Toggle */}

                <button
                  onClick={toggleShowDetails}
                  className="btn btn-light btn-outline-dark ml-2 p-2"
                >
                  {countdown > 0
                    ? `Hide in ${countdown}s`
                    : showDetails
                    ? "Hide Details"
                    : "Show Details"}
                  {showDetails ? (
                    <FaEyeSlash className="p-1" style={{ fontSize: "25px" }} />
                  ) : (
                    <FaEye className="p-1" style={{ fontSize: "25px" }} />
                  )}
                </button>
              </div>
              <div
                className="d-flex align-items-center mb-sm-2 mb-2"
                style={{ justifyContent: "end" }}
              >
                <div className="input-group" style={{ paddingRight: "1rem" }}>
                  <input
                    type="text"
                    className="form-control h-auto text-black"
                    placeholder="Enter Amount"
                    onChange={(e) => setLoadAmount(e.target.value)}
                    style={{ maxWidth: "150px" }}
                    // disabled={
                    //   isLoadingTransactionBSC ||
                    //   isLoadingTransactionMATIC
                    // }
                  />
                </div>
                <h4
                  className="fs-20 text-black mb-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {/* **** **** **** {issuer_Last4} */}
                  {cardnumber}
                </h4>
              </div>
              <div
                className="d-flex align-items-center justify-content-between mt-4"
                style={{ paddingRight: "6rem" }}
              >
                <div>
                  <h5 className="m-0" style={{ fontSize: "10px" }}>
                    CCV:
                  </h5>
                  <p>{ccv}</p>
                </div>

                <div>
                  <h5 className="m-0" style={{ fontSize: "10px" }}>
                    VALID THRU:
                  </h5>
                  <p>{validTill}</p>
                </div>
              </div>
            </div>
            {/* </div>
            </div> */}

            {net_topup !== 0 && (
              <Card className="text-center" style={{ maxHeight: "250px" }}>
                <Card.Body>
                  <div>
                    <p className="fs-18 mb-3 text-black">
                      NET TOP UP: ${net_topup}
                    </p>
                    {address ? (
                      <>
                        <Topupbutton
                          LoadAmount={LoadAmount}
                          LoadAmountNet={net_topup}
                          LoadAmountFCF={toFcf}
                          onTopUpSuccess={handleTopUpSuccess}
                          onStatusChange={handleStatusChange}
                          settopuperror={handlesettopuperror}
                        />
                      </>
                    ) : (
                      <>
                        <label className="text-black font-w600 ">
                          Select Blockchain
                        </label>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            flexDirection: "row", // Change from 'column' to 'row'
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            value="bep20"
                            onClick={(e) => handlesetNetwork("bep20")}
                            className="btn btn-primary mt-3 m-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disabled={network && network === "bep20"}
                          >
                            <img
                              src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                              alt="network"
                              style={{
                                marginRight: "10px",
                                width: "25px",
                              }}
                            />
                            USDT BEP20
                          </button>
                          <button
                            value="matic"
                            onClick={(e) => handlesetNetwork("matic")}
                            className="btn btn-primary mt-3 m-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disabled={network && network === "matic"}
                          >
                            <img
                              src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                              alt="network"
                              style={{
                                marginRight: "10px",
                                width: "25px",
                              }}
                            />
                            USDT POLYGON
                          </button>
                        </div>
                      </>
                    )}
                    {isLoadingNetwork && (
                      <div className="text-center mt-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            )}
            <div className="d-flex flex-column">
              {net_topup != 0 && (
                <>
                  {success_message ? (
                    <div className="alert alert-warning" role="alert">
                      Transaction Confirmed. The card balance loading can take
                      up to 10 minutes. Please be patient and do not reload this
                      page.
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {createdAddress && (
              <Card
                className="mt-4 d-flex"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Card.Body
                  className="d-flex"
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <h4>Payment Details</h4>
                          <p>Address: {createdAddress}</p> */}
                  <label className="text-black font-w600 ">Address</label>
                  <div className="input-group mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control text-black"
                      placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                      value={createdAddress}
                      readOnly
                    />

                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(createdAddress)
                          .then(() =>
                            setAlert({
                              type: "success",
                              message: "Address copied to clipboard",
                            })
                          )
                          .catch((error) =>
                            console.error("Could not copy text: ", error)
                          );
                      }}
                    >
                      COPY
                    </button>
                  </div>

                  <div className="text-danger font-w600">
                    Sending funds on any other blockchain will result in the
                    loss of your funds.
                  </div>

                  <label className="text-black font-w600 mt-2">Amount</label>
                  <div
                    className="input-group mb-3 mt-3"
                    style={{ maxWidth: "250px" }}
                  >
                    <button className="btn btn-primary" type="button" disabled>
                      USDT
                    </button>
                    <input
                      type="number"
                      className="form-control text-black"
                      placeholder="0"
                      value={LoadAmount}
                      readOnly
                    />

                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(LoadAmount)
                          .then(() =>
                            setAlert({
                              type: "success",
                              message: "Amount copied to clipboard",
                            })
                          )
                          .catch((error) =>
                            console.error("Could not copy text: ", error)
                          );
                      }}
                    >
                      COPY
                    </button>
                  </div>
                  {qrCode && (
                    <div className="text-center">
                      <img
                        src={`data:image/png;base64,${qrCode}`}
                        alt="QR Code"
                        style={{ maxWidth: "220px" }}
                      />
                    </div>
                  )}
                  {isLoadingTransaction ? (
                    <div className="text-center mt-4">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      <div></div>
                      <br></br>
                      {txnHash ? (
                        <Fragment>
                          <p>Transaction Hash: {txnHash}</p>
                          <p>Confirmations: {confirmations}</p>
                        </Fragment>
                      ) : (
                        <>
                          <br></br>
                          <label className="text-black font-w600 mt-3">
                            Waiting for Payment
                          </label>
                          <br></br>
                        </>
                      )}
                    </div>
                  ) : (
                    transactionsConfirmed && (
                      <Alert variant="success" className="text-center mt-4">
                        Transaction confirmed! Card purchase successful.
                      </Alert>
                    )
                  )}
                  {errormessage && (
                    <Alert variant="danger" className="text-center mt-4">
                      {errormessage}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VirtualCardWidget;
