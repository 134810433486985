import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Dropdown,
  Button,
  Modal,
  Alert,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import profile from "../../images/profile/profile.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Donut2 from "../components/Mophy/MyWallet/Donut2";
import PerfectScrollbar from "react-perfect-scrollbar";

import PostDetails from "../components/AppsMenu/AppProfile/PostDetails";
import { set } from "lodash";
import Spinner from "react-bootstrap/Spinner";

const PartnerDashboard = () => {
  const [sendMessage, setSendMessage] = useState(false);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const user = useSelector((state) => state.user.user);
  const [reflink, setreflink] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showTableDetail, setShowTableDetail] = useState(false);
  const [downlineCounts, setDownlineCounts] = useState({});
  const [downlineDetails, setdownlineDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [totalTeamMembers, setTotalTeamMembers] = useState(0);
  const [totalrefcommission, setTotalRefCommission] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [withdrawtransactions, setWithdrawTransactions] = useState([]);
  const [userBalances, setUserBalances] = useState({});
  const [userTurnover, setUserTurnover] = useState(0);
  const [turnoverPercentage, setTurnoverPercentage] = useState(0);
  const [userRank, setUserRank] = useState("");
  const [activeTab, setActiveTab] = useState("transactions");

  //Withdraw Balances
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [currency, setCurrency] = useState("bsc_usdt");
  const [withdrawMessage, setWithdrawMessage] = useState("");
  const [withdrawError, setWithdrawError] = useState("");
  const [isWithdrawingBSC, setIsWithdrawingBSC] = useState(false);
  const [isWithdrawingMATIC, setIsWithdrawingMATIC] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalMatic, setShowModalMatic] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleTabClick = (tab) => {
    console.log(tab);
    setActiveTab(tab);
  };

  const handleWithdraw = async (withdrawAmount, currency) => {
    console.log("handleWithdraw", withdrawAmount, currency);
    if (currency === "bsc_usdt") {
      setIsWithdrawingBSC(true);
    } else {
      setIsWithdrawingMATIC(true);
    }
    setWithdrawMessage("");

    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/withdraw",
        {
          amount: withdrawAmount,
          currency: currency,
          user: localUser.id,
        }
      );

      if (response.data.message) {
        setWithdrawMessage(response.data.message);
        if (currency === "bsc_usdt") {
          userBalances.bsc_usdt = 0;
        } else {
          userBalances.matic_usdt = 0;
        }
        fetchDownlineCounts();
      }
    } catch (error) {
      console.error(
        "Withdrawal Error:",
        error.response?.data?.message || "An error occurred"
      );
      setWithdrawError(error.response?.data?.message || "An error occurred");
    } finally {
      setIsWithdrawingBSC(false);
      setIsWithdrawingMATIC(false);
    }
  };

  const ranks = [
    { name: "", level: [0] },
    { name: "Bridge Achiever", level: [1] },
    { name: "Bridge Professional", level: [2, 5] },
    { name: "Bridge Expert", level: [6, 10] },
    { name: "Bridge Master", level: [10, 15] },
    { name: "Bridge Gold Director", level: [16, 20] },
  ];

  function belongsToRank(level, ranks) {
    let minLevel = ranks.level[0];
    let maxLevel = ranks.level[ranks.level.length - 1];

    return level >= minLevel && level <= maxLevel;
  }

  const fetchUserEssentials = async () => {
    try {
      const response = await axios.get(
        `https://api.bridge-bit.com/api/getUserEssentials/${localUser.id}`
      );
      console.log("fetch Balances", response.data);
      const userBalances = response.data.userBalances;
      setUserBalances(userBalances);
      setDownlineCounts(response.data.downlineCounts);
      setWithdrawTransactions(response.data.withdrawals);
      const totalTeamMembers = Object.values(
        response.data.downlineCounts
      ).reduce((total, count) => total + count, 0);
      setTotalTeamMembers(totalTeamMembers);
      const totalrefcommissions = Object.keys(userBalances)
        .filter((key) => key.includes("usdt"))
        .reduce((total, key) => total + parseFloat(userBalances[key]), 0);

      const totalWithdraws = response.data.withdrawals.reduce(
        (total, withdrawal) => total + parseFloat(withdrawal.amount),
        0
      );
      let step = totalWithdraws + totalrefcommissions;
      setTotalRefCommission(step.toFixed(2));
      setTotalBalance(totalrefcommissions.toFixed(2));

      setTransactions(response.data.transactions.reverse());
    } catch (error) {
      console.error("Error fetching downline counts", error);
    }
  };

  const fetchDownlineCounts = async () => {
    try {
      setIsLoading(true); // Set loading to true at the start

      const response = await axios.get(
        `https://api.bridge-bit.com/api/partner-downline/${localUser.id}`
      );
      const data = response.data;
      console.log("partner-downline", data);
      console.log("partner-downline", data.downlineDetails.message);

      if (data.downlineDetails.message !== "No downlines found") {
        setdownlineDetails(data.downlineDetails);

        const overallTurnover = data.downlineDetails.reduce(
          (totalTurnover, user) => totalTurnover + user.turnover,
          0
        );

        setUserTurnover(overallTurnover);
        const getpercentageturnover = (overallTurnover / 1000000) * 100;
        setTurnoverPercentage(getpercentageturnover);
      } else {
        console.log("No downlines found");
        setdownlineDetails([]);
        setUserTurnover(0);
        setTurnoverPercentage(0);
      }
    } catch (error) {
      console.error("Error fetching downline counts", error);
    } finally {
      setIsLoading(false); // Set loading to false when done
    }
  };

  useEffect(() => {
    setreflink(
      `${window.location.origin}/page-register?referral=${localUser.username}`
    );

    let userRank = ranks.find((rank) => belongsToRank(localUser.level, rank));
    setUserRank(userRank.name);

    fetchUserEssentials();

    fetchDownlineCounts();
  }, [localUser]);

  useEffect(() => {
    console.log("downlineDetails", downlineDetails);
    if (Object.keys(downlineDetails).length > 0) {
      const flattenedUsers = [].concat(...Object.values(downlineDetails));

      setFilteredUsers(flattenedUsers);
    }
  }, [downlineDetails]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const lowercasedValue = value.toLowerCase();

    let filtered = [];

    if (downlineDetails && Object.keys(downlineDetails).length > 0) {
      for (const level in downlineDetails) {
        downlineDetails[level].forEach((user) => {
          if (
            user.username.toLowerCase().includes(lowercasedValue) ||
            level.toString().includes(lowercasedValue)
          ) {
            filtered.push({
              ...user,
              level: level,
            });
          }
        });
      }
    }

    setFilteredUsers(filtered);
  };

  const calculateUserRank = (turnover) => {
    let currentRank = ranks[0];
    let nextRank = null;
    let turnoverLeft = 0;

    for (let i = 0; i < ranks.length; i++) {
      if (turnover >= ranks[i].turnover) {
        currentRank = ranks[i];
        nextRank = ranks[i + 1] || null;
      } else {
        break;
      }
    }

    if (nextRank) {
      turnoverLeft = nextRank.turnover - turnover;
    }

    const progress = nextRank
      ? Math.min(
          ((turnover - currentRank.turnover) /
            (nextRank.turnover - currentRank.turnover)) *
            100,
          100
        )
      : 100;

    return { currentRank, nextRank, progress, turnoverLeft };
  };
  const { currentRank, nextRank, progress, turnoverLeft } = calculateUserRank(
    750 || 0
  );

  const handleCopyClick = () => {
    navigator.clipboard.writeText(reflink).then(() => {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
    });
  };

  const handleShowDetails = () => {
    setShowTable(false);
    setShowTableDetail(true);
  };

  const handleShowTable = () => {
    setShowTable(true);
  };

  const currencyImages = {
    bsc_usdt:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/56863661-1ea7-4013-a36b-7f77cc612b00/public",
    trc20_usdt:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/0c21016c-b10b-48da-dfc4-63973d714700/public",
    sol_usdt:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1bf007cb-61cf-41e0-3246-be4c568ebe00/public",
    matic_usdt:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/d9c5ae86-5d66-4316-8b7f-445e83b18d00/public",
    arbitrum_usdt:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/159b3015-4c70-43fa-53f8-ac9bcb550a00/public",
    btc: "https://api.cryptapi.io/media/token_logos/btc.png",
  };

  const transactions_types = {
    0: "Card Sale",
    1: "Top Up",
    2: "Virtual Card",
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleShowMatic = () => setShowModalMatic(true);
  const handleCloseMatic = () => setShowModalMatic(false);
  const handleConfirmWithdrawal = () => {
    handleWithdraw(Number(userBalances?.bsc_usdt).toFixed(4), "bsc_usdt");
    handleClose();
  };
  const handleConfirmWithdrawalMatic = () => {
    handleWithdraw(Number(userBalances?.matic_usdt).toFixed(4), "matic_usdt");
    handleClose();
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Partner Dashboard</h2>
      </div>
      <div className="row">
        <div className="col-lg-12 ">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              {/* <div className="photo-content ">
                <div
                  className="cover-photo rounded"
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  VIELLEICHT IRGENDEIN COOLES BILD HIER ?
                </div>
              </div> */}
              <div className="profile-info">
                <div
                  className="profile-details"
                  style={{ alignItems: "center" }}
                >
                  {/* <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Sponsor</h4>
                    <p>{localUser.referredBy}</p>
                  </div> */}
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-black mb-0">Partner Link</h4>
                    <p>{reflink}</p>
                  </div>

                  <Button
                    variant="primary"
                    className="ms-auto"
                    onClick={handleCopyClick}
                  >
                    <i
                      className="fa fa-copy"
                      aria-hidden="true"
                      style={{ color: "#EC6631" }}
                    ></i>{" "}
                    Copy
                  </Button>
                </div>
              </div>
              {showAlert && (
                <div className="row">
                  <div className="col-lg-12">
                    <Alert variant="success" className="mt-3">
                      Partner link copied to clipboard!
                    </Alert>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card stacked-2">
            <div className="card-header flex-wrap border-0 pb-0 align-items-end">
              <div className="d-flex align-items-center me-3 w-100">
                {/* <svg
                  className="me-3"
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                  style={{ color: "#EC6631" }}
                >
                  <path
                    d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                    fill="#1EAAE7"
                  />
                </svg> */}
                <div className="me-auto">
                  <h3 className="fs-20 text-black font-w600">
                    Partner Balances
                  </h3>
                  {/* <span className="text-num text-black font-w600">
                    ${totalBalance}
                  </span> */}
                </div>
              </div>
            </div>
            {/* WALLETS */}
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-xl-4 mb-3 col-xxl-4 col-sm-5">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                      minWidth: "190px",
                    }}
                  >
                    <img
                      src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                      alt="network"
                      style={{ marginRight: "10px", width: "35px" }}
                    />
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">BSC</h4>
                      <span className="fs-14">
                        {userBalances?.bsc_usdt
                          ? Math.floor(userBalances?.bsc_usdt * 100) / 100 +
                            " USDT"
                          : "Loading..."}
                      </span>
                    </div>

                    {/* <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={() =>
                        handleWithdraw(
                          Number(userBalances?.bsc_usdt).toFixed(2),
                          "bsc_usdt"
                        )
                      }
                    >
                      {isWithdrawingBSC ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        </>
                      ) : (
                        <i className="fa fa-wallet" aria-hidden="true"></i>
                      )}
                    </Button> */}

                    <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={handleShow}
                    >
                      {isWithdrawingBSC ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      ) : (
                        <i className="fa fa-wallet" aria-hidden="true"></i>
                      )}
                    </Button>

                    {userBalances?.bsc_usdt && (
                      <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Withdrawal</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="text-center">
                          <h4>
                            {Math.floor(userBalances?.bsc_usdt * 100) / 100}{" "}
                            USDT
                          </h4>
                          <h4>Withdrawal can take up to 24 hours.</h4>
                          {Number(userBalances?.bsc_usdt) < 2 && (
                            <p className="text-danger">
                              Withdrawal amount cannot be less than 2 USDT.
                            </p>
                          )}
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="danger" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleConfirmWithdrawal}
                            disabled={
                              isWithdrawingBSC ||
                              Number(userBalances?.bsc_usdt) < 2
                            }
                          >
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </div>
                </div>
                {/* <div className="col-xl-3 mb-3 col-xxl-3 col-sm-4">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                      minWidth: "190px",
                    }}
                  >
                    <img
                      src="https://cdn.bitkeep.vip/u_b_fdfe0be0-c215-11ed-bb06-6b42bb500220.png"
                      alt="network"
                      style={{ marginRight: "10px", width: "25px" }}
                    />
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">TRC20</h4>
                      <span className="fs-14">
                        {userBalances
                          ? Number(userBalances.trc20_usdt).toFixed(2) + " USDT"
                          : "0.00 USDT"}
                      </span>
                    </div>

                    <Button variant="primary" className="ms-auto">
                      <i className="fa fa-wallet" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div> */}

                <div className="col-xl-4 mb-3 col-xxl-4 col-sm-5">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                      minWidth: "190px",
                    }}
                  >
                    <img
                      src="https://cryptologos.cc/logos/polygon-matic-logo.png"
                      alt="network"
                      style={{ marginRight: "10px", width: "25px" }}
                    />
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">MATIC</h4>
                      <span className="fs-14">
                        {userBalances?.matic_usdt
                          ? Math.floor(userBalances?.matic_usdt * 100) / 100 +
                            " USDT"
                          : "Loading..."}
                      </span>
                    </div>

                    {/* <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={() =>
                        handleWithdraw(
                          Number(userBalances?.matic_usdt).toFixed(2),
                          "matic_usdt"
                        )
                      }
                    >
                      {isWithdrawingMATIC ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        </>
                      ) : (
                        <i className="fa fa-wallet" aria-hidden="true"></i>
                      )}
                    </Button> */}

                    <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={handleShowMatic}
                    >
                      {isWithdrawingMATIC ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      ) : (
                        <i className="fa fa-wallet" aria-hidden="true"></i>
                      )}
                    </Button>

                    {userBalances?.matic_usdt && (
                      <Modal show={showModalMatic} onHide={handleCloseMatic}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Withdrawal</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="text-center">
                          <h4>
                            {Math.floor(userBalances?.matic_usdt * 100) / 100}{" "}
                            USDT
                          </h4>
                          <h4>Withdrawal can take up to 24 hours.</h4>
                          {Number(userBalances?.matic_usdt) < 2 && (
                            <p className="text-danger">
                              Withdrawal amount cannot be less than 2 USDT.
                            </p>
                          )}
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="danger" onClick={handleCloseMatic}>
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleConfirmWithdrawalMatic}
                            disabled={
                              isWithdrawingMATIC ||
                              Number(userBalances?.matic_usdt) < 2
                            }
                          >
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </div>
                </div>
                {/* <div className="col-xl-3 mb-3 col-xxl-3 col-sm-4">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                      minWidth: "190px",
                    }}
                  >
                    <img
                      src="https://api.cryptapi.io/media/token_logos/btc.png"
                      alt="network"
                      style={{ marginRight: "10px", width: "25px" }}
                    />
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">BTC</h4>
                      <span className="fs-14">
                        {userBalances
                          ? Number(userBalances.btc).toFixed(2) + " BTC"
                          : "0.00 BTC"}
                      </span>
                    </div>

                    <Button variant="primary" className="ms-auto">
                      <i className="fa fa-wallet" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div> */}
              </div>
              {withdrawMessage && (
                <Alert variant="success" className="mt-3 text-center">
                  {withdrawMessage}
                </Alert>
              )}
              {withdrawError && (
                <Alert variant="danger" className="mt-3 text-center">
                  {withdrawError}
                </Alert>
              )}
            </div>
            <div className="card-body">
              <h2 className="text-black font-w600 mb-0 text-center mb-2">
                Team Turnover
              </h2>

              <div
                className="progress mb-4 text-center"
                style={{ height: "24px" }}
              >
                <div
                  className="progress-bar bg-inverse progress-animated"
                  style={{
                    width: `${turnoverPercentage}%`,
                    // width: `${progress}%`,
                    height: "24px",
                    backgroundColor: "#EC6631",
                  }}
                  role="progressbar"
                >
                  <span className="sr-only">100% Complete</span>
                </div>
                <div
                  className="text-center"
                  style={{
                    position: "absolute",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "24px",
                    color: "black",
                    fontSize: "1rem",
                  }}
                >
                  {isLoading
                    ? "Loading..."
                    : `${
                        Math.floor(userTurnover * 100) / 100
                      } USDT / 1.000.000`}
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-xl-3 mb-3 col-xxl-6 col-sm-6">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                    }}
                  >
                    <span className="bg-white rounded-circle p-3 me-4">
                      <i className="fa fa-users fa-xl	" aria-hidden="true"></i>
                    </span>
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">
                        Total Team Members
                      </h4>
                      <span className="fs-14 text-black ">
                        {totalTeamMembers}
                      </span>
                    </div>{" "}
                    <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={handleShowTable}
                    >
                      {/* <i className="fa fa-wallet" aria-hidden="true"></i>  */}
                      Show
                    </Button>
                  </div>
                </div>
                <div className="col-xl-3 mb-3 col-xxl-6 col-sm-6">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                    }}
                  >
                    <span className="bg-white rounded-circle p-3 me-4">
                      <i
                        className="fa-solid fa-coins fa-xl	"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">
                        Total Partner Income
                      </h4>
                      <span className="fs-14 text-black ">
                        $ {totalrefcommission}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 mb-3 col-xxl-6 col-sm-6">
                  <div
                    className="media align-items-center bgl-white rounded p-2"
                    style={{
                      border: "0.5px solid",
                    }}
                  >
                    <span className="bg-white rounded-circle p-3 me-4">
                      <i className="fa fa-gem fa-xl	" aria-hidden="true"></i>
                    </span>
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">Rank</h4>
                      <span className="fs-14 text-black ">
                        {userRank ? (
                          <div>{userRank}</div>
                        ) : (
                          <div>No Rank Found</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {showTable && (
                <>
                  <Table responsive bordered className="header-border">
                    <thead>
                      <tr>
                        <th className="fs-15 text-black font-w600 text-center">
                          Level
                        </th>
                        <th className="fs-15 text-black font-w600 text-center">
                          Team Members
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(downlineCounts).map(
                        ([level, details]) => (
                          <tr key={level}>
                            <td className="text-center fs-14 text-black">
                              {level}
                            </td>
                            <td className="text-center fs-14 text-black">
                              {details}
                            </td>
                            {/* <td>
                          {details.users.map((user) => (
                            <div key={user.id}>{user.username}</div>
                          ))}
                        </td> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <div className="row card-body">
                    <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={handleShowDetails}
                    >
                      Detailed Team Overview
                    </Button>
                  </div>
                </>
              )}

              {showTableDetail && (
                <>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Search by username or level"
                      aria-label="Search"
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                  </InputGroup>
                  <Table responsive bordered className="header-border">
                    <thead>
                      <tr>
                        <th className="fs-15 text-black font-w600 text-center">
                          Level
                        </th>
                        <th className="fs-15 text-black font-w600 text-center">
                          Username
                        </th>
                        <th className="fs-15 text-black font-w600 text-center">
                          KYC
                        </th>
                        <th className="fs-15 text-black font-w600 text-center">
                          Card
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user, index) => (
                        <tr key={index}>
                          <td className="text-center fs-14 text-black">
                            {user.level}
                          </td>
                          <td className="text-center fs-14 text-black">
                            {user.username} (
                            <a href={`mailto:${user.email}`}>{user.email}</a>)
                          </td>
                          <div className="text-center fs-14 text-black">
                            {user.kyc === "true" ? (
                              <span style={{ color: "green" }}> Verified</span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {" "}
                                Not Verified
                              </span>
                            )}
                          </div>

                          <td className="text-center fs-14 text-black">
                            {user.card_type === "0" ? (
                              <span style={{ color: "red" }}>No Card</span>
                            ) : (
                              <>
                                {user.card_type == 1 && (
                                  <span>Standard Card</span>
                                )}
                                {user.card_type == 2 && (
                                  <span>Premium Card</span>
                                )}
                                {user.card_type == 3 && <span>VIP Card</span>}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-xxl-12 col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "transactions" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("transactions")}
                  >
                    Transactions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "withdrawTransactions" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("withdrawTransactions")}
                  >
                    Withdraw Transactions
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                {activeTab === "transactions" && (
                  <div className="tab-pane fade show active">
                    <Table responsive>
                      <thead>
                        <tr className="text-black text-weight-600">
                          <th>Amount</th>
                          <th>Partner</th>
                          <th className="text-center">Currency</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr className="text-black" key={transaction.id}>
                            <td
                              className="text-black"
                              style={{ width: "110px" }}
                            >
                              {Number(transaction.amount).toFixed(2)} USDT
                            </td>
                            <td className="text-black">
                              {transaction.buyer_name} (
                              {transactions_types[transaction.type]})
                            </td>
                            <td className="text-black text-center">
                              <img
                                src={currencyImages[transaction.currency]}
                                alt={transaction.currency}
                                width="25px"
                                height="25px"
                              />
                            </td>
                            <td className="text-black">{transaction.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>{" "}
                  </div>
                )}
                {activeTab === "withdrawTransactions" && (
                  <div className="tab-pane fade show active">
                    <Table responsive>
                      <thead>
                        <tr className="text-black text-weight-600">
                          <th>Amount</th>
                          <th className="text-center">Currency</th>
                          <th>TXN</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawtransactions.map((transaction) => (
                          <tr className="text-black" key={transaction.id}>
                            <td
                              className="text-black"
                              style={{ width: "110px" }}
                            >
                              {Number(transaction.amount).toFixed(2)} USDT
                            </td>
                            <td className="text-black text-center">
                              <img
                                src={currencyImages[transaction.currency]}
                                alt={transaction.currency}
                                width="25px"
                                height="25px"
                              />
                            </td>
                            <td className="text-black">
                              {transaction.txn_hash}
                            </td>
                            <td className="text-black">{transaction.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartnerDashboard;
